@import '../../../../styles/mixins/media';

.header-right {
  float: left;
  position: absolute;
  left: 250px;
  right: 0px;

  @include media-tn {
    .navbar-right {
      float: none!important;
      display: flex;
      justify-content: flex-end;

      @include media-tn {
        justify-content: space-between;
      }
    }

    .dropdown.vertical > a {
      padding-right: 10px!important;
      padding-left: 10px!important;

      @include media-tn {
        padding-right: 6px!important;
        padding-left: 6px!important;
        font-size: 12px;
      }
    }
  }

  .navbar-toolbar:before {
    border-bottom: 1px solid #DDD;
    position: absolute;
    display: block;
    content: "";
    bottom: -1px;
    left: 0px;
    right: 0px;
  }

  .navbar {
    border: none;
    min-height: 62px;

    @include media-tn {
      min-height: 50px;
    }
  }

  .navbar-dark .navbar-right .dropdown > a:focus i,
  .navbar-dark .navbar-right .dropdown > a:active i {
    color: #777;
  }

  .navbar-dark .navbar-right .dropdown > a.active i,
  .navbar-dark .navbar-right .dropdown.open > a i {
    color: #c35745
  }

}

#header.on-scrolling {
  @include media-tn {

    .header-right {
      top: 0px;
    }

    .navbar-right {
      @include media-tn {
        justify-content: flex-end;
      }

      .dropdown.vertical > a {
        width: 58px;
        height: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;

        @include media-mc {
          width: 50px;
        }

        span:not(.count) {
          display: none;
        }

        .count {
          top: 6px;
          right: 0;
          padding-bottom: 2px;
        }

        i {
          font-size: 24px;
        }
      }
    }
  }
}
