@import '../../../styles/mixins/media';

.picks-table {
  margin-bottom: 20px;
  font-weight: bold;
  @include media-sm {
    margin: 0 0 20px;
  }
  .col-md-12 {
    margin: 0;
    padding: 0;
  }
  .table {
    @include media-sm {
      min-width: initial;
      display: flex;
      flex-direction: column;
    }
  }

  thead {
    tr {
      background-color: #607D8B!important;
      font-weight: bold;
      color: #eee;
      @include media-sm {
        display: flex;
        flex-direction: column;
      }
      td {
        padding: 12px 8px;
        border: none;
      }
    }

    .finish-soon {
      border-top: 2px solid #e9573f;
    }
  }

  tbody {
    @include media-sm {
      display: flex;
      flex-direction: row;
      width: 100%;
      table-layout: unset;
      font-size: 12px;
    }
    tr {
      @include media-sm {
        display: flex;
        flex-direction: column;
        &:nth-child(1) {
          td {
            width: 90px;
          }
        }
        &:nth-child(4) {
          td {
            flex-direction: row;
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: 100%;
          td {
            color: #000;
            flex-shrink: 0;
            min-height: 34px;
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              .btn {
                padding: 12px 2px 5px 25px;
                font-size: 11px;
                &::before {
                  left: 6px;
                  top: 9px;
                }
                &::after {
                  left: 9px;
                  top: 11px;
                }
              }
            }
            .btn {
              padding-right: 2px;
            }
          }
        }
        &:nth-child(4){
          flex-direction: row;
        }
        td {
          height: initial;
        }
        td:nth-child(1) {
          flex-grow: 1;
        }
    }
      td {
        line-height: 16px;
        font-weight: bold;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
  tfoot {
    @include media-sm {
      tr, td {
        display: block;
      }
    }
  }
  .table-btn {
    padding: 0!important;

    .btn {
      height: 40px;
      border: none;
      color: #777;
      font-weight: bold;
      border-radius: 0;
      letter-spacing: 1px;
      @include media-sm {
        height: initial;
      }

      &::before {
        content: '';
        position: absolute;
        left: 15px;
        top: 13px;
        border: 2px solid #777;
        background-color: #eee;
        border-radius: 3px;
        width: 15px;
        height: 15px;
      }

      &:hover {
        background-color: #eee;
      }

      &.btn-odd-selected {
        background-color: #8bc152;
        font-weight: 900;
        width: 100%;

        &::after {
          content: "\f00c";
          position: absolute;
          z-index: 2;
          left: 17px;
          font-size: 11px;
          line-height: 1;
          top: 16px;
          color: #4CAF50;
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
          display: inline-block;
          vertical-align: middle;
          transition: 0ms;
        }
      }

      &.disabled-pick {
        position: relative;
        background: #9E9E9E !important;
        overflow: hidden;
        color: #777!important;
        opacity: 1!important;

        &::after {
          content: "\F00D";
          position: absolute;
          z-index: 2;
          left: 18px;
          font-size: 11px;
          line-height: 1;
          color: #f24525;
          top: 15px;
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
          display: inline-block;
          vertical-align: middle;
          transition: 0ms;
        }
      }
    }
  }

  .btn-warning {
    color: #000 !important;
    margin-top: 10px;
    max-width: 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    i {
      margin-right: 7px;
    }
  }

  .two-rows {
    position: relative;
    background-color: white;
    &-mob {
      display: none;

      @include media-sm {
        display: block;
      }
    }

    .btn {
      height: 81px;

      @media (min-width: 769px) {
        &::before {
          top: 33px;
        }

        &::after {
          top: 35px!important;
        }
      }

      @include media-sm {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #fafafa;
        height: 32px;
        width: calc(200% + 2px);
        padding-right: 100%!important;

        &.btn-odd-selected {
          width: calc(200% + 2px);
        }

        &.disabled-pick {
          position: absolute;
        }
      }
    }
  }
}