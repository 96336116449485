@import '../../styles/mixins/media';

.mypicks-page {
  .pick {
    padding: 15px;
    border: 1px solid #ccc;
    @media (min-width: 528px) {
      .user-pick {
        width: calc(100% - 250px);
      }
    }

    @include media-sm {
      padding: 10px;
    }
  }

  .body-content {
    @include media-sm {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .panel-tab .panel-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-sm {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      border-bottom: none;
    }

    .nav {
      margin-bottom: 0;

      > li {
        margin-bottom: 0;

        @include media-tn {
          &.active a::before {
            content: '';
            position: absolute;
            right: 25px;
            top: 28px;
            width: 10px;
            height: 10px;
            background-color: #777;
            border-radius: 50%;
            z-index: 1;
          }
          > a {
              position: relative;
              padding: 14px 20px 14px 14px;
              border-bottom: 1px solid #eee;

              &::after {
                content: '';
                position: absolute;
                right: 20px;
                top: 23px;
                width: 20px;
                height: 20px;
                background-color: #ddd;
                border-radius: 50%;
              }
          }
        }
        .sorting {
          margin: 10px;
          font-size: 16px !important;
        }
      }
    }
  }

  .panel-tab {
    .panel-body {
      label {
        margin-right: 10px;
      }
      .btn-group {
        margin-right: 30px;
      }
    }
  }

  .switch-btn {
    margin-right: 15px;
    margin-left: 15px;
    max-width: 200px;

    @include media-only-lg {
      margin-left: 0;
    }

    @include media-only-md {
      margin-left: 0;
    }

    @include media-lg {
      margin-bottom: 15px;
    }

    @include media-tn {
      margin-top: -5px;
    }

    i {
      margin-right: 10px;
    }
  }
  .empty-component {
    padding: 30px;
    h1 {
      margin-top: 5px;
    }
  }

  .filters-switcher {
    font-size: 15px;
    cursor: pointer;
    padding: 5px 10px;

    @include media-sm {
      width: 100%;
      text-align: center;
      border-top: 1px solid #ccc;
      padding-top: 10px;
      padding-bottom: 8px;
    }

    i {
      margin-left: 8px;
      font-size: 18px;
    }
  }

  .filters-panel {
    padding: 10px 15px;
    background-color: #fefefe;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;

    @include media-tn {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 15px;
    }

    label {
      margin-right: 10px;
      font-size: 14px;
      margin-bottom: 0;
      color: #444;
    }

    .btn-group {
      margin-right: 35px;

      &:nth-of-type(2) {
        li a {
          text-transform: uppercase;
        }
      }
    }

    .btn .caret {
      margin-left: 5px;
    }

    .form-group {
      margin-bottom: 0;

      @include media-tn {
        margin-bottom: 10px;
      }
    }

    .sports {
      .dropdown-menu li,
      .dropdown-toggle {
        text-transform: uppercase;
      }
    }
  }
}