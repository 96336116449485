// inherit styles from UserStatsProfile

.user-stats-profile {
  .unfollow {
    position: absolute;
    right: 10px;
    top: 10px;

    a {
      padding: 4px;
      cursor: pointer;
    }

    i {
      font-size: 24px;
      color: #eee;
    }
  }

  .send-message {
    margin-left: 5px;

    a {
      padding: 4px;
    }

    i {
      font-size: 17px;
      color: #eee;
    }
  }
}