@import '../../../styles/mixins/media';

.chat-sender {
    padding: 25px 30px 20px;
    background-color: #434651;
    min-height: 174px;
    height: 26.8%;

    @include media-tn {
        padding: 15px 20px 12px;
        min-height: auto;
        height: 157px;
    }

    p {
        color: #eee;
        text-align: center;
        font-size: 14px;
    }

    .textarea-wrap {
        position: relative; // fix textarea jump with grammarly
    }

    textarea {
        width: 100%;
        border: none;
        padding: 10px 20px;
        margin-bottom: 10px;
        border-radius: 5px;
        resize: none;
        background-color: #ccc;
        color: #2a2a2a!important;
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);

        &::placeholder {
            color: #777;
        }

        &:focus {
            outline: none;
            border-color: #999 !important;
            border: none !important;
        }
    }

    .fa-file-o,
    .fa-file-image-o {
        font-size: 16px;
        color: gray;
        cursor: pointer;
    }

    button {
        float: right;
        color: #CECBB6;
        font-size: 16px;
        text-transform: uppercase;
        border: none;
        cursor: pointer;
        font-weight: bold;
        background: #837F8D;
        padding: 5px 25px;
        border-radius: 5px;
        min-width: 120px;

        &:hover {
            color: #eee;
        }
    }

    .length-indicator {
        display: inline-block;
        line-height: 25px;
        color: #888;

        .correct-length {
            color: #18d723;
        }

        .incorrect-length {
            color: #e8573f;
        }
    }
}