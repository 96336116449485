@import '../../../styles/mixins/media';

.media-block {
    text-align: center;
    background-color: #ffffff;
    cursor: pointer;

    @media (max-width: 767px) {
        max-width: 100%;
        height: 100%;
        width: auto;
    }

    .content > a > img {
      max-height: 300px;
      max-width: 100%;
    }

    .youtube-cover {
        background-color: #000;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        height: 315px;

        @include media-tn {
            height: 220px;
        }

        img {
            position: absolute;
            height: 150%;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            opacity: 0.7;
            cursor: pointer;

            @include media-tn {
                width: 100%;
                height: auto;
            }
        }

        .play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d( -50%, -50%, 0 );
            width: 90px;
            height: 60px;
            background-color: #333;
            box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
            z-index: 1;
            opacity: 0.8;
            border-radius: 6px;
            cursor: pointer;

            &:before {
                content: "";
                top: 50%;
                left: 50%;
                transform: translate3d( -50%, -50%, 0 );
                position: absolute;
                border-style: solid;
                border-width: 15px 0 15px 26.0px;
                border-color: transparent transparent transparent #fff;
            }
        }

        iframe {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }
    }
}

.feed-media-modal {
    .modal-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    .modal-dialog {
        width: inherit;
        width: max-content;
        min-width: 500px;
        max-width: 85%;
        max-height: 90vh;
    }

    .modal-body {
        overflow: hidden;
        text-align: center;

        iframe {
            min-width: 650px;

            @media (max-width: 767px) {
                min-width: auto;
            }
        }

        img {
            width: auto;
            max-width: 100%;
            max-height: 70vh;
        }
    }

    .modal-header {
        padding: 0;
    }

    .close {
        position: absolute;
        padding: 5px;
        font-size: 30px;
        color: #fff;
        top: -28px;
        right: -28px;
        opacity: 0.7;
        transition: 0.2s ease-in;

        &:hover {
            opacity: 1;
        }
    }
    .content {
        width: 100%;
        //height: 188px;

        .media-block-image {
            display: block;
            min-width: 450px;
            min-height: 188px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            @media (max-width: 767px) {
                max-width: 100%;
                min-width: 300px;
                min-height: 188p;
            }
        }
        .full-width {
            display: block;
            width: 100%;
        }
        &.cover {
            background-size: cover;
        }
    }
}
