@import '../../../styles/mixins/media';

.landing-sign-up {
  .auth {
    width: 430px;
    max-width: inherit;

    @include media-sm {
      width: 100%;
    }
  }

  .sign-header {
    border-bottom: none;
    background-color: #2a2a2a;
    padding: 15px 15px 0;
  }

  .sign-body {
    padding: 20px 20px 10px;
  }

  .sign-footer {
    padding: 0 20px 10px;
  }

  .sign-text {
    &::before {
      display: none;
    }

    span {
      background-color: #2a2a2a;
      font-size: 18px;
      font-weight: bold;
    }
  }
}