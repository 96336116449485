.landing-advantages {
  background: #fff;
  padding: 100px 0px;

  .great_title {
    text-align: center;
    margin-bottom: 50px;
  }

  .great_feature_inner {
    margin-bottom: -40px;
    overflow: hidden;

    .great_feature_item {
        text-align: center;
        margin-bottom: 40px;

        i {
          font-size: 35px;
        }

        h4 {
            font-size: 20px;
            font-weight: bold;
            color: #e9573f;
            letter-spacing: .40px;
            padding: 8px 0px 16px 0px;
        }

        p {
            font-size: 14px;
            line-height: 24px;
            color: #777;
            letter-spacing: .28px;
            max-width: 290px;
            margin: auto;
        }
    }
  }
}