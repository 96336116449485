@charset "UTF-8";
/* ========================================================================
 * TABLE OF CONTENTS
 * ========================================================================
   01. ALERT
   02. CALLOUT
   03. BADGE
   04. BREADCRUMB
   05. BUTTON
   06. DROPDOWN
   07. FORM
   08. IMG BORDERED
   09. LABEL
   10. MEDIA IMAGE
   11. MISC
   12. MODALS
   13. PAGINATION
   14. PANEL
   15. PROGRESS BAR
   16. RATING STAR
   17. RESET
   18. RIBBONS
   19. TABLE
   20. TABS
   21. THUMBNAIL
   22. TOOLTIP
   23. TYPOGRAPHY
   24. WIDGETS
 * ======================================================================== */
/* ========================================================================
 * WIDGETS
 * ======================================================================== */
/* ========================================================================
 * TABLE OF CONTENT
 * ========================================================================
   OVERVIEW
   MINI STAT
   MINI STAT TYPE 3
   MINI STAT TYPE 4
   MINI STAT TYPE 5
   MINI STAT TYPE 6
   BLOG
   PROFILE
   CURRENT CITY
   FORECAST
   VISITOR CHART
   TO-DO
   RECENT ACTIVITY
   OVERVIEW SOCIAL
 * ======================================================================== */
.widget-wrapper {
  position: relative;
  border: none !important;
}

/* ========================================================================
 * OVERVIEW
 * ======================================================================== */
.widget-overview .overview-label {
  text-transform: uppercase;
  font-size: 11px;
  opacity: 0.75;
  display: block;
  line-height: normal;
  margin-bottom: 2px;
}
.widget-overview h1 {
  margin: 0;
  line-height: 36px;
  font-size: 38px;
}
.widget-overview .row {
  margin-bottom: 10px;
}
.widget-overview .row:last-child {
  margin-bottom: 0px;
}
.widget-overview h4 {
  margin-top: 2px;
  margin-bottom: 0px;
}

/* ========================================================================
 * MINI STAT
 * ======================================================================== */
.mini-stat {
  padding: 15px;
  margin-bottom: 20px;
}

.mini-stat-chart {
  width: auto;
  height: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
  float: left;
  margin-right: 10px;
}

.mini-stat-icon {
  width: 60px;
  height: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
  background: none repeat scroll 0% 0% #EEE;
  border-radius: 100%;
  float: left;
  margin-right: 10px;
  color: #FFF;
}

.mini-stat-info {
  font-size: 12px;
  padding-top: 2px;
}
.mini-stat-info span {
  display: block;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 7px;
}

/* ========================================================================
 * MINI STAT TYPE 3
 * ======================================================================== */
.mini-stat-type-3 {
  position: relative;
  background-color: #FBFBFB;
  padding: 10px;
  margin-bottom: 20px;
}
.mini-stat-type-3 h3 {
  margin-top: 8px;
}
.mini-stat-type-3 .meta-stat {
  display: block;
  border-top: 1px solid #DDD;
  font-size: 10px;
  color: #A7A7A7;
  padding-top: 5px;
  margin: 0 40px;
}

/* ========================================================================
 * MINI STAT TYPE 4
 * ======================================================================== */
.mini-stat-type-4 {
  text-align: center;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 20px;
}
.mini-stat-type-4 h1, .mini-stat-type-4 h3 {
  margin-top: 0;
  font-weight: 300;
}
.mini-stat-type-4 h1 {
  font-size: 100px;
}

/* ========================================================================
 * MINI STAT TYPE 5
 * ======================================================================== */
.mini-stat-type-5 {
  text-align: center;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 20px;
}
.mini-stat-type-5.border-facebook {
  border-top: 5px solid #3b5998;
}
.mini-stat-type-5.border-twitter {
  border-top: 5px solid #00a0d1;
}
.mini-stat-type-5.border-youtube {
  border-top: 5px solid #c4302b;
}
.mini-stat-type-5 h1, .mini-stat-type-5 h3 {
  margin-top: 0;
  font-weight: 300;
}
.mini-stat-type-5 h3 {
  font-size: 18px;
}
.mini-stat-type-5 h3 .label {
  display: inline-block;
  padding: 0;
  font-size: 10px;
  vertical-align: middle;
  padding-top: 2px;
}
.mini-stat-type-5 h1 {
  font-size: 35px;
  margin: 25px 0;
  position: relative;
}
.mini-stat-type-5 h1 .status, .mini-stat-type-5 h1 .number {
  display: inline-block;
}
.mini-stat-type-5 h1 .status {
  font-size: 15px;
  vertical-align: middle;
  margin-left: -55px;
}
.mini-stat-type-5 h1 .status .percent, .mini-stat-type-5 h1 .status i {
  display: block;
}
.mini-stat-type-5 p {
  margin: 0;
  font-size: 12px;
}

/* ========================================================================
 * MINI STAT TYPE 6
 * ======================================================================== */
.mini-stat-type-6 {
  background-color: #ffffff;
  margin-bottom: 20px;
}
.mini-stat-type-6 > .inner {
  padding: 10px;
}
.mini-stat-type-6 > .inner h3 {
  font-size: 30px;
  font-weight: bold;
  margin: 0 0 3px 0;
  white-space: nowrap;
  padding: 0;
}
.mini-stat-type-6 > .inner p {
  font-size: 15px;
}
.mini-stat-type-6 .icon {
  position: absolute;
  top: 20px;
  right: 25px;
  z-index: 0;
  font-size: 50px;
  color: rgba(0, 0, 0, 0.15);
}
.mini-stat-type-6 .small-box-footer {
  position: relative;
  text-align: center;
  padding: 10px 0;
  font-size: 15px;
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
.mini-stat-type-6 .small-box-footer:hover {
  background: rgba(0, 0, 0, 0.15);
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

/* ========================================================================
 * MINI STAT TYPE 2
 * ======================================================================== */
.mini-stat-type-2 {
  position: relative;
  background-color: #FBFBFB;
  padding: 10px;
  margin-bottom: 20px;
}
.mini-stat-type-2 h3, .mini-stat-type-2 p {
  margin: 0;
}
.mini-stat-type-2.border-danger {
  border-top: 15px solid #E9573F;
}
.mini-stat-type-2.border-success {
  border-top: 15px solid #8CC152;
}
.mini-stat-type-2.border-primary {
  border-top: 15px solid #00B1E1;
}
.mini-stat-type-2.border-lilac {
  border-top: 15px solid #906094;
}
.mini-stat-type-2 .text-muted {
  font-size: 10px;
}
.mini-stat-type-2 .overview-icon {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  text-align: center;
  display: block;
  content: "";
  line-height: 50px;
  font-size: 30px;
  margin: 15px auto;
}

/* ========================================================================
 * BLOG
 * ======================================================================== */
.panel-blog {
  padding: 20px;
}
.panel-blog .panel-body {
  background-color: #ffffff !important;
}

#blog-list .blog-title {
  color: #333;
  margin: 5px 0px 0px 0px;
  font-weight: 400;
  font-size: 16px;
}
#blog-list .blog-title a {
  color: #333;
}
#blog-list .blog-subtitle {
  font-size: 15px;
  text-transform: uppercase;
  color: #333;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 15px;
  margin-top: 20px;
}
#blog-list .blog-meta {
  list-style: none;
  padding: 0px;
  margin: 5px 0px;
  display: block;
}
#blog-list .blog-meta:after {
  clear: both;
  display: block;
  content: '';
}
#blog-list .blog-meta li {
  float: left;
  padding: 0px 5px;
  border-right: 1px solid #DDD;
  line-height: normal;
  font-size: 11px;
  margin-bottom: 5px;
}
#blog-list .blog-meta li:first-child {
  padding-left: 0px;
}
#blog-list .blog-meta li:last-child {
  padding-right: 0;
  border-right: 0;
}
#blog-list .blog-author {
  margin: 20px 0px 30px;
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  padding: 20px 0px;
}
#blog-list .blog-item {
  background: #ffffff;
  margin-bottom: 20px;
  position: relative;
}
#blog-list .blog-item .carousel {
  margin-bottom: 10px;
  padding-right: 10px;
  width: 300px;
}
#blog-list .blog-item .carousel:hover .carousel-control {
  display: block;
}
#blog-list .blog-item .carousel-caption {
  bottom: 10px;
  right: 10px;
  left: auto;
  padding-bottom: 0px;
  padding-top: 0px;
}
#blog-list .blog-item .carousel-control {
  display: block;
  background-image: none;
}
#blog-list .blog-item .carousel-control span {
  position: absolute;
  top: 40%;
  font-size: 20px;
  width: 30px;
  height: 30px;
  border: 1px solid white;
  border-radius: 50%;
  line-height: 27px;
}
#blog-list .blog-item .carousel-control.left span {
  right: 0px;
}
#blog-list .blog-item .carousel-control.right {
  right: 12px;
}
#blog-list .blog-item .carousel-control.right span {
  left: 0px;
}
#blog-list .blog-item .carousel-indicators {
  left: 15px;
  margin-left: 0px;
  bottom: 0px;
  width: auto;
}
#blog-list .blog-img {
  padding-right: 10px;
  width: 300px;
}
#blog-list .blog-details {
  padding-top: 4px;
}
#blog-list .blog-img, #blog-list .blog-details, #blog-list .quote, #blog-list .carousel {
  display: table-cell;
  vertical-align: top;
}
#blog-list .blog-summary {
  font-size: 13px;
}
#blog-list .blog-summary p {
  margin: 5px 0px;
}
#blog-list .blog-summary .btn {
  margin-top: 5px;
}
#blog-list .blog-quote .quote-primary a {
  color: #fff;
  background-color: #00B1E1;
}
#blog-list .blog-quote .quote-primary a:hover {
  background-color: #009dc8;
}
#blog-list .blog-quote .quote-success a {
  color: #fff;
  background-color: #8CC152;
}
#blog-list .blog-quote .quote-success a:hover {
  background-color: #7fb842;
}
#blog-list .blog-quote .quote-info a {
  color: #fff;
  background-color: #63D3E9;
}
#blog-list .blog-quote .quote-info a:hover {
  background-color: #4dcde6;
}
#blog-list .blog-quote .quote-warning a {
  color: #fff;
  background-color: #F6BB42;
}
#blog-list .blog-quote .quote-warning a:hover {
  background-color: #f5b22a;
}
#blog-list .blog-quote .quote-danger a {
  color: #fff;
  background-color: #E9573F;
}
#blog-list .blog-quote .quote-danger a:hover {
  background-color: #e64328;
}
#blog-list .blog-quote .quote-lilac a {
  color: #fff;
  background-color: #906094;
}
#blog-list .blog-quote .quote-lilac a:hover {
  background-color: #815685;
}
#blog-list .blog-quote .quote-inverse a {
  color: #fff;
  background-color: #2A2A2A;
}
#blog-list .blog-quote .quote-inverse a:hover {
  background-color: #1d1d1d;
}
#blog-list .blog-quote .quote-teal a {
  color: #fff;
  background-color: #37BC9B;
}
#blog-list .blog-quote .quote-teal a:hover {
  background-color: #31a88b;
}
#blog-list .blog-quote .quote {
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  width: 300px;
  padding-right: 10px;
}
#blog-list .blog-quote .quote a {
  padding: 22px;
  color: white;
  display: block;
  text-align: center;
  text-decoration: none;
}
#blog-list .blog-quote .quote a:hover {
  text-decoration: none;
}
#blog-list .blog-quote .quote-author {
  display: block;
  opacity: 0.5;
}
#blog-list .blog-quote .blog-meta {
  margin-top: 5px;
  margin-bottom: 5px;
}
#blog-list .blog-video {
  display: table-cell;
  position: relative;
  width: 290px;
  overflow: hidden;
  vertical-align: top;
  height: 235px;
}
#blog-list .blog-video iframe {
  position: absolute;
  width: 100%;
  height: 218px;
  border: none;
  top: 0;
  left: 0;
}
#blog-list .blog-video + .blog-details {
  padding-left: 10px;
}
#blog-list .blog-grid .media {
  border-bottom: 1px solid #DDD;
  padding: 15px;
  margin: 0px;
}
#blog-list .blog-grid .media:last-child {
  border-bottom: none;
  margin-bottom: 20px;
}
#blog-list .blog-grid .thumbnail {
  margin: 0px;
}
#blog-list .blog-grid a {
  font-size: 13px;
}
#blog-list .blog-grid b {
  font-size: 12px;
}
#blog-list .blog-grid img {
  width: 55px;
}
#blog-list .blog-list-slider .carousel-indicators {
  top: -33px;
  right: 10px;
  width: 17%;
  left: inherit;
}

.blog-title {
  color: #333;
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
}
.blog-title a {
  color: #333;
}

.blog-subtitle {
  font-size: 15px;
  text-transform: uppercase;
  color: #333;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 15px;
  margin-top: 20px;
}

.blog-meta {
  list-style: none;
  padding: 0px;
  margin: 10px 0px 10px;
  display: block;
}
.blog-meta:after {
  clear: both;
  display: block;
  content: '';
}
.blog-meta li {
  float: left;
  padding: 0px 5px;
  border-right: 1px solid #DDD;
  line-height: normal;
  font-size: 11px;
  margin-bottom: 5px;
}
.blog-meta li:first-child {
  padding-left: 0px;
}
.blog-meta li:last-child {
  padding-right: 0;
  border-right: 0;
}

.blog-img {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.blog-author {
  margin: 20px 0px 30px;
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  padding: 20px 0px;
}

.blog-item {
  background: #fcfcfc;
  margin-bottom: 20px;
  position: relative;
}
.blog-item .carousel {
  margin-bottom: 10px;
}
.blog-item .carousel:hover .carousel-control {
  display: block;
}
.blog-item .carousel-caption {
  bottom: 10px;
  right: 10px;
  left: auto;
  padding-bottom: 0px;
  padding-top: 0px;
}
.blog-item .carousel-control {
  display: block;
  background-image: none;
}
.blog-item .carousel-control span {
  position: absolute;
  top: 40%;
  font-size: 20px;
  width: 30px;
  height: 30px;
  border: 1px solid white;
  border-radius: 50%;
  line-height: 27px;
}
.blog-item .carousel-control.left span {
  right: 0px;
}
.blog-item .carousel-control.right span {
  left: 0px;
}
.blog-item .carousel-indicators {
  left: 15px;
  margin-left: 0px;
  bottom: 0px;
  width: auto;
}

.blog-details {
  padding: 10px;
}

.blog-summary {
  font-size: 13px;
}
.blog-summary p {
  margin: 10px 0;
}
.blog-summary .btn {
  margin-top: 10px;
}

.blog-quote .quote-primary a {
  color: #fff;
  background-color: #00B1E1;
}
.blog-quote .quote-primary a:hover {
  background-color: #009dc8;
}
.blog-quote .quote-success a {
  color: #fff;
  background-color: #8CC152;
}
.blog-quote .quote-success a:hover {
  background-color: #7fb842;
}
.blog-quote .quote-info a {
  color: #fff;
  background-color: #63D3E9;
}
.blog-quote .quote-info a:hover {
  background-color: #4dcde6;
}
.blog-quote .quote-warning a {
  color: #fff;
  background-color: #F6BB42;
}
.blog-quote .quote-warning a:hover {
  background-color: #f5b22a;
}
.blog-quote .quote-danger a {
  color: #fff;
  background-color: #E9573F;
}
.blog-quote .quote-danger a:hover {
  background-color: #e64328;
}
.blog-quote .quote-lilac a {
  color: #fff;
  background-color: #906094;
}
.blog-quote .quote-lilac a:hover {
  background-color: #815685;
}
.blog-quote .quote-inverse a {
  color: #fff;
  background-color: #2A2A2A;
}
.blog-quote .quote-inverse a:hover {
  background-color: #1d1d1d;
}
.blog-quote .quote-teal a {
  color: #fff;
  background-color: #37BC9B;
}
.blog-quote .quote-teal a:hover {
  background-color: #31a88b;
}
.blog-quote .quote {
  margin: 0;
  font-size: 15px;
  line-height: 24px;
}
.blog-quote .quote a {
  padding: 15px;
  color: white;
  display: block;
  text-align: center;
}
.blog-quote .quote a:hover {
  text-decoration: none;
}
.blog-quote .quote-author {
  display: block;
  opacity: 0.5;
  text-transform: capitalize;
}
.blog-quote .blog-meta {
  margin-top: 5px;
  margin-bottom: 5px;
}

.blog-video {
  overflow: hidden;
  height: 0;
  padding-top: 43px;
  padding-bottom: 50%;
  position: relative;
}
.blog-video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  top: 0;
  left: 0;
}

.blog-list .media {
  border-bottom: 1px solid #DDD;
  padding: 15px;
  margin: 0px;
}
.blog-list .media:last-child {
  border-bottom: none;
  margin-bottom: 20px;
}
.blog-list .thumbnail {
  margin: 0px;
}
.blog-list a {
  font-size: 13px;
}
.blog-list b {
  font-size: 12px;
}
.blog-list img {
  width: 55px;
}

.blog-list-slider .carousel-indicators {
  top: -33px;
  right: 10px;
  width: 17%;
  left: inherit;
}

.blog-tags {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-tags li, .blog-tags a {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  position: relative;
  font-size: 11px;
}
.blog-tags li {
  padding: 0px;
  margin-bottom: 10px;
}
.blog-tags a {
  margin-left: 20px;
  padding: 0 10px 0 12px;
  background: #00B1E1;
  color: #fff;
  text-decoration: none;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
.blog-tags a:before {
  content: "";
  float: left;
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-color: transparent #00B1E1 transparent transparent;
  border-style: solid;
  border-width: 12px 12px 12px 0;
}
.blog-tags a:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  float: left;
  width: 4px;
  height: 4px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #fff;
  -moz-box-shadow: -1px -1px 2px #00a5d2;
  -webkit-box-shadow: -1px -1px 2px #00a5d2;
  box-shadow: -1px -1px 2px #00a5d2;
}
.blog-tags a:hover {
  background: #555;
  color: white;
}
.blog-tags a:hover:before {
  border-color: transparent #555 transparent transparent;
}

.blog-twitter .blog-twitter-list {
  padding: 5px;
  position: relative;
  margin-bottom: 10px;
  border-right: solid 2px #DDD;
}
.blog-twitter .blog-twitter-list:hover {
  background-color: #e1e1e1;
  border-right: solid 2px #8CC152;
}
.blog-twitter .blog-twitter-list a {
  color: #4d90fe;
}
.blog-twitter .blog-twitter-list p {
  margin-bottom: 0;
}
.blog-twitter .blog-twitter-list span {
  color: #555;
  display: block;
  font-size: 12px;
}
.blog-twitter .blog-twitter-list .blog-twitter-icon {
  color: #c8c8c8;
  right: 10px;
  bottom: 10px;
  font-size: 30px;
  position: absolute;
}

.blog-gallery li {
  padding-right: 3px;
  margin-bottom: 10px;
  opacity: 0.8;
}
.blog-gallery li:hover {
  opacity: 1;
}

.comment-count {
  font-size: 13px;
  text-transform: uppercase;
  color: #333;
  margin-bottom: 15px;
  margin-top: 0px;
}

.comment-list .media {
  border-bottom: 1px dotted #DDD;
  padding-top: 15px !important;
  padding-bottom: 15px;
}
.comment-list .media:last-child {
  border-bottom: none;
}
.comment-list .media .media {
  border-bottom: none;
  padding-bottom: 0px;
}
.comment-list .media-object {
  width: 60px;
  margin-right: 5px;
}
.comment-list h4 {
  margin: 0px;
  color: #333;
  font-size: 16px;
}
.comment-list .media-body .media {
  border-top: 1px dotted #DDD;
  padding-top: 15px;
}
.comment-list .media-body p:last-child {
  margin-bottom: 0px;
}
.comment-list .reply {
  padding: 3px 8px;
  line-height: normal;
  border-radius: 2px;
}

@media (max-width: 360px) {
  #blog-list .blog-item {
    height: inherit !important;
  }
  #blog-list .blog-img,
  #blog-list .blog-quote .quote,
  #blog-list .blog-item .carousel,
  #blog-list .blog-video {
    width: 100%;
    display: block;
    padding-right: 0px;
  }
}
/* ========================================================================
 * PROFILE
 * ======================================================================== */
.profile-cover {
  width: 100%;
}
.profile-cover .cover {
  position: relative;
  border: 10px solid #FFF;
}
.profile-cover .cover .inner-cover {
  overflow: hidden;
  height: auto;
}
.profile-cover .cover .inner-cover img {
  border: 1px solid transparent;
  text-align: center;
  width: 100%;
}
.profile-cover .cover .inner-cover .cover-menu-mobile {
  position: absolute;
  top: 10px;
  right: 10px;
}
.profile-cover .cover .inner-cover .cover-menu-mobile button i {
  font-size: 17px;
}
.profile-cover .cover ul.cover-menu {
  padding-left: 150px;
  position: absolute;
  overflow: hidden;
  left: 1px;
  float: left;
  bottom: 0px;
  width: 100%;
  margin: 0px;
  background: none repeat scroll 0% 0% rgba(0, 0, 0, 0.24);
}
.profile-cover .cover ul.cover-menu li {
  display: block;
  float: left;
  margin-right: 0px;
  padding: 0px 10px;
  line-height: 40px;
  height: 40px;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.profile-cover .cover ul.cover-menu li:hover {
  background-color: rgba(0, 0, 0, 0.44);
}
.profile-cover .cover ul.cover-menu li.active {
  background-color: rgba(0, 0, 0, 0.64);
}
.profile-cover .cover ul.cover-menu li a {
  color: #FFF;
  font-weight: bold;
  display: block;
  height: 40px;
  line-height: 40px;
  text-decoration: none;
}
.profile-cover .cover ul.cover-menu li a i {
  font-size: 18px;
}
.profile-cover .profile-body {
  margin: 0px auto 10px;
  position: relative;
}
.profile-cover .profile-timeline {
  padding: 15px;
}

/* ========================================================================
 * CURRENT CITY
 * ======================================================================== */
.weather-current-city {
  padding: 10px;
  color: #fff;
  margin-bottom: 20px;
}
.weather-current-city img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 125px;
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
}

.current-city {
  display: block;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 10px;
}

.current-temp {
  display: block;
  font-weight: 700;
  letter-spacing: -3px;
  font-size: 2.8em;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  line-height: 1.1em;
}

.current-day {
  display: block;
  font-size: 1em;
  font-weight: 400;
  position: absolute;
}

.current-day-icon {
  display: block;
  float: right;
  font-size: 5.2em;
  font-weight: 700;
}

ul.days {
  list-style: none outside none;
  margin-top: 20px;
  padding: 0 10px;
  color: #fff;
}
ul.days li {
  text-align: center;
  border-left: 2px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 10px;
}
ul.days li:first-child {
  border-left: none;
}
ul.days li strong {
  position: relative;
  border-radius: 3px;
  font-size: 1.1em;
  text-transform: uppercase;
  line-height: 25px;
  margin-bottom: 5px;
  display: block;
}
ul.days li span {
  display: block;
  font-weight: 700;
  letter-spacing: -1px;
  font-size: 1.9em;
  margin-bottom: 5px;
}

/* ========================================================================
 * FORECAST
 * ======================================================================== */
.weather-forecast {
  position: relative;
  color: #fff;
}
.weather-forecast img {
  width: 100%;
  -moz-border-radius: 3px 3px 0px 0px;
  -webkit-border-radius: 3px;
  border-radius: 3px 3px 0px 0px;
}
.weather-forecast .title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.8);
  color: #FFF;
  position: absolute;
  left: 30px;
  bottom: 15px;
}
.weather-forecast .country-select {
  position: absolute;
  top: 10px;
  right: 27px;
}

.weather-full-info {
  color: #CBCBCB;
  background-color: white;
  padding: 20px;
  -moz-border-radius: 0px 0px 3px 3px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 3px 3px;
}
.weather-full-info .degree {
  font-size: 27px;
  font-weight: 600;
  display: inline-block;
  padding-left: 10px;
}
.weather-full-info .degree:after {
  content: "o";
  position: relative;
  top: -12px;
  font-size: 16px;
}
.weather-full-info ul {
  margin-bottom: 0px;
}
.weather-full-info ul li {
  list-style: none;
  border-left: 1px dotted #F1F2F7;
}
.weather-full-info ul li:first-child {
  border-left: none;
}
.weather-full-info ul li i {
  font-size: 25px;
}
.weather-full-info h2 {
  font-size: 14px;
  font-weight: 300;
  margin: 0px 0px 5px;
}
.weather-full-info .statistics {
  padding-top: 5px;
}
.weather-full-info .statistics:after {
  content: "o";
  position: relative;
  top: -5px;
  font-size: 10px;
}
.weather-full-info .today-status h1 {
  margin: 0px 0px 20px 0px;
  font-size: 19px;
}
.weather-full-info .today-status i {
  font-size: 30px;
  color: #81B71A;
}

@media (max-width: 600px) {
  .weather-full-info .today-status {
    text-align: center;
  }
}
@media (max-width: 360px) {
  .weather-full-info .today-status {
    margin-bottom: 10px;
  }
  .weather-full-info ul li {
    padding-bottom: 10px;
    padding-top: 10px;
    border-left: none !important;
  }
}
/* ========================================================================
 * VISITOR CHART
 * ======================================================================== */
.stat-stack.widget-visitor > .panel-body {
  background-color: #F5F5F5 !important;
}
.stat-stack.widget-visitor > .panel-body .text-strong {
  color: #636e7b;
}
.stat-stack.widget-visitor > .panel-body .text-muted {
  color: #777;
}
.stat-stack.widget-market > .panel-body {
  background-color: #FBFBFB !important;
}

.widget-visitor .stat-left .panel-footer {
  padding: 20px 10px 10px !important;
}
.widget-visitor .stat-left .panel-footer .border-right {
  border-right: 1px dotted #DDD;
}
.widget-visitor .stat-left .panel-footer .border-right:last-child {
  border-right: none;
}
.widget-visitor .stat-left .resize-chart {
  height: 250px;
}
.widget-visitor .stat-right .panel-footer {
  padding: 0px 20px 20px !important;
}

.widget-market .stat-left .panel-footer {
  padding: 20px 20px 10px !important;
}
.widget-market .stat-left .resize-chart {
  height: 310px;
}
.widget-market .stat-right .panel-footer {
  padding: 30px 20px 15px !important;
}
.widget-market .stat-right .resize-chart {
  width: 100%;
  height: 270px;
}

@media (max-width: 1024px) {
  .widget-market .stat-left .mini-stat .mini-stat-chart {
    float: none !important;
    margin-left: auto;
    margin-right: auto;
  }
  .widget-market .stat-left .mini-stat .mini-stat-info {
    text-align: center !important;
  }
  .widget-market .stat-left .panel-footer {
    padding: 20px 20px 10px !important;
  }
}
.stat-left {
  background-color: #F5F5F5;
}
.stat-left .panel-heading .panel-toolbar .btn-group .btn {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.stat-left .panel-heading .panel-toolbar .btn-group .btn.dropdown-toggle {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.stat-left .panel-footer h4 {
  margin-top: 0px;
  margin-bottom: 5px;
}
.stat-left .mini-stat-info span {
  font-size: 18px;
}
.stat-left .mini-stat-info p {
  font-size: 11px;
}
.stat-left .resize-chart {
  width: 100%;
}
.stat-left .resize-chart > .legend > div, .stat-left .resize-chart > .legend > table {
  top: 0px !important;
  left: 40px !important;
}
.stat-left .resize-chart > .legend > div:first-child {
  background-color: transparent !important;
}
.stat-left .resize-chart > .legend .legendColorBox {
  padding-right: 5px;
}
.stat-left .resize-chart > .legend .legendColorBox > div {
  border: none !important;
}
.stat-left .resize-chart > .legend .legendLabel {
  color: #FFFFFF;
}

@media (max-width: 360px) {
  .stat-left .panel-footer .row {
    margin: 0px;
  }
  .stat-left .panel-footer [class*="col-"] {
    border-bottom: 1px dotted #DDD;
    border-right: none !important;
    padding: 10px;
  }
  .stat-left .panel-footer [class*="col-"]:first-child {
    padding-top: 0px;
  }
  .stat-left .panel-footer [class*="col-"]:last-child {
    padding-bottom: 0px;
    border-bottom: none;
  }
}
.stat-right .panel-body {
  -moz-border-radius: 0px 3px 3px 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px 3px 3px 0px;
}
.stat-right .panel-body span {
  font-size: 12px;
}
.stat-right .progress {
  margin-bottom: 11px;
}
.stat-right .income-progress {
  padding: 0px 20px;
  background-color: #FBFBFB;
}
.stat-right .resize-chart {
  width: 100%;
  height: 100px;
}
.stat-right .panel-body {
  padding: 20px 20px 0px 20px !important;
}
.stat-right .panel-footer {
  padding: 0px 20px 10px !important;
  background-color: #FBFBFB;
  border-top: none;
}
.stat-right .panel-footer span {
  font-size: 11px;
}
.stat-right .flot-text {
  font-size: 11px;
  color: #999 !important;
}

/* ========================================================================
 * TO-DO
 * ======================================================================== */
.widget-todo .todo-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.widget-todo .todo-list li {
  border-bottom: 1px solid #DDD;
  padding: 8px 10px 2px;
}
.widget-todo .todo-list li input:checked + label {
  text-decoration: line-through;
  color: #999;
  font-size: 15px;
}

/* ========================================================================
 * RECENT ACTIVITY
 * ======================================================================== */
.recent-activity {
  margin: 0;
  padding: 0;
  position: relative;
  margin-bottom: 30px;
}
.recent-activity > h3 {
  margin-top: 0;
  font-size: 20px;
  font-weight: 300;
}

.recent-activity-item {
  position: relative;
  margin: 0;
  padding: 0;
}
.recent-activity-item:before {
  content: "";
  position: absolute;
  display: block;
  width: 3px;
  background: #dbdde2;
  top: 0px;
  bottom: -30px;
  margin-left: 8px;
}
.recent-activity-item.recent-activity-danger:before {
  background: #f6bbb1;
}
.recent-activity-item.recent-activity-danger .recent-activity-badge-userpic {
  border: 3px solid #f6bbb1;
}
.recent-activity-item.recent-activity-success:before {
  background: #cae3b0;
}
.recent-activity-item.recent-activity-success .recent-activity-badge-userpic {
  border: 3px solid #cae3b0;
}
.recent-activity-item.recent-activity-primary:before {
  background: #62ddff;
}
.recent-activity-item.recent-activity-primary .recent-activity-badge-userpic {
  border: 3px solid #62ddff;
}
.recent-activity-item.recent-activity-info:before {
  background: #d3f3f9;
}
.recent-activity-item.recent-activity-info .recent-activity-badge-userpic {
  border: 3px solid #d3f3f9;
}
.recent-activity-item.recent-activity-warning:before {
  background: #fce7bc;
}
.recent-activity-item.recent-activity-warning .recent-activity-badge-userpic {
  border: 3px solid #fce7bc;
}
.recent-activity-item.recent-activity-lilac:before {
  background: #c6abc9;
}
.recent-activity-item.recent-activity-lilac .recent-activity-badge-userpic {
  border: 3px solid #c6abc9;
}
.recent-activity-item.recent-activity-teals:before {
  background: #93dfcc;
}
.recent-activity-item.recent-activity-teals .recent-activity-badge-userpic {
  border: 3px solid #93dfcc;
}
.recent-activity-item.recent-activity-inverse:before {
  background: #6a6a6a;
}
.recent-activity-item.recent-activity-inverse .recent-activity-badge-userpic {
  border: 3px solid #6a6a6a;
}
.recent-activity-item.recent-activity-last:before {
  content: initial;
}

.recent-activity-badge {
  float: left;
  position: relative;
  padding-right: 20px;
  height: 20px;
  width: 20px;
}

.recent-activity-badge-userpic {
  width: 20px;
  height: 20px;
  content: "";
  display: block;
  border: 3px #E9EAED solid;
  background-color: #E9EAED;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50%;
  border-radius: 50% !important;
}

.recent-activity-body {
  position: relative;
  padding: 0;
  margin-top: 10px;
  margin-left: 30px;
}

.recent-activity-body:before, .recent-activity-body:after {
  content: " ";
  display: table;
}

.recent-activity-body:after {
  clear: both;
}

.recent-activity-body-head {
  margin-bottom: 10px;
}

.recent-activity-body-title {
  font-size: 15px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: capitalize;
}

.recent-activity-body-content {
  font-size: 13px;
  margin-top: 10px;
}
.recent-activity-body-content p:last-child {
  margin-bottom: 0;
}

.text-muted {
  color: #B0ADAD;
}

@media (max-width: 768px) {
  .recent-activity-body-head-caption {
    width: 100%;
  }
}
/* ========================================================================
 * OVERVIEW SOCIAL
 * ======================================================================== */
.widget-social-overview {
  position: relative;
}
.widget-social-overview .bg-facebook .ct-series-a .ct-line,
.widget-social-overview .bg-facebook .ct-series-a .ct-point {
  stroke: #FFFFFF;
}
.widget-social-overview .bg-facebook .ct-series-b .ct-line,
.widget-social-overview .bg-facebook .ct-series-b .ct-point {
  stroke: #87A1D0;
}
.widget-social-overview .bg-twitter .ct-series-a .ct-line,
.widget-social-overview .bg-twitter .ct-series-a .ct-point {
  stroke: #42C9F2;
}
.widget-social-overview .bg-youtube .ct-series-a .ct-area,
.widget-social-overview .bg-youtube .ct-series-a .ct-point {
  stroke: #FFFFFF;
  fill: #DABFBF;
}
.widget-social-overview .bg-youtube .ct-series-b .ct-area,
.widget-social-overview .bg-youtube .ct-series-b .ct-point {
  stroke: #F7403A;
  fill: #590502;
}
.widget-social-overview .overview-heading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
}
.widget-social-overview .overview-heading h3 {
  margin: 0;
  padding: 0;
}
.widget-social-overview .overview-heading .mark, .widget-social-overview .overview-heading .inline {
  display: inline-block;
}
.widget-social-overview .overview-heading .mark {
  height: 10px;
  width: 10px;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
.widget-social-overview .overview-heading ul li:last-child {
  padding-right: 0;
}
.widget-social-overview .panel-heading {
  padding-top: 50px !important;
  border-bottom: none !important;
}
.widget-social-overview .panel-body h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 300;
  color: #A5A5A5;
}
.widget-social-overview .panel-body p {
  text-align: left;
  font-weight: bold;
  font-size: 23px;
  margin: 0;
  padding: 0;
  color: #3f5fa3;
}
.widget-social-overview .panel-body p i {
  color: #3f5fa3;
  font-size: 20px;
  margin-right: 5px;
}
.widget-social-overview .panel-body .pull-right a {
  text-decoration: none;
}
.widget-social-overview .panel-body .pull-right a:hover i {
  color: #989898;
}
.widget-social-overview .panel-body .pull-right a i {
  font-size: 35px;
  font-weight: 300;
  color: #A5A5A5;
}
.widget-social-overview .ct-chart {
  width: 100%;
  height: 200px;
  margin-left: -10px;
}
.widget-social-overview .ct-grids line {
  stroke: white;
}

/* ========================================================================
 * ALERT
 * ======================================================================== */
.alert {
  position: relative;
}

.alert-dismissable .close, .alert-dismissible .close {
  position: absolute;
  top: 0px;
  right: 4px;
}

.alert-default {
  background-color: white;
  border-color: white;
  color: #bbbbbb;
  padding: 10px;
}
.alert-default .alert-icon {
  background-color: #c8c8c8;
}
.alert-default .alert-link, .alert-default a {
  color: #a2a2a2 !important;
  font-weight: bold;
}
.alert-default .notification-sender a {
  color: #bbbbbb;
}

.alert-primary {
  background-color: #7be3ff;
  border-color: #7be3ff;
  color: #00617b;
  padding: 10px;
}
.alert-primary .alert-icon {
  background-color: #007595;
}
.alert-primary .alert-link, .alert-primary a {
  color: #003948 !important;
  font-weight: bold;
}
.alert-primary .notification-sender a {
  color: #00617b;
}

.alert-success {
  background-color: #d7e9c3;
  border-color: #d7e9c3;
  color: #587f2e;
  padding: 10px;
}
.alert-success .alert-icon {
  background-color: #659234;
}
.alert-success .alert-link, .alert-success a {
  color: #3e5a20 !important;
  font-weight: bold;
}
.alert-success .notification-sender a {
  color: #587f2e;
}

.alert-info {
  background-color: #e9f9fc;
  border-color: #e9f9fc;
  color: #1cadca;
  padding: 10px;
}
.alert-info .alert-icon {
  background-color: #20c0e0;
}
.alert-info .alert-link, .alert-info a {
  color: #16879d !important;
  font-weight: bold;
}
.alert-info .notification-sender a {
  color: #1cadca;
}

.alert-warning {
  background-color: #fdf0d4;
  border-color: #fdf0d4;
  color: #c88a0a;
  padding: 10px;
}
.alert-warning .alert-icon {
  background-color: #e19b0b;
}
.alert-warning .alert-link, .alert-warning a {
  color: #986807 !important;
  font-weight: bold;
}
.alert-warning .notification-sender a {
  color: #c88a0a;
}

.alert-danger {
  background-color: #f9cfc8;
  border-color: #f9cfc8;
  color: #ae2a14;
  padding: 10px;
}
.alert-danger .alert-icon {
  background-color: #c52f17;
}
.alert-danger .alert-link, .alert-danger a {
  color: #801f0f !important;
  font-weight: bold;
}
.alert-danger .notification-sender a {
  color: #ae2a14;
}

.alert-lilac {
  background-color: #d1bad3;
  border-color: #d1bad3;
  color: #543856;
  padding: 10px;
}
.alert-lilac .alert-icon {
  background-color: #634266;
}
.alert-lilac .alert-link, .alert-lilac a {
  color: #362437 !important;
  font-weight: bold;
}
.alert-lilac .notification-sender a {
  color: #543856;
}

.alert-teals {
  background-color: #a7e5d6;
  border-color: #a7e5d6;
  color: #206d5a;
  padding: 10px;
}
.alert-teals .alert-icon {
  background-color: #26816a;
}
.alert-teals .alert-link, .alert-teals a {
  color: #144639 !important;
  font-weight: bold;
}
.alert-teals .notification-sender a {
  color: #206d5a;
}

.alert-inverse {
  background-color: #777777;
  border-color: #777777;
  color: black;
  padding: 10px;
}
.alert-inverse .alert-icon {
  background-color: #040404;
}
.alert-inverse .alert-link, .alert-inverse a {
  color: black !important;
  font-weight: bold;
}
.alert-inverse .notification-sender a {
  color: black;
}

.alert-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 100%;
}
.alert-icon i {
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #FFF;
}

.notification-info {
  margin-left: 56px;
  margin-top: -40px;
  min-height: 40px;
}
.notification-info p {
  margin: 0px;
  line-height: 13px;
}

.notification-meta {
  margin-bottom: 3px;
  padding-left: 0px;
  list-style: none outside none;
}

.notification-sender {
  color: #414147;
}

.notification-time {
  font-style: italic;
  color: #999;
}

/* ========================================================================
 * CALLOUT
 * ======================================================================= */
.callout {
  position: relative;
  margin: 0px;
  /*padding: 3px 5px 1px 0px;*/
  border-left: 5px solid #EEE;
  font-size: 0.9em;
  word-wrap: break-word;
}

.callout p:last-child {
  margin-bottom: 0;
}
.callout code, .callout .highlight {
  background-color: transparent;
}

.callout-info {
  background-color: #e9f9fc;
  border-color: #63D3E9;
}

.callout-info h4 {
  color: #36c6e3;
}
.callout-info a {
  color: #4dcde6;
  font-weight: bold;
}
.callout-info a:hover {
  color: #36c6e3;
}

.callout-warning {
  background-color: #fdf0d4;
  border-color: #F6BB42;
}
.callout-warning:before {
  position: absolute;
  left: 10px;
  top: 10px !important;
  display: block;
  background: #F6BB42;
  color: #fff;
  width: 40px;
  text-align: center;
  height: 40px;
  font-size: 1.5em;
  font-family: 'FontAwesome';
  content: "";
  line-height: 39px;
}
.callout-warning h4 {
  color: #f4a911;
}
.callout-warning a {
  color: #f5b22a;
  font-weight: bold;
}
.callout-warning a:hover {
  color: #f4a911;
}

.callout-danger {
  background-color: #f9cfc8;
  border-color: #E9573F;
}
.callout-danger:before {
  position: absolute;
  left: 10px;
  top: 10px !important;
  display: block;
  background: #E9573F;
  color: #fff;
  width: 40px;
  text-align: center;
  height: 40px;
  font-size: 1.5em;
  font-family: 'FontAwesome';
  content: "";
  line-height: 39px;
}
.callout-danger h4 {
  color: #dc3519;
}
.callout-danger a {
  color: #e64328;
  font-weight: bold;
}
.callout-danger a:hover {
  color: #dc3519;
}

/* ========================================================================
 * BADGE
 * ======================================================================== */
.badge {
  padding: 4px 7px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.badge-default {
  color: #999 !important;
}

.badge-default {
  background-color: #EEEEEE;
}
.badge-default.badge-stroke {
  background-color: transparent;
  border: 1px solid #EEEEEE;
  color: #EEEEEE;
}

.badge-primary {
  background-color: #00B1E1;
}
.badge-primary.badge-stroke {
  background-color: transparent;
  border: 1px solid #00B1E1;
  color: #00B1E1;
}

.badge-success {
  background-color: #8CC152;
}
.badge-success.badge-stroke {
  background-color: transparent;
  border: 1px solid #8CC152;
  color: #8CC152;
}

.badge-info {
  background-color: #63D3E9;
}
.badge-info.badge-stroke {
  background-color: transparent;
  border: 1px solid #63D3E9;
  color: #63D3E9;
}

.badge-warning {
  background-color: #F6BB42;
}
.badge-warning.badge-stroke {
  background-color: transparent;
  border: 1px solid #F6BB42;
  color: #F6BB42;
}

.badge-danger {
  background-color: #E9573F;
}
.badge-danger.badge-stroke {
  background-color: transparent;
  border: 1px solid #E9573F;
  color: #E9573F;
}

.badge-lilac {
  background-color: #906094;
}
.badge-lilac.badge-stroke {
  background-color: transparent;
  border: 1px solid #906094;
  color: #906094;
}

.badge-teals {
  background-color: #37BC9B;
}
.badge-teals.badge-stroke {
  background-color: transparent;
  border: 1px solid #37BC9B;
  color: #37BC9B;
}

.badge-inverse {
  background-color: #2A2A2A;
}
.badge-inverse.badge-stroke {
  background-color: transparent;
  border: 1px solid #2A2A2A;
  color: #2A2A2A;
}

.badge-circle {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  line-height: 10px;
  height: 15px;
  width: 15px;
  display: block;
}

/* ========================================================================
 * BREADCRUMB
 * ======================================================================== */
.breadcrumb {
  background-color: #F5F5F5;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* ========================================================================
 * BUTTON
 * ======================================================================== */
.btn {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  z-index: 1;
  background-color: transparent;
  font-weight: 300;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.btn:after {
  content: ' ';
  position: absolute;
  z-index: -1;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.btn:before {
  speak: none;
  text-transform: none;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.btn-default {
  color: #999 !important;
  border-color: #CCC !important;
}

.btn-xs {
  padding: 1px 5px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
  display: initial !important;
}

.btn-default {
  background-color: #EEEEEE;
  border-color: #EEEEEE;
  color: white;
}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .btn-default[disabled], .btn-default.disabled {
  background-color: #e1e1e1;
  border-color: transparent;
  color: white;
}
.btn-default.dropdown-toggle.btn-default {
  background-color: #EEEEEE;
  border-color: #e1e1e1;
  color: white;
}
.btn-default.btn-alt {
  background-color: #e1e1e1;
  border: 1px solid #EEEEEE;
}
.btn-default.btn-alt:hover {
  background-color: #d5d5d5;
}
.btn-default.btn-stroke {
  border: 1px double #EEEEEE;
  background-color: transparent;
  color: #EEEEEE;
}
.btn-default.btn-stroke:hover {
  background-color: #e1e1e1;
  border-color: transparent;
  color: white;
}
.btn-default.btn-solid {
  border: 1px solid #EEEEEE;
}
.btn-default.btn-dashed {
  border: 1px dashed #EEEEEE;
}
.btn-default.btn-dotted {
  border: 1px dotted #EEEEEE;
}
.btn-default.btn-double {
  border: 4px double #EEEEEE;
}
.btn-default.btn-inset {
  border: 4px inset #EEEEEE;
}
.btn-default.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}
.btn-default.btn-slidedown:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: #d5d5d5;
  z-index: -1;
}
.btn-default.btn-slidedown:hover, .btn-default.btn-slidedown:active {
  color: white;
}
.btn-default.btn-slidedown:hover:after, .btn-default.btn-slidedown:active:after {
  height: 100%;
}
.btn-default.btn-slideright:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #d5d5d5;
  z-index: -1;
}
.btn-default.btn-slideright:hover, .btn-default.btn-slideright:active {
  color: white;
}
.btn-default.btn-slideright:hover:after, .btn-default.btn-slideright:active:after {
  width: 100%;
}
.btn-default.btn-expand:after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background-color: #d5d5d5;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-default.btn-expand:hover:after {
  width: 90%;
  opacity: 1;
}
.btn-default.btn-expand:active:after {
  width: 101%;
  opacity: 1;
}
.btn-default.btn-rotate {
  overflow: hidden;
}
.btn-default.btn-rotate:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #d5d5d5;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.btn-default.btn-rotate:hover:after {
  height: 260%;
  opacity: 1;
}
.btn-default.btn-rotate:active:after {
  height: 400%;
  opacity: 1;
}
.btn-default.btn-open {
  overflow: hidden;
}
.btn-default.btn-open:after {
  width: 101%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #d5d5d5;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-default.btn-open:hover:after {
  height: 75%;
  opacity: 1;
}
.btn-default.btn-open:active:after {
  height: 130%;
  opacity: 1;
}
.btn-default.btn-push {
  background: #EEEEEE;
  box-shadow: 0 6px #d5d5d5;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-default.btn-push:hover {
  box-shadow: 0 4px #d5d5d5;
  top: 2px;
}
.btn-default.btn-push:active {
  box-shadow: 0 0 #d5d5d5;
  top: 6px;
}
.btn-default.btn-pushright {
  background: #EEEEEE;
  box-shadow: 6px 0 #d5d5d5;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-default.btn-pushright:hover {
  box-shadow: 4px 0 #d5d5d5;
  left: 2px;
}
.btn-default.btn-pushright:active {
  box-shadow: 0 0 #d5d5d5;
  left: 6px;
}

.btn-primary {
  background-color: #00B1E1;
  border-color: #00B1E1;
  color: white;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary[disabled], .btn-primary.disabled {
  background-color: #009dc8;
  border-color: transparent;
  color: white;
}
.btn-primary.dropdown-toggle.btn-primary {
  background-color: #00B1E1;
  border-color: #009dc8;
  color: white;
}
.btn-primary.btn-alt {
  background-color: #009dc8;
  border: 1px solid #00B1E1;
}
.btn-primary.btn-alt:hover {
  background-color: #0089ae;
}
.btn-primary.btn-stroke {
  border: 1px double #00B1E1;
  background-color: transparent;
  color: #00B1E1;
}
.btn-primary.btn-stroke:hover {
  background-color: #009dc8;
  border-color: transparent;
  color: white;
}
.btn-primary.btn-solid {
  border: 1px solid #00B1E1;
}
.btn-primary.btn-dashed {
  border: 1px dashed #00B1E1;
}
.btn-primary.btn-dotted {
  border: 1px dotted #00B1E1;
}
.btn-primary.btn-double {
  border: 4px double #00B1E1;
}
.btn-primary.btn-inset {
  border: 4px inset #00B1E1;
}
.btn-primary.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}
.btn-primary.btn-slidedown:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: #0089ae;
  z-index: -1;
}
.btn-primary.btn-slidedown:hover, .btn-primary.btn-slidedown:active {
  color: white;
}
.btn-primary.btn-slidedown:hover:after, .btn-primary.btn-slidedown:active:after {
  height: 100%;
}
.btn-primary.btn-slideright:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #0089ae;
  z-index: -1;
}
.btn-primary.btn-slideright:hover, .btn-primary.btn-slideright:active {
  color: white;
}
.btn-primary.btn-slideright:hover:after, .btn-primary.btn-slideright:active:after {
  width: 100%;
}
.btn-primary.btn-expand:after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background-color: #0089ae;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-primary.btn-expand:hover:after {
  width: 90%;
  opacity: 1;
}
.btn-primary.btn-expand:active:after {
  width: 101%;
  opacity: 1;
}
.btn-primary.btn-rotate {
  overflow: hidden;
}
.btn-primary.btn-rotate:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #0089ae;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.btn-primary.btn-rotate:hover:after {
  height: 260%;
  opacity: 1;
}
.btn-primary.btn-rotate:active:after {
  height: 400%;
  opacity: 1;
}
.btn-primary.btn-open {
  overflow: hidden;
}
.btn-primary.btn-open:after {
  width: 101%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #0089ae;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-primary.btn-open:hover:after {
  height: 75%;
  opacity: 1;
}
.btn-primary.btn-open:active:after {
  height: 130%;
  opacity: 1;
}
.btn-primary.btn-push {
  background: #00B1E1;
  box-shadow: 0 6px #0089ae;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-primary.btn-push:hover {
  box-shadow: 0 4px #0089ae;
  top: 2px;
}
.btn-primary.btn-push:active {
  box-shadow: 0 0 #0089ae;
  top: 6px;
}
.btn-primary.btn-pushright {
  background: #00B1E1;
  box-shadow: 6px 0 #0089ae;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-primary.btn-pushright:hover {
  box-shadow: 4px 0 #0089ae;
  left: 2px;
}
.btn-primary.btn-pushright:active {
  box-shadow: 0 0 #0089ae;
  left: 6px;
}

.btn-success {
  background-color: #8CC152;
  border-color: #8CC152;
  color: white;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success[disabled], .btn-success.disabled {
  background-color: #7fb842;
  border-color: transparent;
  color: white;
}
.btn-success.dropdown-toggle.btn-success {
  background-color: #8CC152;
  border-color: #7fb842;
  color: white;
}
.btn-success.btn-alt {
  background-color: #7fb842;
  border: 1px solid #8CC152;
}
.btn-success.btn-alt:hover {
  background-color: #72a53b;
}
.btn-success.btn-stroke {
  border: 1px double #8CC152;
  background-color: transparent;
  color: #8CC152;
}
.btn-success.btn-stroke:hover {
  background-color: #7fb842;
  border-color: transparent;
  color: white;
}
.btn-success.btn-solid {
  border: 1px solid #8CC152;
}
.btn-success.btn-dashed {
  border: 1px dashed #8CC152;
}
.btn-success.btn-dotted {
  border: 1px dotted #8CC152;
}
.btn-success.btn-double {
  border: 4px double #8CC152;
}
.btn-success.btn-inset {
  border: 4px inset #8CC152;
}
.btn-success.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}
.btn-success.btn-slidedown:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: #72a53b;
  z-index: -1;
}
.btn-success.btn-slidedown:hover, .btn-success.btn-slidedown:active {
  color: white;
}
.btn-success.btn-slidedown:hover:after, .btn-success.btn-slidedown:active:after {
  height: 100%;
}
.btn-success.btn-slideright:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #72a53b;
  z-index: -1;
}
.btn-success.btn-slideright:hover, .btn-success.btn-slideright:active {
  color: white;
}
.btn-success.btn-slideright:hover:after, .btn-success.btn-slideright:active:after {
  width: 100%;
}
.btn-success.btn-expand:after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background-color: #72a53b;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-success.btn-expand:hover:after {
  width: 90%;
  opacity: 1;
}
.btn-success.btn-expand:active:after {
  width: 101%;
  opacity: 1;
}
.btn-success.btn-rotate {
  overflow: hidden;
}
.btn-success.btn-rotate:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #72a53b;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.btn-success.btn-rotate:hover:after {
  height: 260%;
  opacity: 1;
}
.btn-success.btn-rotate:active:after {
  height: 400%;
  opacity: 1;
}
.btn-success.btn-open {
  overflow: hidden;
}
.btn-success.btn-open:after {
  width: 101%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #72a53b;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-success.btn-open:hover:after {
  height: 75%;
  opacity: 1;
}
.btn-success.btn-open:active:after {
  height: 130%;
  opacity: 1;
}
.btn-success.btn-push {
  background: #8CC152;
  box-shadow: 0 6px #72a53b;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-success.btn-push:hover {
  box-shadow: 0 4px #72a53b;
  top: 2px;
}
.btn-success.btn-push:active {
  box-shadow: 0 0 #72a53b;
  top: 6px;
}
.btn-success.btn-pushright {
  background: #8CC152;
  box-shadow: 6px 0 #72a53b;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-success.btn-pushright:hover {
  box-shadow: 4px 0 #72a53b;
  left: 2px;
}
.btn-success.btn-pushright:active {
  box-shadow: 0 0 #72a53b;
  left: 6px;
}

.btn-info {
  background-color: #63D3E9;
  border-color: #63D3E9;
  color: white;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info[disabled], .btn-info.disabled {
  background-color: #4dcde6;
  border-color: transparent;
  color: white;
}
.btn-info.dropdown-toggle.btn-info {
  background-color: #63D3E9;
  border-color: #4dcde6;
  color: white;
}
.btn-info.btn-alt {
  background-color: #4dcde6;
  border: 1px solid #63D3E9;
}
.btn-info.btn-alt:hover {
  background-color: #36c6e3;
}
.btn-info.btn-stroke {
  border: 1px double #63D3E9;
  background-color: transparent;
  color: #63D3E9;
}
.btn-info.btn-stroke:hover {
  background-color: #4dcde6;
  border-color: transparent;
  color: white;
}
.btn-info.btn-solid {
  border: 1px solid #63D3E9;
}
.btn-info.btn-dashed {
  border: 1px dashed #63D3E9;
}
.btn-info.btn-dotted {
  border: 1px dotted #63D3E9;
}
.btn-info.btn-double {
  border: 4px double #63D3E9;
}
.btn-info.btn-inset {
  border: 4px inset #63D3E9;
}
.btn-info.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}
.btn-info.btn-slidedown:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: #36c6e3;
  z-index: -1;
}
.btn-info.btn-slidedown:hover, .btn-info.btn-slidedown:active {
  color: white;
}
.btn-info.btn-slidedown:hover:after, .btn-info.btn-slidedown:active:after {
  height: 100%;
}
.btn-info.btn-slideright:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #36c6e3;
  z-index: -1;
}
.btn-info.btn-slideright:hover, .btn-info.btn-slideright:active {
  color: white;
}
.btn-info.btn-slideright:hover:after, .btn-info.btn-slideright:active:after {
  width: 100%;
}
.btn-info.btn-expand:after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background-color: #36c6e3;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-info.btn-expand:hover:after {
  width: 90%;
  opacity: 1;
}
.btn-info.btn-expand:active:after {
  width: 101%;
  opacity: 1;
}
.btn-info.btn-rotate {
  overflow: hidden;
}
.btn-info.btn-rotate:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #36c6e3;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.btn-info.btn-rotate:hover:after {
  height: 260%;
  opacity: 1;
}
.btn-info.btn-rotate:active:after {
  height: 400%;
  opacity: 1;
}
.btn-info.btn-open {
  overflow: hidden;
}
.btn-info.btn-open:after {
  width: 101%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #36c6e3;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-info.btn-open:hover:after {
  height: 75%;
  opacity: 1;
}
.btn-info.btn-open:active:after {
  height: 130%;
  opacity: 1;
}
.btn-info.btn-push {
  background: #63D3E9;
  box-shadow: 0 6px #36c6e3;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-info.btn-push:hover {
  box-shadow: 0 4px #36c6e3;
  top: 2px;
}
.btn-info.btn-push:active {
  box-shadow: 0 0 #36c6e3;
  top: 6px;
}
.btn-info.btn-pushright {
  background: #63D3E9;
  box-shadow: 6px 0 #36c6e3;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-info.btn-pushright:hover {
  box-shadow: 4px 0 #36c6e3;
  left: 2px;
}
.btn-info.btn-pushright:active {
  box-shadow: 0 0 #36c6e3;
  left: 6px;
}

.btn-warning {
  background-color: #F6BB42;
  border-color: #F6BB42;
  color: white;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning[disabled], .btn-warning.disabled {
  background-color: #f5b22a;
  border-color: transparent;
  color: white;
}
.btn-warning.dropdown-toggle.btn-warning {
  background-color: #F6BB42;
  border-color: #f5b22a;
  color: white;
}
.btn-warning.btn-alt {
  background-color: #f5b22a;
  border: 1px solid #F6BB42;
}
.btn-warning.btn-alt:hover {
  background-color: #f4a911;
}
.btn-warning.btn-stroke {
  border: 1px double #F6BB42;
  background-color: transparent;
  color: #F6BB42;
}
.btn-warning.btn-stroke:hover {
  background-color: #f5b22a;
  border-color: transparent;
  color: white;
}
.btn-warning.btn-solid {
  border: 1px solid #F6BB42;
}
.btn-warning.btn-dashed {
  border: 1px dashed #F6BB42;
}
.btn-warning.btn-dotted {
  border: 1px dotted #F6BB42;
}
.btn-warning.btn-double {
  border: 4px double #F6BB42;
}
.btn-warning.btn-inset {
  border: 4px inset #F6BB42;
}
.btn-warning.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}
.btn-warning.btn-slidedown:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: #f4a911;
  z-index: -1;
}
.btn-warning.btn-slidedown:hover, .btn-warning.btn-slidedown:active {
  color: white;
}
.btn-warning.btn-slidedown:hover:after, .btn-warning.btn-slidedown:active:after {
  height: 100%;
}
.btn-warning.btn-slideright:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #f4a911;
  z-index: -1;
}
.btn-warning.btn-slideright:hover, .btn-warning.btn-slideright:active {
  color: white;
}
.btn-warning.btn-slideright:hover:after, .btn-warning.btn-slideright:active:after {
  width: 100%;
}
.btn-warning.btn-expand:after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background-color: #f4a911;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-warning.btn-expand:hover:after {
  width: 90%;
  opacity: 1;
}
.btn-warning.btn-expand:active:after {
  width: 101%;
  opacity: 1;
}
.btn-warning.btn-rotate {
  overflow: hidden;
}
.btn-warning.btn-rotate:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #f4a911;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.btn-warning.btn-rotate:hover:after {
  height: 260%;
  opacity: 1;
}
.btn-warning.btn-rotate:active:after {
  height: 400%;
  opacity: 1;
}
.btn-warning.btn-open {
  overflow: hidden;
}
.btn-warning.btn-open:after {
  width: 101%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #f4a911;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-warning.btn-open:hover:after {
  height: 75%;
  opacity: 1;
}
.btn-warning.btn-open:active:after {
  height: 130%;
  opacity: 1;
}
.btn-warning.btn-push {
  background: #F6BB42;
  box-shadow: 0 6px #f4a911;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-warning.btn-push:hover {
  box-shadow: 0 4px #f4a911;
  top: 2px;
}
.btn-warning.btn-push:active {
  box-shadow: 0 0 #f4a911;
  top: 6px;
}
.btn-warning.btn-pushright {
  background: #F6BB42;
  box-shadow: 6px 0 #f4a911;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-warning.btn-pushright:hover {
  box-shadow: 4px 0 #f4a911;
  left: 2px;
}
.btn-warning.btn-pushright:active {
  box-shadow: 0 0 #f4a911;
  left: 6px;
}

.btn-danger {
  background-color: #E9573F;
  border-color: #E9573F;
  color: white;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .btn-danger[disabled], .btn-danger.disabled {
  background-color: #e64328;
  border-color: transparent;
  color: white;
}
.btn-danger.dropdown-toggle.btn-danger {
  background-color: #E9573F;
  border-color: #e64328;
  color: white;
}
.btn-danger.btn-alt {
  background-color: #e64328;
  border: 1px solid #E9573F;
}
.btn-danger.btn-alt:hover {
  background-color: #dc3519;
}
.btn-danger.btn-stroke {
  border: 1px double #E9573F;
  background-color: transparent;
  color: #E9573F;
}
.btn-danger.btn-stroke:hover {
  background-color: #e64328;
  border-color: transparent;
  color: white;
}
.btn-danger.btn-solid {
  border: 1px solid #E9573F;
}
.btn-danger.btn-dashed {
  border: 1px dashed #E9573F;
}
.btn-danger.btn-dotted {
  border: 1px dotted #E9573F;
}
.btn-danger.btn-double {
  border: 4px double #E9573F;
}
.btn-danger.btn-inset {
  border: 4px inset #E9573F;
}
.btn-danger.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}
.btn-danger.btn-slidedown:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: #dc3519;
  z-index: -1;
}
.btn-danger.btn-slidedown:hover, .btn-danger.btn-slidedown:active {
  color: white;
}
.btn-danger.btn-slidedown:hover:after, .btn-danger.btn-slidedown:active:after {
  height: 100%;
}
.btn-danger.btn-slideright:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #dc3519;
  z-index: -1;
}
.btn-danger.btn-slideright:hover, .btn-danger.btn-slideright:active {
  color: white;
}
.btn-danger.btn-slideright:hover:after, .btn-danger.btn-slideright:active:after {
  width: 100%;
}
.btn-danger.btn-expand:after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background-color: #dc3519;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-danger.btn-expand:hover:after {
  width: 90%;
  opacity: 1;
}
.btn-danger.btn-expand:active:after {
  width: 101%;
  opacity: 1;
}
.btn-danger.btn-rotate {
  overflow: hidden;
}
.btn-danger.btn-rotate:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #dc3519;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.btn-danger.btn-rotate:hover:after {
  height: 260%;
  opacity: 1;
}
.btn-danger.btn-rotate:active:after {
  height: 400%;
  opacity: 1;
}
.btn-danger.btn-open {
  overflow: hidden;
}
.btn-danger.btn-open:after {
  width: 101%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #dc3519;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-danger.btn-open:hover:after {
  height: 75%;
  opacity: 1;
}
.btn-danger.btn-open:active:after {
  height: 130%;
  opacity: 1;
}
.btn-danger.btn-push {
  background: #E9573F;
  box-shadow: 0 6px #dc3519;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-danger.btn-push:hover {
  box-shadow: 0 4px #dc3519;
  top: 2px;
}
.btn-danger.btn-push:active {
  box-shadow: 0 0 #dc3519;
  top: 6px;
}
.btn-danger.btn-pushright {
  background: #E9573F;
  box-shadow: 6px 0 #dc3519;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-danger.btn-pushright:hover {
  box-shadow: 4px 0 #dc3519;
  left: 2px;
}
.btn-danger.btn-pushright:active {
  box-shadow: 0 0 #dc3519;
  left: 6px;
}

.btn-lilac {
  background-color: #906094;
  border-color: #906094;
  color: white;
}
.btn-lilac:hover, .btn-lilac:focus, .btn-lilac:active, .btn-lilac.active, .btn-lilac[disabled], .btn-lilac.disabled {
  background-color: #815685;
  border-color: transparent;
  color: white;
}
.btn-lilac.dropdown-toggle.btn-lilac {
  background-color: #906094;
  border-color: #815685;
  color: white;
}
.btn-lilac.btn-alt {
  background-color: #815685;
  border: 1px solid #906094;
}
.btn-lilac.btn-alt:hover {
  background-color: #724c75;
}
.btn-lilac.btn-stroke {
  border: 1px double #906094;
  background-color: transparent;
  color: #906094;
}
.btn-lilac.btn-stroke:hover {
  background-color: #815685;
  border-color: transparent;
  color: white;
}
.btn-lilac.btn-solid {
  border: 1px solid #906094;
}
.btn-lilac.btn-dashed {
  border: 1px dashed #906094;
}
.btn-lilac.btn-dotted {
  border: 1px dotted #906094;
}
.btn-lilac.btn-double {
  border: 4px double #906094;
}
.btn-lilac.btn-inset {
  border: 4px inset #906094;
}
.btn-lilac.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}
.btn-lilac.btn-slidedown:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: #724c75;
  z-index: -1;
}
.btn-lilac.btn-slidedown:hover, .btn-lilac.btn-slidedown:active {
  color: white;
}
.btn-lilac.btn-slidedown:hover:after, .btn-lilac.btn-slidedown:active:after {
  height: 100%;
}
.btn-lilac.btn-slideright:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #724c75;
  z-index: -1;
}
.btn-lilac.btn-slideright:hover, .btn-lilac.btn-slideright:active {
  color: white;
}
.btn-lilac.btn-slideright:hover:after, .btn-lilac.btn-slideright:active:after {
  width: 100%;
}
.btn-lilac.btn-expand:after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background-color: #724c75;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-lilac.btn-expand:hover:after {
  width: 90%;
  opacity: 1;
}
.btn-lilac.btn-expand:active:after {
  width: 101%;
  opacity: 1;
}
.btn-lilac.btn-rotate {
  overflow: hidden;
}
.btn-lilac.btn-rotate:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #724c75;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.btn-lilac.btn-rotate:hover:after {
  height: 260%;
  opacity: 1;
}
.btn-lilac.btn-rotate:active:after {
  height: 400%;
  opacity: 1;
}
.btn-lilac.btn-open {
  overflow: hidden;
}
.btn-lilac.btn-open:after {
  width: 101%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #724c75;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-lilac.btn-open:hover:after {
  height: 75%;
  opacity: 1;
}
.btn-lilac.btn-open:active:after {
  height: 130%;
  opacity: 1;
}
.btn-lilac.btn-push {
  background: #906094;
  box-shadow: 0 6px #724c75;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-lilac.btn-push:hover {
  box-shadow: 0 4px #724c75;
  top: 2px;
}
.btn-lilac.btn-push:active {
  box-shadow: 0 0 #724c75;
  top: 6px;
}
.btn-lilac.btn-pushright {
  background: #906094;
  box-shadow: 6px 0 #724c75;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-lilac.btn-pushright:hover {
  box-shadow: 4px 0 #724c75;
  left: 2px;
}
.btn-lilac.btn-pushright:active {
  box-shadow: 0 0 #724c75;
  left: 6px;
}

.btn-inverse {
  background-color: #2A2A2A;
  border-color: #2A2A2A;
  color: white;
}
.btn-inverse:hover, .btn-inverse:focus, .btn-inverse:active, .btn-inverse.active, .btn-inverse[disabled], .btn-inverse.disabled {
  background-color: #1d1d1d;
  border-color: transparent;
  color: white;
}
.btn-inverse.dropdown-toggle.btn-inverse {
  background-color: #2A2A2A;
  border-color: #1d1d1d;
  color: white;
}
.btn-inverse.btn-alt {
  background-color: #1d1d1d;
  border: 1px solid #2A2A2A;
}
.btn-inverse.btn-alt:hover {
  background-color: #111111;
}
.btn-inverse.btn-stroke {
  border: 1px double #2A2A2A;
  background-color: transparent;
  color: #2A2A2A;
}
.btn-inverse.btn-stroke:hover {
  background-color: #1d1d1d;
  border-color: transparent;
  color: white;
}
.btn-inverse.btn-solid {
  border: 1px solid #2A2A2A;
}
.btn-inverse.btn-dashed {
  border: 1px dashed #2A2A2A;
}
.btn-inverse.btn-dotted {
  border: 1px dotted #2A2A2A;
}
.btn-inverse.btn-double {
  border: 4px double #2A2A2A;
}
.btn-inverse.btn-inset {
  border: 4px inset #2A2A2A;
}
.btn-inverse.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}
.btn-inverse.btn-slidedown:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: #111111;
  z-index: -1;
}
.btn-inverse.btn-slidedown:hover, .btn-inverse.btn-slidedown:active {
  color: white;
}
.btn-inverse.btn-slidedown:hover:after, .btn-inverse.btn-slidedown:active:after {
  height: 100%;
}
.btn-inverse.btn-slideright:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #111111;
  z-index: -1;
}
.btn-inverse.btn-slideright:hover, .btn-inverse.btn-slideright:active {
  color: white;
}
.btn-inverse.btn-slideright:hover:after, .btn-inverse.btn-slideright:active:after {
  width: 100%;
}
.btn-inverse.btn-expand:after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background-color: #111111;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-inverse.btn-expand:hover:after {
  width: 90%;
  opacity: 1;
}
.btn-inverse.btn-expand:active:after {
  width: 101%;
  opacity: 1;
}
.btn-inverse.btn-rotate {
  overflow: hidden;
}
.btn-inverse.btn-rotate:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #111111;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.btn-inverse.btn-rotate:hover:after {
  height: 260%;
  opacity: 1;
}
.btn-inverse.btn-rotate:active:after {
  height: 400%;
  opacity: 1;
}
.btn-inverse.btn-open {
  overflow: hidden;
}
.btn-inverse.btn-open:after {
  width: 101%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #111111;
  opacity: 0;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-inverse.btn-open:hover:after {
  height: 75%;
  opacity: 1;
}
.btn-inverse.btn-open:active:after {
  height: 130%;
  opacity: 1;
}
.btn-inverse.btn-push {
  background: #2A2A2A;
  box-shadow: 0 6px #111111;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-inverse.btn-push:hover {
  box-shadow: 0 4px #111111;
  top: 2px;
}
.btn-inverse.btn-push:active {
  box-shadow: 0 0 #111111;
  top: 6px;
}
.btn-inverse.btn-pushright {
  background: #2A2A2A;
  box-shadow: 6px 0 #111111;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.btn-inverse.btn-pushright:hover {
  box-shadow: 4px 0 #111111;
  left: 2px;
}
.btn-inverse.btn-pushright:active {
  box-shadow: 0 0 #111111;
  left: 6px;
}

.btn-teal {
  color: #FFF;
  background-color: #37BC9B;
  border-color: #31a88b;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-teal:hover, .btn-teal:focus, .btn-teal:active {
  color: #FFF !important;
  background-color: #31a88b;
}
.btn-teal.btn-stroke {
  border: 1px double #37BC9B;
  background-color: transparent;
  color: #37BC9B;
}
.btn-teal.btn-stroke:hover {
  color: #FFF !important;
  background-color: #31a88b;
}
.btn-teal.btn-solid {
  border: 1px solid #37BC9B;
}
.btn-teal.btn-dashed {
  border: 1px dashed #37BC9B;
}
.btn-teal.btn-dotted {
  border: 1px dotted #37BC9B;
}
.btn-teal.btn-double {
  border: 4px double #37BC9B;
}
.btn-teal.btn-inset {
  border: 4px inset #37BC9B;
}
.btn-teal.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-bitbucket {
  color: #FFF;
  background-color: #205081;
  border-color: #205081;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-bitbucket:hover, .btn-bitbucket:focus, .btn-bitbucket:active {
  color: #FFF !important;
  background-color: #1b436d;
}
.btn-bitbucket.btn-stroke {
  border: 1px double #205081;
  background-color: transparent;
  color: #205081;
}
.btn-bitbucket.btn-stroke:hover {
  color: #FFF !important;
  background-color: #1b436d;
}
.btn-bitbucket.btn-solid {
  border: 1px solid #205081;
}
.btn-bitbucket.btn-dashed {
  border: 1px dashed #205081;
}
.btn-bitbucket.btn-dotted {
  border: 1px dotted #205081;
}
.btn-bitbucket.btn-double {
  border: 4px double #205081;
}
.btn-bitbucket.btn-inset {
  border: 4px inset #205081;
}
.btn-bitbucket.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-behance {
  color: #FFF;
  background-color: #053eff;
  border-color: #053eff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-behance:hover, .btn-behance:focus, .btn-behance:active {
  color: #FFF !important;
  background-color: #0035eb;
}
.btn-behance.btn-stroke {
  border: 1px double #053eff;
  background-color: transparent;
  color: #053eff;
}
.btn-behance.btn-stroke:hover {
  color: #FFF !important;
  background-color: #0035eb;
}
.btn-behance.btn-solid {
  border: 1px solid #053eff;
}
.btn-behance.btn-dashed {
  border: 1px dashed #053eff;
}
.btn-behance.btn-dotted {
  border: 1px dotted #053eff;
}
.btn-behance.btn-double {
  border: 4px double #053eff;
}
.btn-behance.btn-inset {
  border: 4px inset #053eff;
}
.btn-behance.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-delicious {
  color: #FFF;
  background-color: #205cc0;
  border-color: #205cc0;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-delicious:hover, .btn-delicious:focus, .btn-delicious:active {
  color: #FFF !important;
  background-color: #1c52aa;
}
.btn-delicious.btn-stroke {
  border: 1px double #205cc0;
  background-color: transparent;
  color: #205cc0;
}
.btn-delicious.btn-stroke:hover {
  color: #FFF !important;
  background-color: #1c52aa;
}
.btn-delicious.btn-solid {
  border: 1px solid #205cc0;
}
.btn-delicious.btn-dashed {
  border: 1px dashed #205cc0;
}
.btn-delicious.btn-dotted {
  border: 1px dotted #205cc0;
}
.btn-delicious.btn-double {
  border: 4px double #205cc0;
}
.btn-delicious.btn-inset {
  border: 4px inset #205cc0;
}
.btn-delicious.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-dribbble {
  color: #FFF;
  background-color: #ea4c89;
  border-color: #ea4c89;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-dribbble:hover, .btn-dribbble:focus, .btn-dribbble:active {
  color: #FFF !important;
  background-color: #e7357a;
}
.btn-dribbble.btn-stroke {
  border: 1px double #ea4c89;
  background-color: transparent;
  color: #ea4c89;
}
.btn-dribbble.btn-stroke:hover {
  color: #FFF !important;
  background-color: #e7357a;
}
.btn-dribbble.btn-solid {
  border: 1px solid #ea4c89;
}
.btn-dribbble.btn-dashed {
  border: 1px dashed #ea4c89;
}
.btn-dribbble.btn-dotted {
  border: 1px dotted #ea4c89;
}
.btn-dribbble.btn-double {
  border: 4px double #ea4c89;
}
.btn-dribbble.btn-inset {
  border: 4px inset #ea4c89;
}
.btn-dribbble.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-facebook {
  color: #FFF;
  background-color: #3b5998;
  border-color: #3b5998;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active {
  color: #FFF !important;
  background-color: #344e86;
}
.btn-facebook.btn-stroke {
  border: 1px double #3b5998;
  background-color: transparent;
  color: #3b5998;
}
.btn-facebook.btn-stroke:hover {
  color: #FFF !important;
  background-color: #344e86;
}
.btn-facebook.btn-solid {
  border: 1px solid #3b5998;
}
.btn-facebook.btn-dashed {
  border: 1px dashed #3b5998;
}
.btn-facebook.btn-dotted {
  border: 1px dotted #3b5998;
}
.btn-facebook.btn-double {
  border: 4px double #3b5998;
}
.btn-facebook.btn-inset {
  border: 4px inset #3b5998;
}
.btn-facebook.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-flickr {
  color: #FFF;
  background-color: #ff0084;
  border-color: #ff0084;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-flickr:hover, .btn-flickr:focus, .btn-flickr:active {
  color: #FFF !important;
  background-color: #e60077;
}
.btn-flickr.btn-stroke {
  border: 1px double #ff0084;
  background-color: transparent;
  color: #ff0084;
}
.btn-flickr.btn-stroke:hover {
  color: #FFF !important;
  background-color: #e60077;
}
.btn-flickr.btn-solid {
  border: 1px solid #ff0084;
}
.btn-flickr.btn-dashed {
  border: 1px dashed #ff0084;
}
.btn-flickr.btn-dotted {
  border: 1px dotted #ff0084;
}
.btn-flickr.btn-double {
  border: 4px double #ff0084;
}
.btn-flickr.btn-inset {
  border: 4px inset #ff0084;
}
.btn-flickr.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-foursquare {
  color: #FFF;
  background-color: #25a0ca;
  border-color: #25a0ca;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-foursquare:hover, .btn-foursquare:focus, .btn-foursquare:active {
  color: #FFF !important;
  background-color: #218fb4;
}
.btn-foursquare.btn-stroke {
  border: 1px double #25a0ca;
  background-color: transparent;
  color: #25a0ca;
}
.btn-foursquare.btn-stroke:hover {
  color: #FFF !important;
  background-color: #218fb4;
}
.btn-foursquare.btn-solid {
  border: 1px solid #25a0ca;
}
.btn-foursquare.btn-dashed {
  border: 1px dashed #25a0ca;
}
.btn-foursquare.btn-dotted {
  border: 1px dotted #25a0ca;
}
.btn-foursquare.btn-double {
  border: 4px double #25a0ca;
}
.btn-foursquare.btn-inset {
  border: 4px inset #25a0ca;
}
.btn-foursquare.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-googleplus {
  color: #FFF;
  background-color: #db4a39;
  border-color: #db4a39;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-googleplus:hover, .btn-googleplus:focus, .btn-googleplus:active {
  color: #FFF !important;
  background-color: #d43927;
}
.btn-googleplus.btn-stroke {
  border: 1px double #db4a39;
  background-color: transparent;
  color: #db4a39;
}
.btn-googleplus.btn-stroke:hover {
  color: #FFF !important;
  background-color: #d43927;
}
.btn-googleplus.btn-solid {
  border: 1px solid #db4a39;
}
.btn-googleplus.btn-dashed {
  border: 1px dashed #db4a39;
}
.btn-googleplus.btn-dotted {
  border: 1px dotted #db4a39;
}
.btn-googleplus.btn-double {
  border: 4px double #db4a39;
}
.btn-googleplus.btn-inset {
  border: 4px inset #db4a39;
}
.btn-googleplus.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-instagram {
  color: #FFF;
  background-color: #3f729b;
  border-color: #3f729b;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-instagram:hover, .btn-instagram:focus, .btn-instagram:active {
  color: #FFF !important;
  background-color: #386589;
}
.btn-instagram.btn-stroke {
  border: 1px double #3f729b;
  background-color: transparent;
  color: #3f729b;
}
.btn-instagram.btn-stroke:hover {
  color: #FFF !important;
  background-color: #386589;
}
.btn-instagram.btn-solid {
  border: 1px solid #3f729b;
}
.btn-instagram.btn-dashed {
  border: 1px dashed #3f729b;
}
.btn-instagram.btn-dotted {
  border: 1px dotted #3f729b;
}
.btn-instagram.btn-double {
  border: 4px double #3f729b;
}
.btn-instagram.btn-inset {
  border: 4px inset #3f729b;
}
.btn-instagram.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-linkedin {
  color: #FFF;
  background-color: #0e76a8;
  border-color: #0e76a8;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-linkedin:hover, .btn-linkedin:focus, .btn-linkedin:active {
  color: #FFF !important;
  background-color: #0c6590;
}
.btn-linkedin.btn-stroke {
  border: 1px double #0e76a8;
  background-color: transparent;
  color: #0e76a8;
}
.btn-linkedin.btn-stroke:hover {
  color: #FFF !important;
  background-color: #0c6590;
}
.btn-linkedin.btn-solid {
  border: 1px solid #0e76a8;
}
.btn-linkedin.btn-dashed {
  border: 1px dashed #0e76a8;
}
.btn-linkedin.btn-dotted {
  border: 1px dotted #0e76a8;
}
.btn-linkedin.btn-double {
  border: 4px double #0e76a8;
}
.btn-linkedin.btn-inset {
  border: 4px inset #0e76a8;
}
.btn-linkedin.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-path {
  color: #FFF;
  background-color: #e41f11;
  border-color: #e41f11;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-path:hover, .btn-path:focus, .btn-path:active {
  color: #FFF !important;
  background-color: #cc1c0f;
}
.btn-path.btn-stroke {
  border: 1px double #e41f11;
  background-color: transparent;
  color: #e41f11;
}
.btn-path.btn-stroke:hover {
  color: #FFF !important;
  background-color: #cc1c0f;
}
.btn-path.btn-solid {
  border: 1px solid #e41f11;
}
.btn-path.btn-dashed {
  border: 1px dashed #e41f11;
}
.btn-path.btn-dotted {
  border: 1px dotted #e41f11;
}
.btn-path.btn-double {
  border: 4px double #e41f11;
}
.btn-path.btn-inset {
  border: 4px inset #e41f11;
}
.btn-path.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-pinterest {
  color: #FFF;
  background-color: #c8232c;
  border-color: #c8232c;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-pinterest:hover, .btn-pinterest:focus, .btn-pinterest:active {
  color: #FFF !important;
  background-color: #b21f27;
}
.btn-pinterest.btn-stroke {
  border: 1px double #c8232c;
  background-color: transparent;
  color: #c8232c;
}
.btn-pinterest.btn-stroke:hover {
  color: #FFF !important;
  background-color: #b21f27;
}
.btn-pinterest.btn-solid {
  border: 1px solid #c8232c;
}
.btn-pinterest.btn-dashed {
  border: 1px dashed #c8232c;
}
.btn-pinterest.btn-dotted {
  border: 1px dotted #c8232c;
}
.btn-pinterest.btn-double {
  border: 4px double #c8232c;
}
.btn-pinterest.btn-inset {
  border: 4px inset #c8232c;
}
.btn-pinterest.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-rss {
  color: #FFF;
  background-color: #ee802f;
  border-color: #ee802f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-rss:hover, .btn-rss:focus, .btn-rss:active {
  color: #FFF !important;
  background-color: #ec7217;
}
.btn-rss.btn-stroke {
  border: 1px double #ee802f;
  background-color: transparent;
  color: #ee802f;
}
.btn-rss.btn-stroke:hover {
  color: #FFF !important;
  background-color: #ec7217;
}
.btn-rss.btn-solid {
  border: 1px solid #ee802f;
}
.btn-rss.btn-dashed {
  border: 1px dashed #ee802f;
}
.btn-rss.btn-dotted {
  border: 1px dotted #ee802f;
}
.btn-rss.btn-double {
  border: 4px double #ee802f;
}
.btn-rss.btn-inset {
  border: 4px inset #ee802f;
}
.btn-rss.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-skype {
  color: #FFF;
  background-color: #00aff0;
  border-color: #00aff0;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-skype:hover, .btn-skype:focus, .btn-skype:active {
  color: #FFF !important;
  background-color: #009cd7;
}
.btn-skype.btn-stroke {
  border: 1px double #00aff0;
  background-color: transparent;
  color: #00aff0;
}
.btn-skype.btn-stroke:hover {
  color: #FFF !important;
  background-color: #009cd7;
}
.btn-skype.btn-solid {
  border: 1px solid #00aff0;
}
.btn-skype.btn-dashed {
  border: 1px dashed #00aff0;
}
.btn-skype.btn-dotted {
  border: 1px dotted #00aff0;
}
.btn-skype.btn-double {
  border: 4px double #00aff0;
}
.btn-skype.btn-inset {
  border: 4px inset #00aff0;
}
.btn-skype.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-soundcloud {
  color: #FFF;
  background-color: #ff7700;
  border-color: #ff7700;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-soundcloud:hover, .btn-soundcloud:focus, .btn-soundcloud:active {
  color: #FFF !important;
  background-color: #e66b00;
}
.btn-soundcloud.btn-stroke {
  border: 1px double #ff7700;
  background-color: transparent;
  color: #ff7700;
}
.btn-soundcloud.btn-stroke:hover {
  color: #FFF !important;
  background-color: #e66b00;
}
.btn-soundcloud.btn-solid {
  border: 1px solid #ff7700;
}
.btn-soundcloud.btn-dashed {
  border: 1px dashed #ff7700;
}
.btn-soundcloud.btn-dotted {
  border: 1px dotted #ff7700;
}
.btn-soundcloud.btn-double {
  border: 4px double #ff7700;
}
.btn-soundcloud.btn-inset {
  border: 4px inset #ff7700;
}
.btn-soundcloud.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-tumblr {
  color: #FFF;
  background-color: #34526f;
  border-color: #34526f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-tumblr:hover, .btn-tumblr:focus, .btn-tumblr:active {
  color: #FFF !important;
  background-color: #2c455e;
}
.btn-tumblr.btn-stroke {
  border: 1px double #34526f;
  background-color: transparent;
  color: #34526f;
}
.btn-tumblr.btn-stroke:hover {
  color: #FFF !important;
  background-color: #2c455e;
}
.btn-tumblr.btn-solid {
  border: 1px solid #34526f;
}
.btn-tumblr.btn-dashed {
  border: 1px dashed #34526f;
}
.btn-tumblr.btn-dotted {
  border: 1px dotted #34526f;
}
.btn-tumblr.btn-double {
  border: 4px double #34526f;
}
.btn-tumblr.btn-inset {
  border: 4px inset #34526f;
}
.btn-tumblr.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-twitter {
  color: #FFF;
  background-color: #00a0d1;
  border-color: #00a0d1;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-twitter:hover, .btn-twitter:focus, .btn-twitter:active {
  color: #FFF !important;
  background-color: #008cb8;
}
.btn-twitter.btn-stroke {
  border: 1px double #00a0d1;
  background-color: transparent;
  color: #00a0d1;
}
.btn-twitter.btn-stroke:hover {
  color: #FFF !important;
  background-color: #008cb8;
}
.btn-twitter.btn-solid {
  border: 1px solid #00a0d1;
}
.btn-twitter.btn-dashed {
  border: 1px dashed #00a0d1;
}
.btn-twitter.btn-dotted {
  border: 1px dotted #00a0d1;
}
.btn-twitter.btn-double {
  border: 4px double #00a0d1;
}
.btn-twitter.btn-inset {
  border: 4px inset #00a0d1;
}
.btn-twitter.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-vimeo {
  color: #FFF;
  background-color: #86c9ef;
  border-color: #86c9ef;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-vimeo:hover, .btn-vimeo:focus, .btn-vimeo:active {
  color: #FFF !important;
  background-color: #6fbfec;
}
.btn-vimeo.btn-stroke {
  border: 1px double #86c9ef;
  background-color: transparent;
  color: #86c9ef;
}
.btn-vimeo.btn-stroke:hover {
  color: #FFF !important;
  background-color: #6fbfec;
}
.btn-vimeo.btn-solid {
  border: 1px solid #86c9ef;
}
.btn-vimeo.btn-dashed {
  border: 1px dashed #86c9ef;
}
.btn-vimeo.btn-dotted {
  border: 1px dotted #86c9ef;
}
.btn-vimeo.btn-double {
  border: 4px double #86c9ef;
}
.btn-vimeo.btn-inset {
  border: 4px inset #86c9ef;
}
.btn-vimeo.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-wordpress {
  color: #FFF;
  background-color: #21759b;
  border-color: #21759b;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-wordpress:hover, .btn-wordpress:focus, .btn-wordpress:active {
  color: #FFF !important;
  background-color: #1d6586;
}
.btn-wordpress.btn-stroke {
  border: 1px double #21759b;
  background-color: transparent;
  color: #21759b;
}
.btn-wordpress.btn-stroke:hover {
  color: #FFF !important;
  background-color: #1d6586;
}
.btn-wordpress.btn-solid {
  border: 1px solid #21759b;
}
.btn-wordpress.btn-dashed {
  border: 1px dashed #21759b;
}
.btn-wordpress.btn-dotted {
  border: 1px dotted #21759b;
}
.btn-wordpress.btn-double {
  border: 4px double #21759b;
}
.btn-wordpress.btn-inset {
  border: 4px inset #21759b;
}
.btn-wordpress.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-yahoo {
  color: #FFF;
  background-color: #720e9e;
  border-color: #720e9e;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-yahoo:hover, .btn-yahoo:focus, .btn-yahoo:active {
  color: #FFF !important;
  background-color: #610c87;
}
.btn-yahoo.btn-stroke {
  border: 1px double #720e9e;
  background-color: transparent;
  color: #720e9e;
}
.btn-yahoo.btn-stroke:hover {
  color: #FFF !important;
  background-color: #610c87;
}
.btn-yahoo.btn-solid {
  border: 1px solid #720e9e;
}
.btn-yahoo.btn-dashed {
  border: 1px dashed #720e9e;
}
.btn-yahoo.btn-dotted {
  border: 1px dotted #720e9e;
}
.btn-yahoo.btn-double {
  border: 4px double #720e9e;
}
.btn-yahoo.btn-inset {
  border: 4px inset #720e9e;
}
.btn-yahoo.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-youtube {
  color: #FFF;
  background-color: #c4302b;
  border-color: #c4302b;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.btn-youtube:hover, .btn-youtube:focus, .btn-youtube:active {
  color: #FFF !important;
  background-color: #af2b26;
}
.btn-youtube.btn-stroke {
  border: 1px double #c4302b;
  background-color: transparent;
  color: #c4302b;
}
.btn-youtube.btn-stroke:hover {
  color: #FFF !important;
  background-color: #af2b26;
}
.btn-youtube.btn-solid {
  border: 1px solid #c4302b;
}
.btn-youtube.btn-dashed {
  border: 1px dashed #c4302b;
}
.btn-youtube.btn-dotted {
  border: 1px dotted #c4302b;
}
.btn-youtube.btn-double {
  border: 4px double #c4302b;
}
.btn-youtube.btn-inset {
  border: 4px inset #c4302b;
}
.btn-youtube.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50%;
  border-radius: 50% 50% 50% 50%;
}

.btn-icon-stacked {
  text-align: left;
  padding: 10px 10px 10px 45px;
  position: relative;
}
.btn-icon-stacked:focus, .btn-icon-stacked:active {
  color: white;
}
.btn-icon-stacked i {
  display: block;
  position: absolute;
  left: 15px;
  top: 15px;
}
.btn-icon-stacked span {
  display: block;
  font-size: 13px;
  line-height: normal;
}

.btn-compose-email {
  padding: 10px 0px;
  margin-bottom: 20px;
}

.btn-group-vertical > .btn.rounded:first-child:not(:last-child) {
  -moz-border-radius: 3px 3px 0px 0px;
  -webkit-border-radius: 3px;
  border-radius: 3px 3px 0px 0px;
}
.btn-group-vertical > .btn.rounded:last-child:not(:first-child) {
  -moz-border-radius: 0px 0px 3px 3px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 3px 3px;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* ========================================================================
 * DROPDOWN
 * ======================================================================== */
.dropdown-menu {
  padding: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  font-size: 12px;
  border: 1px solid #DDD;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dropdown-menu .dropdown-header {
  background-color: #FBFBFB;
  border-top: none;
  border-bottom: 1px solid #DDD;
}
.dropdown-menu .divider {
  margin: 0px;
}

/* ========================================================================
 * FORM
 * ======================================================================== */
/* ========================================================================
 * TABLE OF CONTENTS
 * ========================================================================
   01. FORM
   02. FORM HORIZONTAL
   03. FORM BODY
   04. FORM BORDERED
   05. FORM STRIPED
   06. FORM BODY
   07. FORM FOOTER
   08. CONTEXTUAL CLASSES CHECKBOX
   09. CHECKBOX
   10. RADIO
   11. VALIDATION STATES
   12. STATIC CONTROL
   13. INPUT WITH ICONS
   14. INPUT WITH SPINNERS
   15. FORM MISC
   16. IE SUPPORT
 * ======================================================================== */
form .form-group {
  position: relative;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
form .form-group .fileinput {
  width: 100%;
}
form .form-group .error {
  display: none;
  font-size: 12px;
  font-style: italic;
  color: #E9573F;
}
form .form-group .error + .help-block {
  display: none;
}
form .form-group.no-padding .form-control-feedback {
  right: 4px !important;
}
form .form-group .control-label {
  padding-top: 3px;
  font-weight: 300;
}
form .form-group.has-feedback.has-feedback-sm button.form-control-feedback {
  width: 22px;
  height: 22px;
  font-size: 12px;
  top: 4px;
  right: 4px;
}
form .form-group.has-feedback button.form-control-feedback {
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  line-height: 6px;
  position: absolute;
  pointer-events: auto !important;
}
form .form-group.has-feedback button.form-control-feedback:before {
  position: absolute;
  left: 6px;
  top: 8px;
}
form .form-group.has-primary .control-label {
  color: #00B1E1;
}
form .form-group.has-primary .form-control {
  border-color: #00B1E1;
}
form .form-group.has-primary .form-control:focus {
  border-color: #0089ae !important;
}
form .form-group.has-primary .form-control-feedback {
  color: #00B1E1;
}
form .form-group.has-primary .help-inline {
  color: #00B1E1;
}
form .form-group.has-info .control-label {
  color: #63D3E9;
}
form .form-group.has-info .form-control {
  border-color: #63D3E9;
}
form .form-group.has-info .form-control:focus {
  border-color: #36c6e3 !important;
}
form .form-group.has-info .form-control-feedback {
  color: #63D3E9;
}
form .form-group.has-info .help-inline {
  color: #63D3E9;
}
form .form-group.has-success .control-label {
  color: #8CC152;
}
form .form-group.has-success .form-control {
  border-color: #8CC152;
}
form .form-group.has-success .form-control:focus {
  border-color: #72a53b !important;
}
form .form-group.has-success .form-control-feedback {
  color: #8CC152;
}
form .form-group.has-success .help-inline {
  color: #8CC152;
}
form .form-group.has-warning .control-label {
  color: #F6BB42;
}
form .form-group.has-warning .form-control {
  border-color: #F6BB42;
}
form .form-group.has-warning .form-control:focus {
  border-color: #f4a911 !important;
}
form .form-group.has-warning .form-control-feedback {
  color: #F6BB42;
}
form .form-group.has-warning .help-inline {
  color: #F6BB42;
}
form .form-group.has-error .control-label {
  color: #E9573F;
}
form .form-group.has-error .form-control {
  border-color: #E9573F;
}
form .form-group.has-error .form-control:focus {
  border-color: #dc3519 !important;
}
form .form-group.has-error .form-control-feedback {
  color: #E9573F;
}
form .form-group.has-error .help-inline {
  color: #E9573F;
}
form .form-group.form-group-inline input, form .form-group.form-group-inline .help-inline {
  display: inline-block;
}
form .form-group.form-group-inline input {
  width: 75%;
  margin-right: 5px;
}
form .form-group.form-group-divider {
  background-color: #EEEEEE;
  padding: 0px;
  margin-bottom: 0px;
}
form .form-group.form-group-divider .form-inner {
  border-left: 5px solid #71a117;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
form .form-group.form-group-divider .form-inner .label-circle {
  display: inline !important;
}
form .form-group.form-footer {
  background-color: #EEEEEE;
  margin-bottom: 0px;
}
form .help-block, form .help-inline {
  font-size: 12px;
  font-style: italic;
  margin-bottom: 0px;
}

/* ========================================================================
 * FORM HORIZONTAL
 * ======================================================================== */
.form-horizontal .form-group .checkbox {
  padding-top: 2px;
}
.form-horizontal .form-group .checkbox-inline {
  padding-top: 1px;
}
.form-horizontal .form-group .radio {
  padding-top: 4px;
}
.form-horizontal .form-group .radio-inline {
  padding-top: 0px;
}

/* ========================================================================
 * FORM BORDERED
 * ======================================================================== */
.form-bordered .form-body {
  padding: 0px;
}
.form-bordered .form-group {
  padding: 15px 0px;
  margin-bottom: 0px;
  border-top: 1px solid #DDD;
}
.form-bordered .form-group:first-child {
  border-top: none;
}
.form-bordered .form-group .chosen-container:last-child {
  margin-bottom: 0px !important;
}

/* ========================================================================
 * FORM STRIPED
 * ======================================================================== */
.form-striped .form-body {
  padding: 0px;
}
.form-striped .form-group {
  padding: 15px 0px;
  margin-bottom: 0px;
  border-top: 1px solid #DDD;
}
.form-striped .form-group:first-child {
  border-top: none;
}
.form-striped .form-group:nth-child(2n+1) {
  background-color: #f1f1f1;
}
.form-striped .form-group .chosen-container:last-child {
  margin-bottom: 0px !important;
}

/* ========================================================================
 * FORM BODY
 * ======================================================================== */
.form-body {
  padding: 10px;
}

/* ========================================================================
 * FORM FOOTER
 * ======================================================================== */
.form-footer {
  padding: 10px;
  background-color: #F5F5F5;
  border-top: 1px solid #DDD;
}
.form-footer [class*="col-"] .btn {
  margin-left: 5px;
}

/* ========================================================================
 * CHECKBOX
 * ======================================================================== */
.ckbox input[type=checkbox] {
  opacity: 0;
}
.ckbox input[type=checkbox]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\F00C";
  position: absolute;
  top: 0;
  left: 0px;
  display: inline-block;
  font-size: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #FFF;
  text-align: center;
  font-weight: 700;
}
.ckbox.rounded input[type=checkbox]:checked + label::after, .ckbox.rounded input[type=checkbox]:checked + label::before {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.ckbox.rounded label:before {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.ckbox.circle input[type=checkbox]:checked + label::after, .ckbox.circle input[type=checkbox]:checked + label::before {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.ckbox.circle label:before {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.ckbox.ckbox-default input[type=checkbox]:checked + label::after {
  color: gray;
}

/* ========================================================================
 * RADIO
 * ======================================================================== */
.rdio input[type=radio] {
  opacity: 0;
}
.rdio input[type=radio]:checked + label::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  display: inline-block;
  font-size: 11px;
  width: 10px;
  height: 10px;
}
.rdio.rounded input[type=radio]:checked + label::after, .rdio.rounded input[type=radio]:checked + label::before {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.rdio.rounded label:before {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.rdio.circle input[type=radio]:checked + label::after, .rdio.circle input[type=radio]:checked + label::before {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.rdio.circle label:before {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.rdio.rdio-default input[type=radio]:checked + label::after {
  color: gray;
}

.text-center .ckbox, .text-center .rdio {
  width: 20px;
  margin-left: auto;
  margin-right: auto;
  line-height: 0px;
}

.ckbox, .rdio {
  position: relative;
  margin-top: 3px;
}
.ckbox label, .rdio label {
  padding-left: 10px;
  cursor: pointer;
  margin-bottom: 7px !important;
}
.ckbox label:before, .rdio label:before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  content: '';
  display: inline-block;
  border: 1px solid #BBB;
  background: #FFF;
}

.ckbox-teal input[type=checkbox]:checked + label::after {
  border-color: #37BC9B;
  background-color: #37BC9B;
}

/* ========================================================================
 * CONTEXTUAL CLASSES CHECKBOX
 * ======================================================================== */
.ckbox-default input[type=checkbox]:checked + label::after {
  border-color: #EEEEEE;
  background-color: #EEEEEE;
}
.ckbox-default input[type=checkbox][disabled]:checked + label::after {
  border-color: #EEEEEE;
  opacity: .5;
}

.ckbox-primary input[type=checkbox]:checked + label::after {
  border-color: #00B1E1;
  background-color: #00B1E1;
}
.ckbox-primary input[type=checkbox][disabled]:checked + label::after {
  border-color: #00B1E1;
  opacity: .5;
}

.ckbox-success input[type=checkbox]:checked + label::after {
  border-color: #8CC152;
  background-color: #8CC152;
}
.ckbox-success input[type=checkbox][disabled]:checked + label::after {
  border-color: #8CC152;
  opacity: .5;
}

.ckbox-info input[type=checkbox]:checked + label::after {
  border-color: #63D3E9;
  background-color: #63D3E9;
}
.ckbox-info input[type=checkbox][disabled]:checked + label::after {
  border-color: #63D3E9;
  opacity: .5;
}

.ckbox-warning input[type=checkbox]:checked + label::after {
  border-color: #F6BB42;
  background-color: #F6BB42;
}
.ckbox-warning input[type=checkbox][disabled]:checked + label::after {
  border-color: #F6BB42;
  opacity: .5;
}

.ckbox-danger input[type=checkbox]:checked + label::after {
  border-color: #E9573F;
  background-color: #E9573F;
}
.ckbox-danger input[type=checkbox][disabled]:checked + label::after {
  border-color: #E9573F;
  opacity: .5;
}

.ckbox-lilac input[type=checkbox]:checked + label::after {
  border-color: #906094;
  background-color: #906094;
}
.ckbox-lilac input[type=checkbox][disabled]:checked + label::after {
  border-color: #906094;
  opacity: .5;
}

.ckbox-inverse input[type=checkbox]:checked + label::after {
  border-color: #2A2A2A;
  background-color: #2A2A2A;
}
.ckbox-inverse input[type=checkbox][disabled]:checked + label::after {
  border-color: #2A2A2A;
  opacity: .5;
}

.rdio-default input[type=radio]:checked + label::after {
  border-color: #EEEEEE;
  background-color: #EEEEEE;
}
.rdio-default input[type=radio][disabled]:checked + label::after {
  border-color: #EEEEEE;
  opacity: .5;
}

.rdio-primary input[type=radio]:checked + label::after {
  border-color: #00B1E1;
  background-color: #00B1E1;
}
.rdio-primary input[type=radio][disabled]:checked + label::after {
  border-color: #00B1E1;
  opacity: .5;
}

.rdio-success input[type=radio]:checked + label::after {
  border-color: #8CC152;
  background-color: #8CC152;
}
.rdio-success input[type=radio][disabled]:checked + label::after {
  border-color: #8CC152;
  opacity: .5;
}

.rdio-info input[type=radio]:checked + label::after {
  border-color: #63D3E9;
  background-color: #63D3E9;
}
.rdio-info input[type=radio][disabled]:checked + label::after {
  border-color: #63D3E9;
  opacity: .5;
}

.rdio-warning input[type=radio]:checked + label::after {
  border-color: #F6BB42;
  background-color: #F6BB42;
}
.rdio-warning input[type=radio][disabled]:checked + label::after {
  border-color: #F6BB42;
  opacity: .5;
}

.rdio-danger input[type=radio]:checked + label::after {
  border-color: #E9573F;
  background-color: #E9573F;
}
.rdio-danger input[type=radio][disabled]:checked + label::after {
  border-color: #E9573F;
  opacity: .5;
}

.rdio-lilac input[type=radio]:checked + label::after {
  border-color: #906094;
  background-color: #906094;
}
.rdio-lilac input[type=radio][disabled]:checked + label::after {
  border-color: #906094;
  opacity: .5;
}

.rdio-inverse input[type=radio]:checked + label::after {
  border-color: #2A2A2A;
  background-color: #2A2A2A;
}
.rdio-inverse input[type=radio][disabled]:checked + label::after {
  border-color: #2A2A2A;
  opacity: .5;
}

.rdio-teal input[type=radio]:checked + label::after {
  border-color: #37BC9B;
  background-color: #37BC9B;
}

/* ========================================================================
 * VALIDATION STATE
 * ======================================================================== */
.has-feedback label ~ .form-control-feedback {
  top: 28px;
}

/* ========================================================================
 * STATIC CONTROL
 * ======================================================================== */
.form-control-static {
  padding-top: 3px;
  padding-bottom: 3px;
}

/* ========================================================================
 * INPUT WITH ICONS
 * ======================================================================== */
.input-icon > i {
  color: #DDD;
  display: block;
  position: absolute;
  margin: 10px 2px 4px 10px;
  z-index: 3;
  width: 16px;
  height: 16px;
  font-size: 16px;
  text-align: center;
}
.input-icon > .form-control {
  padding-left: 33px;
}
.input-icon.right > i {
  right: 8px;
  float: right;
}
.input-icon.right[class*="col-"] > i {
  right: 16px;
}
.input-icon.right > .form-control {
  padding-right: 33px;
  padding-left: 12px;
}

/* ========================================================================
 * INPUT WITH SPINNERS
 * ======================================================================== */
input.spinner[type="text"],
input.spinner[type="password"],
input.spinner[type="datetime"],
input.spinner[type="datetime-local"],
input.spinner[type="date"],
input.spinner[type="month"],
input.spinner[type="time"],
input.spinner[type="week"],
input.spinner[type="number"],
input.spinner[type="email"],
input.spinner[type="url"],
input.spinner[type="search"],
input.spinner[type="tel"],
input.spinner[type="color"] {
  background-repeat: no-repeat;
  background-position: right 6px bottom 6px;
  background-size: 20px Auto;
}
input.spinner[type="text"].flat,
input.spinner[type="password"].flat,
input.spinner[type="datetime"].flat,
input.spinner[type="datetime-local"].flat,
input.spinner[type="date"].flat,
input.spinner[type="month"].flat,
input.spinner[type="time"].flat,
input.spinner[type="week"].flat,
input.spinner[type="number"].flat,
input.spinner[type="email"].flat,
input.spinner[type="url"].flat,
input.spinner[type="search"].flat,
input.spinner[type="tel"].flat,


/* ========================================================================
 * FORM MISC
 * ======================================================================== */
.input-group-addon {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  min-width: 39px;
}
.input-group-addon .ckbox, .input-group-addon .rdio {
  position: absolute;
  top: 4px;
  left: 10px;
}

.input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn, .input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn, .input-group-xs > .form-control, .input-group-xs > .input-group-addon, .input-group-xs > .input-group-btn > .btn {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.input-sm, .form-group-sm .form-control {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.form-control {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (max-width: 640px) {
  .form-inner-all [class*="col-"]:last-child .form-control {
    margin-top: 15px;
  }
}
/* ========================================================================
 * IE SUPPORT
 * ======================================================================== */
/* ========================================================================
 * IMG BORDERED
 * ======================================================================== */
.img-bordered-default {
  border: 2px solid #EEEEEE;
}

.img-bordered-primary {
  border: 2px solid #00B1E1;
}

.img-bordered-success {
  border: 2px solid #8CC152;
}

.img-bordered-info {
  border: 2px solid #63D3E9;
}

.img-bordered-warning {
  border: 2px solid #F6BB42;
}

.img-bordered-danger {
  border: 2px solid #E9573F;
}

.img-bordered-lilac {
  border: 2px solid #906094;
}

.img-bordered-teals {
  border: 2px solid #37BC9B;
}

.img-bordered-inverse {
  border: 2px solid #2A2A2A;
}

/* ========================================================================
 * LABEL
 * ======================================================================== */
.label {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.label-default {
  color: #999 !important;
}

.label-default {
  background-color: #EEEEEE;
}
.label-default.label-stroke {
  background-color: transparent;
  border: 1px solid #EEEEEE;
  color: #EEEEEE;
}

.label-primary {
  background-color: #00B1E1;
}
.label-primary.label-stroke {
  background-color: transparent;
  border: 1px solid #00B1E1;
  color: #00B1E1;
}

.label-success {
  background-color: #8CC152;
}
.label-success.label-stroke {
  background-color: transparent;
  border: 1px solid #8CC152;
  color: #8CC152;
}

.label-info {
  background-color: #63D3E9;
}
.label-info.label-stroke {
  background-color: transparent;
  border: 1px solid #63D3E9;
  color: #63D3E9;
}

.label-warning {
  background-color: #F6BB42;
}
.label-warning.label-stroke {
  background-color: transparent;
  border: 1px solid #F6BB42;
  color: #F6BB42;
}

.label-danger {
  background-color: #E9573F;
}
.label-danger.label-stroke {
  background-color: transparent;
  border: 1px solid #E9573F;
  color: #E9573F;
}

.label-lilac {
  background-color: #906094;
}
.label-lilac.label-stroke {
  background-color: transparent;
  border: 1px solid #906094;
  color: #906094;
}

.label-teals {
  background-color: #37BC9B;
}
.label-teals.label-stroke {
  background-color: transparent;
  border: 1px solid #37BC9B;
  color: #37BC9B;
}

.label-inverse {
  background-color: #2A2A2A;
}
.label-inverse.label-stroke {
  background-color: transparent;
  border: 1px solid #2A2A2A;
  color: #2A2A2A;
}

.label-circle {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  line-height: 10px;
  height: 15px;
  width: 15px;
  display: block;
}

/* ========================================================================
 * LIST GROUP
 * ======================================================================== */
.media-list > .media .media-body .media-meta.device {
  position: absolute;
  top: 5px;
  right: 15px;
}
.media-list > .media .media-body .media-meta.time {
  float: right;
  margin-top: 3px;
}

/* ========================================================================
 * MEDIA IMAGE
 * ======================================================================== */
.media {
  position: relative;
}
.media > .pull-left.has-notif i {
  border: 2px solid #2A2A2A;
}
.media > .pull-left.has-notif i.online {
  background-color: #8CC152;
  animation: opacity-badge 0.6s infinite;
  -webkit-animation: opacity-badge 0.6s infinite;
}
@keyframes opacity-badge {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1.2);
  }
}
@-webkit-keyframes opacity-badge {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1.2);
  }
}
.media > .pull-left.has-notif i.offline {
  background-color: #959595;
}
.media > .pull-left.has-notif i.away {
  background-color: #F6BB42;
}
.media > .pull-left.has-notif i.busy {
  background-color: #E9573F;
}
.media > .pull-left.has-notif i {
  position: absolute;
  left: 14px;
  top: 3px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}
.media > .pull-left.avatar i {
  left: 4px;
  top: 1px;
}

.media-list {
  background-color: white;
}
.media-list > a {
  text-decoration: none;
  border-bottom: 1px solid #DDD;
}
.media-list > a:hover {
  background-color: #EEEEEE;
}
.media-list > a:last-child {
  border-bottom: none;
}
.media-list > .media {
  display: block;
  padding: 8px 15px;
  margin: 0;
  line-height: 21px;
}
.media-list > .media > .pull-left {
  display: table-cell;
  margin-top: 4px;
}
.media-list > .media .media-object {
  width: 50px;
  height: 50px;
  text-align: center;
}
.media-list > .media .media-object img {
  width: 30px;
  height: 30px;
}
.media-list > .media > .media-body {
  display: table-cell;
}
.media-list > .media > .media-body > .media-heading {
  text-transform: capitalize;
  display: block;
  font-weight: 600;
  color: #444;
  margin-bottom: 0;
}
.media-list > .media > .media-body > .media-text {
  display: block;
  color: #777;
  margin: 0;
  font-size: 13px;
  min-width: 210px;
}
.media-list > .media > .media-body > .media-meta {
  font-size: 11px !important;
  margin: 0;
  color: #8f8f8f;
}
.media-list.small > .media {
  padding: 7px 15px;
}
.media-list.small > .media .media-object {
  width: 38px;
  height: 38px;
}

.media-details {
  padding: 20px;
}
.media-details .category {
  font-size: 11px;
}
.media-details .details {
  margin-top: 20px;
}
.media-details .details h4 {
  font-size: 12px;
  color: #333;
  text-transform: uppercase;
}
.media-details .table {
  box-shadow: none;
}
.media-details .media-title {
  font-size: 17px;
}

/* ========================================================================
 * MODALS
 * ======================================================================== */
.modal .modal-dialog .modal-content {
  border: 1px solid #DDD;
}
.modal .modal-dialog .modal-content .panel-group {
  margin-bottom: 0px;
}
.modal .modal-dialog .modal-content .panel {
  box-shadow: none;
  margin-bottom: 0px;
}
.modal .modal-dialog .modal-content .modal-footer {
  padding: 10px;
  background-color: #F5F5F5;
  margin-top: 0px;
  -moz-border-radius: 0px 0px 3px 3px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 3px 3px;
}
.modal.modal-center {
  padding-top: 10%;
}

.modal-primary .modal-header {
  background-color: #00B1E1;
  color: white;
  border-bottom: 1px solid #00B1E1;
  position: relative;
}
.modal-primary .modal-header:before {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  content: "";
  border-top: 1px solid #00B1E1;
  border-left: 1px solid #00B1E1;
  border-right: 1px solid #00B1E1;
}
.modal-primary .modal-content {
  border: 1px solid #00B1E1;
}

.modal-success .modal-header {
  background-color: #8CC152;
  color: white;
  border-bottom: 1px solid #8CC152;
  position: relative;
}
.modal-success .modal-header:before {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  content: "";
  border-top: 1px solid #8CC152;
  border-left: 1px solid #8CC152;
  border-right: 1px solid #8CC152;
}
.modal-success .modal-content {
  border: 1px solid #8CC152;
}

.modal-info .modal-header {
  background-color: #63D3E9;
  color: white;
  border-bottom: 1px solid #63D3E9;
  position: relative;
}
.modal-info .modal-header:before {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  content: "";
  border-top: 1px solid #63D3E9;
  border-left: 1px solid #63D3E9;
  border-right: 1px solid #63D3E9;
}
.modal-info .modal-content {
  border: 1px solid #63D3E9;
}

.modal-warning .modal-header {
  background-color: #F6BB42;
  color: white;
  border-bottom: 1px solid #F6BB42;
  position: relative;
}
.modal-warning .modal-header:before {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  content: "";
  border-top: 1px solid #F6BB42;
  border-left: 1px solid #F6BB42;
  border-right: 1px solid #F6BB42;
}
.modal-warning .modal-content {
  border: 1px solid #F6BB42;
}

.modal-danger .modal-header {
  background-color: #E9573F;
  color: white;
  border-bottom: 1px solid #E9573F;
  position: relative;
}
.modal-danger .modal-header:before {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  content: "";
  border-top: 1px solid #E9573F;
  border-left: 1px solid #E9573F;
  border-right: 1px solid #E9573F;
}
.modal-danger .modal-content {
  border: 1px solid #E9573F;
}

.modal-lilac .modal-header {
  background-color: #906094;
  color: white;
  border-bottom: 1px solid #906094;
  position: relative;
}
.modal-lilac .modal-header:before {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  content: "";
  border-top: 1px solid #906094;
  border-left: 1px solid #906094;
  border-right: 1px solid #906094;
}
.modal-lilac .modal-content {
  border: 1px solid #906094;
}

.modal-teal .modal-header {
  background-color: #37BC9B;
  color: white;
  border-bottom: 1px solid #37BC9B;
  position: relative;
}
.modal-teal .modal-header:before {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  content: "";
  border-top: 1px solid #37BC9B;
  border-left: 1px solid #37BC9B;
  border-right: 1px solid #37BC9B;
}
.modal-teal .modal-content {
  border: 1px solid #37BC9B;
}

.modal-photo-viewer {
  margin-top: 0px;
  margin-bottom: 0px;
  top: 50%;
  left: 50%;
  width: auto;
  position: absolute;
}
.modal-photo-viewer .modal-content {
  position: relative;
  width: 900px;
  height: auto;
  margin-left: -50%;
  margin-top: -200px;
}
.modal-photo-viewer .row {
  margin-left: 0px;
  margin-right: 0px;
}
.modal-photo-viewer .modal-photo-left, .modal-photo-viewer .modal-photo-right {
  padding: 0px;
}
.modal-photo-viewer .modal-photo-right .media-details {
  padding: 10px;
}
.modal-photo-viewer .modal-photo-right .media-details .media-title {
  margin-top: 13px;
}
.modal-photo-viewer .modal-photo {
  top: 50%;
  position: relative;
}
.modal-photo-viewer .photo {
  max-height: 539px;
  margin: auto;
}

@media (max-width: 800px) {
  .modal-photo-viewer .modal-content {
    width: 80%;
    margin-left: -42%;
  }
  .modal-photo-viewer .modal-photo-left {
    -moz-border-radius: 3px 3px 0px 0px;
    -webkit-border-radius: 3px;
    border-radius: 3px 3px 0px 0px;
  }
}
@media (max-width: 480px) {
  .modal-photo-viewer .modal-content {
    width: 50%;
    margin-left: -27%;
  }
}
/* ========================================================================
 * PAGINATION
 * ======================================================================== */
.pagination {
  vertical-align: middle;
}

/* ========================================================================
 * PANEL
 * ======================================================================== */
/* ========================================================================
 * TABLE OF CONTENT
 * ========================================================================
   01. PANEL
   02. PANEL HEADING
   03. PANEL SUB HEADING
   04. PANEL BODY
   05. PANEL FOOTER
   06. CONTEXTUAL CLASSES PANEL
   07. PANEL SCROLLABLE
   08. PANEL TAB
   09. PANEL TAB DOUBLE
   10. PANEL TAB VERTICAL
   11. PANEL GROUP
 * ======================================================================== */
.panel {
  border: none;
  position: relative;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel.panel-fullsize {
  position: fixed;
  width: 96%;
  top: 25px;
  left: 25px;
  z-index: 9999;
}
.panel.panel-fullsize .chartjs {
  max-width: inherit !important;
  height: 470px !important;
}
.panel .panel-heading {
  padding: 5px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom: 1px solid #DDD;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.panel .panel-heading .has-feedback .form-control {
  padding-right: 0;
}
.panel .panel-heading > .pull-right {
  margin-top: 5px;
  margin-right: 5px;
}
.panel .panel-heading > .pull-right > i {
  padding: 5px;
}
.panel .panel-heading > .pull-right > .progress {
  min-width: 120px;
}
.panel .panel-heading .panel-title {
  padding: 10px;
  font-size: 17px;
}
.panel .panel-heading .panel-title > i {
  margin-right: 5px;
}
.panel .panel-heading > .panel-title {
  vertical-align: middle;
}
.panel .panel-heading .nav > li > a {
  padding: 10px;
}
.panel .panel-search {
  padding: 15px;
  position: relative;
  color: #333;
  display: none;
  background: none repeat scroll 0% 0% rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.panel .panel-search i {
  position: absolute;
  right: 25px;
  top: 25px;
  color: #A2A2A2;
}
.panel .panel-sub-heading {
  display: block;
  background-color: #FBFBFB;
  border-bottom: 1px solid #CFD9DB;
}
.panel .panel-sub-heading h4 {
  margin: 0px;
}
.panel .panel-sub-heading p {
  margin: 0px;
  font-size: 13px;
  color: #999;
}
.panel .panel-body {
  position: relative;
  background-color: #FBFBFB;
  padding: 10px;
}
.panel .panel-body .table-responsive {
  margin-top: -1px;
}
.panel .panel-body.out {
  display: none;
}
.panel .panel-body .sample-wrapper p {
  font-size: 13px;
  color: #999;
}
.panel .panel-body .page-header:first-child {
  margin-top: 10px;
}
.panel .panel-body .list-group .list-group-item {
  border-top: none;
  border-right: none;
  border-bottom: 2px solid #DDD;
  border-left: none;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0px;
}
.panel .panel-body .list-group .list-group-item:last-child {
  border-bottom: none !important;
}
.panel .panel-body table {
  margin-bottom: 0px;
}
.panel .panel-body table thead tr th {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.panel .panel-footer {
  padding: 10px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.panel.panel-default .panel-heading .option .btn:hover {
  background-color: #e1e1e1;
}
.panel.rounded .ribbon-wrapper + .panel-body {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.panel.no-overflow .panel-body, .panel.no-overflow .panel-footer, .panel.no-overflow .form-footer {
  -moz-border-radius: 0px 0px 3px 3px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 3px 3px;
}

.panel-primary > .panel-heading {
  background-color: #00B1E1;
  border: 1px solid #00addc;
  color: white;
}
.panel-primary > .panel-heading .option .btn:hover {
  background-color: #009dc8;
  color: white;
}
.panel-primary > .panel-heading .option .btn i {
  color: white;
}

.panel-bg-primary .panel-body {
  background-color: #00B1E1;
  color: white;
}
.panel-bg-primary .panel-body .text-muted {
  color: #f2f2f2;
}

.panel-success > .panel-heading {
  background-color: #8CC152;
  border: 1px solid #8ac04e;
  color: white;
}
.panel-success > .panel-heading .option .btn:hover {
  background-color: #7fb842;
  color: white;
}
.panel-success > .panel-heading .option .btn i {
  color: white;
}

.panel-bg-success .panel-body {
  background-color: #8CC152;
  color: white;
}
.panel-bg-success .panel-body .text-muted {
  color: #f2f2f2;
}

.panel-info > .panel-heading {
  background-color: #63D3E9;
  border: 1px solid #5fd2e8;
  color: white;
}
.panel-info > .panel-heading .option .btn:hover {
  background-color: #4dcde6;
  color: white;
}
.panel-info > .panel-heading .option .btn i {
  color: white;
}

.panel-bg-info .panel-body {
  background-color: #63D3E9;
  color: white;
}
.panel-bg-info .panel-body .text-muted {
  color: #f2f2f2;
}

.panel-warning > .panel-heading {
  background-color: #F6BB42;
  border: 1px solid #f6b93d;
  color: white;
}
.panel-warning > .panel-heading .option .btn:hover {
  background-color: #f5b22a;
  color: white;
}
.panel-warning > .panel-heading .option .btn i {
  color: white;
}

.panel-bg-warning .panel-body {
  background-color: #F6BB42;
  color: white;
}
.panel-bg-warning .panel-body .text-muted {
  color: #f2f2f2;
}

.panel-danger > .panel-heading {
  background-color: #E9573F;
  border: 1px solid #e8533a;
  color: white;
}
.panel-danger > .panel-heading .option .btn:hover {
  background-color: #e64328;
  color: white;
}
.panel-danger > .panel-heading .option .btn i {
  color: white;
}

.panel-bg-danger .panel-body {
  background-color: #E9573F;
  color: white;
}
.panel-bg-danger .panel-body .text-muted {
  color: #f2f2f2;
}

.panel-inverse > .panel-heading {
  background-color: #2A2A2A;
  border: 1px solid #272727;
  color: white;
}
.panel-inverse > .panel-heading .option .btn:hover {
  background-color: #1d1d1d;
  color: white;
}
.panel-inverse > .panel-heading .option .btn i {
  color: white;
}

.panel-bg-inverse .panel-body {
  background-color: #2A2A2A;
  color: white;
}
.panel-bg-inverse .panel-body .text-muted {
  color: #f2f2f2;
}

.panel-lilac > .panel-heading {
  background-color: #906094;
  border: 1px solid #8d5e91;
  color: white;
}
.panel-lilac > .panel-heading .option .btn:hover {
  background-color: #815685;
  color: white;
}
.panel-lilac > .panel-heading .option .btn i {
  color: white;
}

.panel-bg-lilac .panel-body {
  background-color: #906094;
  color: white;
}
.panel-bg-lilac .panel-body .text-muted {
  color: #f2f2f2;
}

.panel-teal .panel-heading {
  background-color: #37BC9B;
  border: 1px solid #36b898;
  color: white;
}
.panel-teal .panel-heading .option .btn:hover {
  background-color: #31a88b;
  color: white;
}
.panel-teal .panel-heading .option .btn i {
  color: white;
}

.panel-bg-teal .panel-body {
  background-color: #37BC9B;
  color: white;
}
.panel-bg-teal .panel-body .text-muted {
  color: #f2f2f2;
}

.panel-scrollable .panel-body {
  height: 300px;
}

.panel-tab {
  background-color: #FBFBFB;
}
.panel-tab .panel-heading ul {
  position: relative;
  overflow: visible;
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: inline-block;
  border: none;
  margin-bottom: -6px;
}
.panel-tab .panel-heading ul li {
  line-height: 25px;
}
.panel-tab .panel-heading ul li.active {
  color: #444;
}
.panel-tab .panel-heading ul li.active a {
  color: #444;
  background: #F7F7F7;
}
.panel-tab .panel-heading ul li.active a:hover {
  background: #F7F7F7;
}
.panel-tab .panel-heading ul li.active a i {
  color: #81B71A;
}
.panel-tab .panel-heading ul li a {
  width: auto;
  margin: 0px;
  display: block;
  padding: 10px 15px;
  position: relative;
  overflow: hidden;
  color: #999;
  text-decoration: none;
  border: none;
  border-right: 1px solid #DDD;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.panel-tab .panel-heading ul li a:hover, .panel-tab .panel-heading ul li a:focus {
  border: none;
  border-right: 1px solid #DDD;
}
.panel-tab .panel-heading ul li a:hover {
  background-color: transparent;
}
.panel-tab .panel-heading ul li a:hover i {
  color: #81B71A;
}
.panel-tab .panel-heading ul li a > i, .panel-tab .panel-heading ul li a > span {
  margin: 0px auto;
  text-align: center;
}
.panel-tab .panel-heading ul li a > div {
  text-align: center;
}
.panel-tab .panel-heading ul li a i {
  width: 100%;
  height: 30px;
  line-height: 20px;
  font-size: 25px;
  color: #CCC;
}
.panel-tab .panel-heading ul li a span {
  display: block;
  line-height: 15px;
}
.panel-tab .panel-sub-heading {
  background: #F7F7F7;
}
.panel-tab .panel-body {
  background: #F7F7F7;
}
.panel-tab .panel-body .tab-content {
  background: #F7F7F7;
  padding: 0px;
  margin: 0px;
  box-shadow: none;
}
.panel-tab .panel-body .tab-content .tab-pane > h4 {
  margin-top: 0px;
}
.panel-tab .panel-body .tab-content .tab-pane > p {
  margin: 0px;
}
.panel-tab .nav-pills > li + li {
  margin-left: 0px;
}

@media (max-width: 360px) {
  .panel-tab .panel-heading .pull-right {
    float: inherit !important;
    display: block;
    width: 100%;
  }
  .panel-tab .panel-heading ul li {
    width: 100%;
  }
}
.panel-tab-double .panel-heading ul li {
  min-width: 100px;
}
.panel-tab-double .panel-heading ul li a {
  padding: 15px 15px 15px 15px;
}
.panel-tab-double .panel-heading ul li a > i, .panel-tab-double .panel-heading ul li a > div {
  float: left;
}
.panel-tab-double .panel-heading ul li a > div {
  text-align: left;
}
.panel-tab-double .panel-heading ul li a > div span:last-child {
  margin-top: 3px;
}
.panel-tab-double .panel-heading ul li a i {
  margin-top: 5px;
  margin-right: 5px;
  width: 35px;
  height: 35px;
}

@media (max-width: 640px) {
  .panel-tab.panel-tab-double .panel-heading ul li {
    width: 100%;
  }
}
.panel-tab-vertical {
  padding-bottom: 5px;
}
.panel-tab-vertical .panel-heading {
  border-bottom: none;
}
.panel-tab-vertical .panel-heading ul {
  display: block;
  height: auto;
}
.panel-tab-vertical .panel-heading ul li {
  float: none;
  display: block;
  border-right: none;
  border-bottom: 1px solid #EFEFEF;
}
.panel-tab-vertical .panel-heading ul li a {
  min-height: 40px;
}
.panel-tab-vertical .panel-heading ul li:first-child a {
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius: 3px;
}
.panel-tab-vertical .panel-heading ul li:last-child a {
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-left-radius: 3px;
}
.panel-tab-vertical .panel-body {
  box-shadow: none;
}

.panel-group .panel {
  border-top: 1px solid #DDD;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.panel-group .panel:first-child {
  border-top: none;
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
}
.panel-group .panel:last-child {
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-left-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  border-bottom-right-radius: 3px;
}
.panel-group .panel + .panel {
  margin-top: 0px;
}
.panel-group .panel .panel-heading {
  padding: 0px;
}
.panel-group .panel .panel-heading .panel-title {
  padding: 0px;
}
.panel-group .panel .panel-heading .panel-title a {
  padding: 14px 15px;
  display: block;
  text-decoration: none;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.panel-group .panel .panel-heading .panel-title a:hover {
  background-color: #F3F3F3;
}

.panel-fullsize-backdrop {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.27);
}

/* ========================================================================
 * PROGRESS BAR
 * ======================================================================== */
.progress {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.progress.progress-lg {
  height: 25px;
}
.progress.progress-sm {
  height: 15px;
}
.progress.progress-xs {
  height: 10px;
}
.progress.progress-xxs {
  height: 5px;
}

.progress-bar-primary {
  background-color: #00B1E1;
}

.progress-bar-success {
  background-color: #8CC152;
}

.progress-bar-info {
  background-color: #63D3E9;
}

.progress-bar-warning {
  background-color: #F6BB42;
}

.progress-bar-danger {
  background-color: #E9573F;
}

.progress-bar-lilac {
  background-color: #906094;
}

.progress-bar-inverse {
  background-color: #2A2A2A;
}

.progress-bar-teal {
  background-color: #37BC9B;
}

/* ========================================================================
 * IE SUPPORT
 * ======================================================================== */
.ie8 .progress-striped .hidden-ie, .ie9 .progress-striped .hidden-ie {
  display: none !important;
}

/* ========================================================================
 * RATING STAR
 * ======================================================================== */
.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  font-size: 13px;
}
.rating.rating-2x {
  font-size: 26px;
}
.rating.rating-3x {
  font-size: 39px;
}
.rating.rating-4x {
  font-size: 52px;
}
.rating.rating-5x {
  font-size: 65px;
}
.rating .star {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  cursor: pointer;
}
.rating .star:hover:before {
  content: "\f005";
}
.rating .star:before {
  content: "\f006";
  padding-right: 0;
}
.rating .star.active:before {
  content: "\f005";
}
.rating .star.active ~ .star:before {
  content: "\f005";
}

.rating-primary .star:hover:before {
  color: #00B1E1 !important;
}
.rating-primary .star.active:before {
  color: #00B1E1 !important;
}
.rating-primary .star.active ~ .star:before {
  color: #00B1E1 !important;
}

.rating-success .star:hover:before {
  color: #8CC152 !important;
}
.rating-success .star.active:before {
  color: #8CC152 !important;
}
.rating-success .star.active ~ .star:before {
  color: #8CC152 !important;
}

.rating-info .star:hover:before {
  color: #63D3E9 !important;
}
.rating-info .star.active:before {
  color: #63D3E9 !important;
}
.rating-info .star.active ~ .star:before {
  color: #63D3E9 !important;
}

.rating-warning .star:hover:before {
  color: #F6BB42 !important;
}
.rating-warning .star.active:before {
  color: #F6BB42 !important;
}
.rating-warning .star.active ~ .star:before {
  color: #F6BB42 !important;
}

.rating-danger .star:hover:before {
  color: #E9573F !important;
}
.rating-danger .star.active:before {
  color: #E9573F !important;
}
.rating-danger .star.active ~ .star:before {
  color: #E9573F !important;
}

.rating-lilac .star:hover:before {
  color: #906094 !important;
}
.rating-lilac .star.active:before {
  color: #906094 !important;
}
.rating-lilac .star.active ~ .star:before {
  color: #906094 !important;
}

.rating-teals .star:hover:before {
  color: #37BC9B !important;
}
.rating-teals .star.active:before {
  color: #37BC9B !important;
}
.rating-teals .star.active ~ .star:before {
  color: #37BC9B !important;
}

.rating-inverse .star:hover:before {
  color: #2A2A2A !important;
}
.rating-inverse .star.active:before {
  color: #2A2A2A !important;
}
.rating-inverse .star.active ~ .star:before {
  color: #2A2A2A !important;
}

/* ========================================================================
 * RIBBONS
 * ======================================================================== */
.ribbon-wrapper {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -4px;
  right: -2px;
  z-index: 250;
}
.ribbon-wrapper.top-left {
  left: -2px;
}
.ribbon-wrapper.top-left .ribbon {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: -30px;
}
.ribbon-wrapper.bottom-right {
  right: -2px;
  bottom: -2px;
  top: inherit;
}
.ribbon-wrapper.bottom-right .ribbon {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: -6px;
  top: 40px;
}
.ribbon-wrapper.bottom-left {
  left: -3px;
  bottom: -3px;
  top: inherit;
}
.ribbon-wrapper.bottom-left .ribbon {
  left: -28px;
  top: 40px;
}
.ribbon-wrapper .ribbon {
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: #FFF;
  text-align: center;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -5px;
  top: 15px;
  width: 120px;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
  background-color: #797979;
}
.ribbon-wrapper .ribbon:before {
  left: 0;
  bottom: -1px;
}
.ribbon-wrapper .ribbon:after {
  right: 0;
}
.ribbon-wrapper .ribbon:before, .ribbon-wrapper .ribbon:after {
  content: "";
  border-top: 2px solid #797979;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  position: absolute;
  bottom: -2px;
}
.ribbon-wrapper .ribbon.ribbon-shadow {
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.ribbon-wrapper .ribbon.transparent {
  background-color: RGBA(121, 121, 121, 0.34) !important;
  border: none !important;
}

.ribbon-default {
  background-color: #EEEEEE !important;
}
.ribbon-default:before, .ribbon-default:after {
  border-top: 2px solid #EEEEEE !important;
}
.ribbon-default.transparent {
  background-color: fade(#EEEEEE, 40%) !important;
}
.ribbon-default.transparent:before, .ribbon-default.transparent:after {
  border-top: 2px solid fade(#EEEEEE, 40%) !important;
}
.ribbon-default.ribbon-shadow {
  -moz-box-shadow: 0 0 3px fade(#EEEEEE, 50%) !important;
  -webkit-box-shadow: 0 0 3px fade(#EEEEEE, 50%) !important;
  box-shadow: 0 0 3px fade(#EEEEEE, 50%) !important;
}

.ribbon-primary {
  background-color: #00B1E1 !important;
}
.ribbon-primary:before, .ribbon-primary:after {
  border-top: 2px solid #00B1E1 !important;
}
.ribbon-primary.transparent {
  background-color: fade(#00B1E1, 40%) !important;
}
.ribbon-primary.transparent:before, .ribbon-primary.transparent:after {
  border-top: 2px solid fade(#00B1E1, 40%) !important;
}
.ribbon-primary.ribbon-shadow {
  -moz-box-shadow: 0 0 3px fade(#00B1E1, 50%) !important;
  -webkit-box-shadow: 0 0 3px fade(#00B1E1, 50%) !important;
  box-shadow: 0 0 3px fade(#00B1E1, 50%) !important;
}

.ribbon-success {
  background-color: #8CC152 !important;
}
.ribbon-success:before, .ribbon-success:after {
  border-top: 2px solid #8CC152 !important;
}
.ribbon-success.transparent {
  background-color: fade(#8CC152, 40%) !important;
}
.ribbon-success.transparent:before, .ribbon-success.transparent:after {
  border-top: 2px solid fade(#8CC152, 40%) !important;
}
.ribbon-success.ribbon-shadow {
  -moz-box-shadow: 0 0 3px fade(#8CC152, 50%) !important;
  -webkit-box-shadow: 0 0 3px fade(#8CC152, 50%) !important;
  box-shadow: 0 0 3px fade(#8CC152, 50%) !important;
}

.ribbon-info {
  background-color: #63D3E9 !important;
}
.ribbon-info:before, .ribbon-info:after {
  border-top: 2px solid #63D3E9 !important;
}
.ribbon-info.transparent {
  background-color: fade(#63D3E9, 40%) !important;
}
.ribbon-info.transparent:before, .ribbon-info.transparent:after {
  border-top: 2px solid fade(#63D3E9, 40%) !important;
}
.ribbon-info.ribbon-shadow {
  -moz-box-shadow: 0 0 3px fade(#63D3E9, 50%) !important;
  -webkit-box-shadow: 0 0 3px fade(#63D3E9, 50%) !important;
  box-shadow: 0 0 3px fade(#63D3E9, 50%) !important;
}

.ribbon-warning {
  background-color: #F6BB42 !important;
}
.ribbon-warning:before, .ribbon-warning:after {
  border-top: 2px solid #F6BB42 !important;
}
.ribbon-warning.transparent {
  background-color: fade(#F6BB42, 40%) !important;
}
.ribbon-warning.transparent:before, .ribbon-warning.transparent:after {
  border-top: 2px solid fade(#F6BB42, 40%) !important;
}
.ribbon-warning.ribbon-shadow {
  -moz-box-shadow: 0 0 3px fade(#F6BB42, 50%) !important;
  -webkit-box-shadow: 0 0 3px fade(#F6BB42, 50%) !important;
  box-shadow: 0 0 3px fade(#F6BB42, 50%) !important;
}

.ribbon-danger {
  background-color: #E9573F !important;
}
.ribbon-danger:before, .ribbon-danger:after {
  border-top: 2px solid #E9573F !important;
}
.ribbon-danger.transparent {
  background-color: fade(#E9573F, 40%) !important;
}
.ribbon-danger.transparent:before, .ribbon-danger.transparent:after {
  border-top: 2px solid fade(#E9573F, 40%) !important;
}
.ribbon-danger.ribbon-shadow {
  -moz-box-shadow: 0 0 3px fade(#E9573F, 50%) !important;
  -webkit-box-shadow: 0 0 3px fade(#E9573F, 50%) !important;
  box-shadow: 0 0 3px fade(#E9573F, 50%) !important;
}

.ribbon-lilac {
  background-color: #906094 !important;
}
.ribbon-lilac:before, .ribbon-lilac:after {
  border-top: 2px solid #906094 !important;
}
.ribbon-lilac.transparent {
  background-color: fade(#906094, 40%) !important;
}
.ribbon-lilac.transparent:before, .ribbon-lilac.transparent:after {
  border-top: 2px solid fade(#906094, 40%) !important;
}
.ribbon-lilac.ribbon-shadow {
  -moz-box-shadow: 0 0 3px fade(#906094, 50%) !important;
  -webkit-box-shadow: 0 0 3px fade(#906094, 50%) !important;
  box-shadow: 0 0 3px fade(#906094, 50%) !important;
}

.ribbon-teals {
  background-color: #37BC9B !important;
}
.ribbon-teals:before, .ribbon-teals:after {
  border-top: 2px solid #37BC9B !important;
}
.ribbon-teals.transparent {
  background-color: fade(#37BC9B, 40%) !important;
}
.ribbon-teals.transparent:before, .ribbon-teals.transparent:after {
  border-top: 2px solid fade(#37BC9B, 40%) !important;
}
.ribbon-teals.ribbon-shadow {
  -moz-box-shadow: 0 0 3px fade(#37BC9B, 50%) !important;
  -webkit-box-shadow: 0 0 3px fade(#37BC9B, 50%) !important;
  box-shadow: 0 0 3px fade(#37BC9B, 50%) !important;
}

.ribbon-inverse {
  background-color: #2A2A2A !important;
}
.ribbon-inverse:before, .ribbon-inverse:after {
  border-top: 2px solid #2A2A2A !important;
}
.ribbon-inverse.transparent {
  background-color: fade(#2A2A2A, 40%) !important;
}
.ribbon-inverse.transparent:before, .ribbon-inverse.transparent:after {
  border-top: 2px solid fade(#2A2A2A, 40%) !important;
}
.ribbon-inverse.ribbon-shadow {
  -moz-box-shadow: 0 0 3px fade(#2A2A2A, 50%) !important;
  -webkit-box-shadow: 0 0 3px fade(#2A2A2A, 50%) !important;
  box-shadow: 0 0 3px fade(#2A2A2A, 50%) !important;
}

/* ========================================================================
 * TABLE
 * ======================================================================== */
.table {
  margin-bottom: 0px;
}
.table thead tr th {
  background-color: #EEEEEE;
  border-color: #e1e1e1 #e1e1e1 gainsboro;
  vertical-align: middle;
  border-width: 1px 1px 7px;
  border-style: solid;
  font-weight: 400;
  font-size: 13px;
}
.table thead tr th:first-child {
  border-left: none;
}
.table thead tr th:last-child {
  border-right: none;
}
.table thead .sorting:after {
  margin-left: 5px;
  content: "\f0dc";
  font-family: 'FontAwesome';
}
.table thead .sorting_asc:after {
  margin-left: 5px;
  content: "\f0dd";
  font-family: 'FontAwesome';
}
.table thead .sorting_desc:after {
  margin-left: 5px;
  content: "\f0de";
  font-family: 'FontAwesome';
}
.table thead .sorting_asc_disabled:after {
  margin-left: 5px;
  color: rgba(255, 255, 255, 0.3);
  content: "\f0dd";
  font-family: 'FontAwesome';
}
.table thead .sorting_desc_disabled:after {
  margin-left: 5px;
  color: rgba(255, 255, 255, 0.3);
  content: "\f0de";
  font-family: 'FontAwesome';
}
.table tbody tr.border-danger td {
  background: inherit !important;
}
.table tbody tr.border-danger td:first-child {
  position: relative;
  padding-left: 10px;
}
.table tbody tr.border-danger td:first-child:before {
  border-left: 3px solid #E9573F;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -1px;
  bottom: 0;
}
.table tbody tr.border-success td {
  background: inherit !important;
}
.table tbody tr.border-success td:first-child {
  position: relative;
  padding-left: 10px;
}
.table tbody tr.border-success td:first-child:before {
  border-left: 3px solid #8CC152;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -1px;
  bottom: 0;
}
.table tbody tr.border-primary td {
  background: inherit !important;
}
.table tbody tr.border-primary td:first-child {
  position: relative;
  padding-left: 10px;
}
.table tbody tr.border-primary td:first-child:before {
  border-left: 3px solid #00B1E1;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -1px;
  bottom: 0;
}
.table tbody tr.border-info td {
  background: inherit !important;
}
.table tbody tr.border-info td:first-child {
  position: relative;
  padding-left: 10px;
}
.table tbody tr.border-info td:first-child:before {
  border-left: 3px solid #63D3E9;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -1px;
  bottom: 0;
}
.table tbody tr.border-warning td {
  background: inherit !important;
}
.table tbody tr.border-warning td:first-child {
  position: relative;
  padding-left: 10px;
}
.table tbody tr.border-warning td:first-child:before {
  border-left: 3px solid #F6BB42;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -1px;
  bottom: 0;
}
.table tbody tr.border-inverse td {
  background: inherit !important;
}
.table tbody tr.border-inverse td:first-child {
  position: relative;
  padding-left: 10px;
}
.table tbody tr.border-inverse td:first-child:before {
  border-left: 3px solid #2A2A2A;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -1px;
  bottom: 0;
}
.table tbody tr.border-lilac td {
  background: inherit !important;
}
.table tbody tr.border-lilac td:first-child {
  position: relative;
  padding-left: 10px;
}
.table tbody tr.border-lilac td:first-child:before {
  border-left: 3px solid #906094;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -1px;
  bottom: 0;
}
.table tbody tr:first-child td:first-child:before {
  top: 2px !important;
}
.table tbody tr td {
  background-color: #FBFBFB;
}
.table tbody tr td > a {
  margin-right: 5px;
}
.table tbody tr td > a i {
  color: #636E7B;
}
.table tbody tr td .btn.btn-success i, .table tbody tr td .btn.btn-danger i, .table tbody tr td .btn.btn-primary i, .table tbody tr td .btn.btn-info i, .table tbody tr td .btn.btn-warning i {
  color: white;
}
.table tfoot tr th {
  vertical-align: middle;
  background-color: #EEEEEE;
  border-width: 7px 1px 1px;
  border-style: solid;
  border-color: gainsboro #e1e1e1 #e1e1e1;
  font-weight: 400;
  font-size: 13px;
  border-bottom: none;
  padding-left: 18px;
  padding-right: 18px;
}
.table tfoot tr th:first-child {
  border-left: none;
}
.table tfoot tr th:last-child {
  border-right: none;
}
.table.table-striped tbody tr:nth-child(odd) td, .table.table-striped tbody tr:nth-child(odd) th {
  background-color: #ecedef;
}

table.table-bordered tbody tr td:first-child:before {
  left: -2px !important;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  background: none;
}

.table-primary thead tr th {
  background-color: #00B1E1 !important;
  border-color: #00c5fb #00c5fb #0095bd !important;
  color: #fff;
}
.table-primary tbody tr td.sorting_1 {
  background: #24d0ff !important;
  color: white;
  border-bottom: 1px solid #48d8ff !important;
}
.table-primary tfoot tr th {
  background-color: #00B1E1 !important;
  border-color: #0095bd #00c5fb #00c5fb !important;
  color: #fff;
}
.table-primary.table-bordered {
  border: 1px solid #00B1E1;
}
.table-primary.table-bordered thead tr th:first-child, .table-primary.table-bordered thead tr th:last-child, .table-primary.table-bordered tfoot tr th:first-child, .table-primary.table-bordered tfoot tr th:last-child {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.table-primary.table-bordered tbody tr td.sorting_1 {
  border-right: 1px solid #48d8ff !important;
}
.table-primary table.has-columns-hidden > tbody > tr > td > span.responsiveExpander:before {
  color: white;
}
.table-primary table.has-columns-hidden > tbody > tr.detail-show > td span.responsiveExpander:before {
  color: white;
}

.table-success thead tr th {
  background-color: #8CC152 !important;
  border-color: #98c865 #98c865 #7ab03f !important;
  color: #fff;
}
.table-success tbody tr td.sorting_1 {
  background: #acd283 !important;
  color: white;
  border-bottom: 1px solid #bedc9d !important;
}
.table-success tfoot tr th {
  background-color: #8CC152 !important;
  border-color: #7ab03f #98c865 #98c865 !important;
  color: #fff;
}
.table-success.table-bordered {
  border: 1px solid #8CC152;
}
.table-success.table-bordered thead tr th:first-child, .table-success.table-bordered thead tr th:last-child, .table-success.table-bordered tfoot tr th:first-child, .table-success.table-bordered tfoot tr th:last-child {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.table-success.table-bordered tbody tr td.sorting_1 {
  border-right: 1px solid #bedc9d !important;
}
.table-success table.has-columns-hidden > tbody > tr > td > span.responsiveExpander:before {
  color: white;
}
.table-success table.has-columns-hidden > tbody > tr.detail-show > td span.responsiveExpander:before {
  color: white;
}

.table-info thead tr th {
  background-color: #63D3E9 !important;
  border-color: #79d9ec #79d9ec #44cae5 !important;
  color: #fff;
}
.table-info tbody tr td.sorting_1 {
  background: #9de3f1 !important;
  color: white;
  border-bottom: 1px solid #bcecf6 !important;
}
.table-info tfoot tr th {
  background-color: #63D3E9 !important;
  border-color: #44cae5 #79d9ec #79d9ec !important;
  color: #fff;
}
.table-info.table-bordered {
  border: 1px solid #63D3E9;
}
.table-info.table-bordered thead tr th:first-child, .table-info.table-bordered thead tr th:last-child, .table-info.table-bordered tfoot tr th:first-child, .table-info.table-bordered tfoot tr th:last-child {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.table-info.table-bordered tbody tr td.sorting_1 {
  border-right: 1px solid #bcecf6 !important;
}
.table-info table.has-columns-hidden > tbody > tr > td > span.responsiveExpander:before {
  color: white;
}
.table-info table.has-columns-hidden > tbody > tr.detail-show > td span.responsiveExpander:before {
  color: white;
}

.table-warning thead tr th {
  background-color: #F6BB42 !important;
  border-color: #f7c45a #f7c45a #f4af20 !important;
  color: #fff;
}
.table-warning tbody tr td.sorting_1 {
  background: #f9d281 !important;
  color: white;
  border-bottom: 1px solid #fbdea3 !important;
}
.table-warning tfoot tr th {
  background-color: #F6BB42 !important;
  border-color: #f4af20 #f7c45a #f7c45a !important;
  color: #fff;
}
.table-warning.table-bordered {
  border: 1px solid #F6BB42;
}
.table-warning.table-bordered thead tr th:first-child, .table-warning.table-bordered thead tr th:last-child, .table-warning.table-bordered tfoot tr th:first-child, .table-warning.table-bordered tfoot tr th:last-child {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.table-warning.table-bordered tbody tr td.sorting_1 {
  border-right: 1px solid #fbdea3 !important;
}
.table-warning table.has-columns-hidden > tbody > tr > td > span.responsiveExpander:before {
  color: white;
}
.table-warning table.has-columns-hidden > tbody > tr.detail-show > td span.responsiveExpander:before {
  color: white;
}

.table-danger thead tr th {
  background-color: #E9573F !important;
  border-color: #ec6b56 #ec6b56 #e53b1f !important;
  color: #fff;
}
.table-danger tbody tr td.sorting_1 {
  background: #f08b7a !important;
  color: white;
  border-bottom: 1px solid #f3a79b !important;
}
.table-danger tfoot tr th {
  background-color: #E9573F !important;
  border-color: #e53b1f #ec6b56 #ec6b56 !important;
  color: #fff;
}
.table-danger.table-bordered {
  border: 1px solid #E9573F;
}
.table-danger.table-bordered thead tr th:first-child, .table-danger.table-bordered thead tr th:last-child, .table-danger.table-bordered tfoot tr th:first-child, .table-danger.table-bordered tfoot tr th:last-child {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.table-danger.table-bordered tbody tr td.sorting_1 {
  border-right: 1px solid #f3a79b !important;
}
.table-danger table.has-columns-hidden > tbody > tr > td > span.responsiveExpander:before {
  color: white;
}
.table-danger table.has-columns-hidden > tbody > tr.detail-show > td span.responsiveExpander:before {
  color: white;
}

.table-lilac thead tr th {
  background-color: #906094 !important;
  border-color: #9c6da0 #9c6da0 #7b527e !important;
  color: #fff;
}
.table-lilac tbody tr td.sorting_1 {
  background: #ad86b0 !important;
  color: white;
  border-bottom: 1px solid #bc9cbe !important;
}
.table-lilac tfoot tr th {
  background-color: #906094 !important;
  border-color: #7b527e #9c6da0 #9c6da0 !important;
  color: #fff;
}
.table-lilac.table-bordered {
  border: 1px solid #906094;
}
.table-lilac.table-bordered thead tr th:first-child, .table-lilac.table-bordered thead tr th:last-child, .table-lilac.table-bordered tfoot tr th:first-child, .table-lilac.table-bordered tfoot tr th:last-child {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.table-lilac.table-bordered tbody tr td.sorting_1 {
  border-right: 1px solid #bc9cbe !important;
}
.table-lilac table.has-columns-hidden > tbody > tr > td > span.responsiveExpander:before {
  color: white;
}
.table-lilac table.has-columns-hidden > tbody > tr.detail-show > td span.responsiveExpander:before {
  color: white;
}

.table-inverse thead tr th {
  background-color: #2A2A2A !important;
  border-color: #373737 #373737 #181818 !important;
  color: #fff;
}
.table-inverse tbody tr td.sorting_1 {
  background: #4b4b4b !important;
  color: white;
  border-bottom: 1px solid #5d5d5d !important;
}
.table-inverse tfoot tr th {
  background-color: #2A2A2A !important;
  border-color: #181818 #373737 #373737 !important;
  color: #fff;
}
.table-inverse.table-bordered {
  border: 1px solid #2A2A2A;
}
.table-inverse.table-bordered thead tr th:first-child, .table-inverse.table-bordered thead tr th:last-child, .table-inverse.table-bordered tfoot tr th:first-child, .table-inverse.table-bordered tfoot tr th:last-child {
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
.table-inverse.table-bordered tbody tr td.sorting_1 {
  border-right: 1px solid #5d5d5d !important;
}
.table-inverse table.has-columns-hidden > tbody > tr > td > span.responsiveExpander:before {
  color: white;
}
.table-inverse table.has-columns-hidden > tbody > tr.detail-show > td span.responsiveExpander:before {
  color: white;
}

.table-teal thead tr th {
  background-color: #37BC9B !important;
  border-color: #44c8a8 #44c8a8 #2fa084 !important;
  color: #fff;
}
.table-teal tfoot tr th {
  background-color: #37BC9B !important;
  border-color: #2fa084 #31a88b #31a88b !important;
}

.table-responsive.rounded {
  overflow: auto !important;
}

.table-middle tr th, .table-middle tr td {
  vertical-align: middle !important;
}

.table-no-border tr td {
  border: none !important;
}

/* ========================================================================
 * TABS
 * ======================================================================== */
.nav-tabs .nav-border {
  position: relative;
}
.nav-tabs .nav-border:first-child.active:after {
  left: 0;
}
.nav-tabs .nav-border.active:after {
  content: "";
  display: block;
  position: absolute;
}
.nav-tabs .nav-border-top-danger.active:after {
  border: 2px solid #E9573F;
  top: 0;
  left: -1px;
  right: 0;
}
.nav-tabs .nav-border-top-primary.active:after {
  border: 2px solid #00B1E1;
  top: 0;
  left: -1px;
  right: 0;
}
.nav-tabs .nav-border-top-success.active:after {
  border: 2px solid #8CC152;
  top: 0;
  left: -1px;
  right: 0;
}
.nav-tabs .nav-border-top-info.active:after {
  border: 2px solid #63D3E9;
  top: 0;
  left: -1px;
  right: 0;
}
.nav-tabs .nav-border-top-warning.active:after {
  border: 2px solid #F6BB42;
  top: 0;
  left: -1px;
  right: 0;
}
.nav-tabs .nav-border-top-lilac.active:after {
  border: 2px solid #906094;
  top: 0;
  left: -1px;
  right: 0;
}
.nav-tabs .nav-border-top-inverse.active:after {
  border: 2px solid #2A2A2A;
  top: 0;
  left: -1px;
  right: 0;
}
.nav-tabs .nav-border-top-teal.active:after {
  border: 2px solid #37BC9B;
  top: 0;
  left: -1px;
  right: 0;
}
.nav-tabs .nav-border-left-danger.active:after {
  border: 2px solid #E9573F;
  top: 0;
  bottom: 0;
  left: 0;
}
.nav-tabs .nav-border-left-primary.active:after {
  border: 2px solid #00B1E1;
  top: 0;
  bottom: 0;
  left: 0;
}
.nav-tabs .nav-border-left-success.active:after {
  border: 2px solid #8CC152;
  top: 0;
  bottom: 0;
  left: 0;
}
.nav-tabs .nav-border-left-info.active:after {
  border: 2px solid #63D3E9;
  top: 0;
  bottom: 0;
  left: 0;
}
.nav-tabs .nav-border-left-warning.active:after {
  border: 2px solid #F6BB42;
  top: 0;
  bottom: 0;
  left: 0;
}
.nav-tabs .nav-border-left-lilac.active:after {
  border: 2px solid #906094;
  top: 0;
  bottom: 0;
  left: 0;
}
.nav-tabs .nav-border-left-inverse.active:after {
  border: 2px solid #2A2A2A;
  top: 0;
  bottom: 0;
  left: 0;
}
.nav-tabs .nav-border-left-teal.active:after {
  border: 2px solid #37BC9B;
  top: 0;
  bottom: 0;
  left: 0;
}
.nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0;
}
.nav-tabs.nav-justified > li > a {
  padding: 15px;
  background-color: #EEEEEE;
  color: #aeaeae;
  border: 0;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.nav-tabs.nav-justified > li:last-child > a {
  border-top-right-radius: 3px;
}
.nav-tabs.nav-justified > .active > a {
  color: #333;
  background-color: #FBFBFB;
  border: 0;
  border-color: #FFF;
  border-bottom-color: #FCFCFC;
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
}
.nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
  border: 0;
  border-color: #FFF;
  border-bottom-color: #FCFCFC;
  background-color: #FBFBFB;
}

.tab-content {
  background-color: #FBFBFB;
  padding: 15px;
  -moz-box-shadow: 0 3px 0 rgba(12, 12, 12, 0.03);
  -webkit-box-shadow: 0 3px 0 rgba(12, 12, 12, 0.03);
  box-shadow: 0 3px 0 rgba(12, 12, 12, 0.03);
  -moz-border-radius: 0 0 3px 3px;
  -webkit-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}

@media (max-width: 800px) {
  .panel .nav-tabs li a {
    border-right: none !important;
  }
}
/* ========================================================================
 * THUMBNAIL
 * ======================================================================== */
.thumbnail h3 {
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.thumbnail .caption p {
  color: #888888;
}
.thumbnail .thumbnail-action {
  background: #EEEEEE;
  padding: 10px;
}
.thumbnail.thumbnail-flat {
  padding: 0;
  border: none;
}
.thumbnail.thumbnail-flat .subtitle {
  color: #959595;
}
.thumbnail.thumbnail-flat p {
  color: #888888;
}
.thumbnail.thumbnail-flat .desc {
  margin-top: 15px;
}
.thumbnail.thumbnail-flat .caption {
  border-left: 1px solid #DDD;
  border-right: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
}

/* ========================================================================
 * TOOLTIP
 * ======================================================================== */
.tooltip {
  z-index: 1000;
}

/* ========================================================================
 * TYPOGRAPHY
 * ======================================================================== */
.text-thin {
  font-weight: 300 !important;
}

.text-xlarge {
  font-size: 50px !important;
  line-height: 50px !important;
}

.text-xs {
  font-size: 10px !important;
}

.text-sm {
  font-size: 12px !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-strong {
  font-weight: bold !important;
}

.text-white {
  color: white !important;
}

.text-block {
  display: block !important;
}

.text-default {
  color: #EEEEEE !important;
}

.text-primary {
  color: #00B1E1 !important;
}

.text-success {
  color: #8CC152 !important;
}

.text-info {
  color: #63D3E9 !important;
}

.text-warning {
  color: #F6BB42 !important;
}

.text-danger {
  color: #E9573F !important;
}

.text-lilac {
  color: #906094 !important;
}

.text-inverse {
  color: #2A2A2A !important;
}

.text-teal {
  color: #37BC9B !important;
}

/* ========================================================================
 * POPOVER
 * ======================================================================== */
.popover {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.popover.top > .arrow:after {
  border-top-color: #FFFFFF;
}
.popover.bottom > .arrow:after {
  border-bottom-color: #F7F7F7;
}
.popover.left > .arrow:after {
  border-left-color: #F7F7F7;
}
.popover.right > .arrow:after {
  border-right-color: #F7F7F7;
}
.popover .popover-title {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

/* ========================================================================
 * MISC
 * ======================================================================== */
/* ========================================================================
 * TABLE OF CONTENTS
 * ========================================================================
   01. FUNCTION BACKGROUND COLOR
   02. CONTEXTUAL CLASSES BACKGROUND
   03. FUNCTION BACKGROUND GRADIENT
   04. CONTEXTUAL CLASSES BACKGROUND GRADIENT
   05. FUNCTION FOREGROUND COLOR
   06. CONTEXTUAL CLASSES FOREGROUND
   07. BORDER
   08. MARGIN
   09. POSITION
   10. LINE HEIGHT
   11. LOADING ANIMATION
   12. LOADING INDICATOR
   13. CHOOSE THEMES
   14. BOOTSTRAP OVERRIDE
   15. FULLSCREEN MODE
   16. CHARTS
   17. BACK TOP
 * ======================================================================== */
.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: none !important;
}
.no-border:active, .no-border:focus {
  border: none !important;
}

.no-overflow {
  overflow: visible !important;
}

.no-box-shadow {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.no-border-radius {
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px;
  border-radius: 3px !important;
}

.no-border-radius-right {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.transparent {
  background: transparent !important;
}

.rounded {
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px;
  border-radius: 3px !important;
}
.rounded:after, .rounded:before {
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px;
  border-radius: 3px !important;
}

.circle {
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50%;
  border-radius: 50% !important;
}
.circle:after, .circle:before {
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50%;
  border-radius: 50% !important;
}

.shadow {
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) !important;
}

.rounded-bottom {
  -moz-border-radius: 0px 0px 3px 3px !important;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 3px 3px !important;
}

.inner-all {
  padding: 10px;
}
.inner-all > button, .inner-all > a, .inner-all .label, .inner-all .badge {
  margin-bottom: 5px;
}
.inner-all > .label, .inner-all .badge {
  display: inline-block;
}

.border-bottom {
  border-bottom: 1px solid #DDD;
}

.block {
  display: block !important;
}

.hide {
  display: none !important;
}

.display-hide {
  display: none;
}

.display-inline {
  display: inline-block !important;
}

.half {
  width: 50% !important;
}

.inline {
  display: inline !important;
  width: auto !important;
}

.divider {
  height: 20px;
  display: block;
}

.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}

.full-width {
  width: 100% !important;
}

.line {
  height: 2px;
  margin: 10px 0px;
  font-size: 0px;
  overflow: hidden;
  background-color: transparent;
  border-width: 1px 0px 0px;
  border-top: 1px solid #DDD;
}

.corner-left {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
  padding: 5px;
}

.asterisk {
  color: #E9573F !important;
}

.section-sample {
  display: block;
  margin-bottom: 25px;
}
.section-sample a, .section-sample button {
  margin-bottom: 5px;
}

.upgrade-browser {
  background-color: #777;
  color: #FFFFFF;
  position: fixed;
  z-index: 99999;
  left: 0px;
  right: 0px;
  text-align: center;
  padding: 15px 0px;
  font-size: 15px;
}
.upgrade-browser a {
  text-decoration: underline;
  color: #FFFFFF;
}
.upgrade-browser a:hover, .upgrade-browser a:active, .upgrade-browser a:visited {
  color: #FFFFFF !important;
}

.sample-rating .rating {
  margin-bottom: 30px;
}

.text-vertically-center {
  height: 200px;
  line-height: 200px;
  text-align: center;
  font-size: 20px;
}
.text-vertically-center span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.show-grid .row {
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
}
.show-grid [class^="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #FBFBFB;
  border-right: 1px solid #DDD;
}
.show-grid [class^="col-"]:last-child {
  border-right: none;
}
.show-grid .show-grid [class^="col-"] {
  background-color: #f2f2f2;
}

.bg-teal {
  background-color: #37BC9B !important;
  color: white;
}
.bg-teal a, .bg-teal i, .bg-teal span, .bg-teal small, .bg-teal p {
  color: white;
}
.bg-teal .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-teal .morris-hover-row-label {
  background-color: #2b957a;
}

.no-bg {
  background: transparent !important;
}

.bg-dark {
  background-color: #373737;
}

.bg-light {
  background-color: #E9EAED;
}
.bg-light #wrapper {
  background-color: #E9EAED;
}

.bg-default {
  background-color: #EEEEEE !important;
  border: 1px solid #EEEEEE;
  color: white;
}
.bg-default a, .bg-default i, .bg-default span, .bg-default small, .bg-default p {
  color: white;
}
.bg-default .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-default .morris-hover-row-label {
  background-color: #d5d5d5;
}

.bg-primary {
  background-color: #00B1E1 !important;
  border: 1px solid #00B1E1;
  color: white;
}
.bg-primary a, .bg-primary i, .bg-primary span, .bg-primary small, .bg-primary p {
  color: white;
}
.bg-primary .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-primary .morris-hover-row-label {
  background-color: #0089ae;
}

.bg-success {
  background-color: #8CC152 !important;
  border: 1px solid #8CC152;
  color: white;
}
.bg-success a, .bg-success i, .bg-success span, .bg-success small, .bg-success p {
  color: white;
}
.bg-success .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-success .morris-hover-row-label {
  background-color: #72a53b;
}

.bg-info {
  background-color: #63D3E9 !important;
  border: 1px solid #63D3E9;
  color: white;
}
.bg-info a, .bg-info i, .bg-info span, .bg-info small, .bg-info p {
  color: white;
}
.bg-info .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-info .morris-hover-row-label {
  background-color: #36c6e3;
}

.bg-warning {
  background-color: #F6BB42 !important;
  border: 1px solid #F6BB42;
  color: white;
}
.bg-warning a, .bg-warning i, .bg-warning span, .bg-warning small, .bg-warning p {
  color: white;
}
.bg-warning .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-warning .morris-hover-row-label {
  background-color: #f4a911;
}

.bg-danger {
  background-color: #E9573F !important;
  border: 1px solid #E9573F;
  color: white;
}
.bg-danger a, .bg-danger i, .bg-danger span, .bg-danger small, .bg-danger p {
  color: white;
}
.bg-danger .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-danger .morris-hover-row-label {
  background-color: #dc3519;
}

.bg-lilac {
  background-color: #906094 !important;
  border: 1px solid #906094;
  color: white;
}
.bg-lilac a, .bg-lilac i, .bg-lilac span, .bg-lilac small, .bg-lilac p {
  color: white;
}
.bg-lilac .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-lilac .morris-hover-row-label {
  background-color: #724c75;
}

.bg-inverse {
  background-color: #2A2A2A !important;
  border: 1px solid #2A2A2A;
  color: white;
}
.bg-inverse a, .bg-inverse i, .bg-inverse span, .bg-inverse small, .bg-inverse p {
  color: white;
}
.bg-inverse .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-inverse .morris-hover-row-label {
  background-color: #111111;
}

.bg-overlay {
  background-color: rgba(0, 0, 0, 0.35) !important;
  border: 1px solid rgba(0, 0, 0, 0.35);
  color: white;
}
.bg-overlay a, .bg-overlay i, .bg-overlay span, .bg-overlay small, .bg-overlay p {
  color: white;
}
.bg-overlay .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-overlay .morris-hover-row-label {
  background-color: rgba(0, 0, 0, 0.35);
}

.bg-facebook {
  background-color: #3b5998 !important;
  border: 1px solid #3b5998;
  color: white;
}
.bg-facebook a, .bg-facebook i, .bg-facebook span, .bg-facebook small, .bg-facebook p {
  color: white;
}
.bg-facebook .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-facebook .morris-hover-row-label {
  background-color: #2d4373;
}

.bg-twitter {
  background-color: #00a0d1 !important;
  border: 1px solid #00a0d1;
  color: white;
}
.bg-twitter a, .bg-twitter i, .bg-twitter span, .bg-twitter small, .bg-twitter p {
  color: white;
}
.bg-twitter .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-twitter .morris-hover-row-label {
  background-color: #00799e;
}

.bg-googleplus {
  background-color: #db4a39 !important;
  border: 1px solid #db4a39;
  color: white;
}
.bg-googleplus a, .bg-googleplus i, .bg-googleplus span, .bg-googleplus small, .bg-googleplus p {
  color: white;
}
.bg-googleplus .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-googleplus .morris-hover-row-label {
  background-color: #be3323;
}

.bg-bitbucket {
  background-color: #205081 !important;
  border: 1px solid #205081;
  color: white;
}
.bg-bitbucket a, .bg-bitbucket i, .bg-bitbucket span, .bg-bitbucket small, .bg-bitbucket p {
  color: white;
}
.bg-bitbucket .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-bitbucket .morris-hover-row-label {
  background-color: #163758;
}

.bg-youtube {
  background-color: #c4302b !important;
  border: 1px solid #c4302b;
  color: white;
}
.bg-youtube a, .bg-youtube i, .bg-youtube span, .bg-youtube small, .bg-youtube p {
  color: white;
}
.bg-youtube .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-youtube .morris-hover-row-label {
  background-color: #9a2622;
}

.bg-dribbble {
  background-color: #ea4c89 !important;
  border: 1px solid #ea4c89;
  color: white;
}
.bg-dribbble a, .bg-dribbble i, .bg-dribbble span, .bg-dribbble small, .bg-dribbble p {
  color: white;
}
.bg-dribbble .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-dribbble .morris-hover-row-label {
  background-color: #e51e6b;
}

.bg-soundcloud {
  background-color: #ff7700 !important;
  border: 1px solid #ff7700;
  color: white;
}
.bg-soundcloud a, .bg-soundcloud i, .bg-soundcloud span, .bg-soundcloud small, .bg-soundcloud p {
  color: white;
}
.bg-soundcloud .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-soundcloud .morris-hover-row-label {
  background-color: #cc5f00;
}

.fg-default {
  color: #EEEEEE !important;
}

.fg-primary {
  color: #00B1E1 !important;
}

.fg-success {
  color: #8CC152 !important;
}

.fg-info {
  color: #63D3E9 !important;
}

.fg-warning {
  color: #F6BB42 !important;
}

.fg-danger {
  color: #E9573F !important;
}

.fg-lilac {
  color: #906094 !important;
}

.fg-teals {
  color: #37BC9B !important;
}

.fg-inverse {
  color: #2A2A2A !important;
}

.fg-facebook {
  color: #3b5998 !important;
}

.fg-twitter {
  color: #00a0d1 !important;
}

.fg-googleplus {
  color: #db4a39 !important;
}

.fg-bitbucket {
  color: #205081 !important;
}

.no-border-top {
  border-top: none !important;
}

.no-border-left {
  border-left: none !important;
}

.no-border-right {
  border-right: none !important;
}

.border {
  border: 1px solid #DDD;
}

.border-top {
  border-top: 1px solid #DDD;
}

.border-right {
  border-right: 1px solid #DDD;
}
.border-right.dotted {
  border-right: 1px dotted #DDD;
}

.border-top-danger {
  border-top: 3px solid #E9573F;
}

.border-top-primary {
  border-top: 3px solid #00B1E1;
}

.border-top-info {
  border-top: 3px solid #63D3E9;
}

.border-top-success {
  border-top: 3px solid #8CC152;
}

.border-top-warning {
  border-top: 3px solid #F6BB42;
}

.br-3 {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.br-t-3 {
  -moz-border-radius: 3px 3px 0px 0px;
  -webkit-border-radius: 3px;
  border-radius: 3px 3px 0px 0px;
}

.br-l-3 {
  -moz-border-radius: 3px 0px 0px 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px 0px 0px 3px;
}

.br-r-3 {
  -moz-border-radius: 0px 3px 3px 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px 3px 3px 0px;
}

.br-b-3 {
  -moz-border-radius: 0px 0px 3px 3px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 3px 3px;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.m-5 {
  margin: 5px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.no-top {
  top: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.no-right {
  right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.lh-5 {
  line-height: 5 px !important;
}

.lh-10 {
  line-height: 10 px !important;
}

.lh-15 {
  line-height: 15 px !important;
}

.lh-20 {
  line-height: 20 px !important;
}

.lh-25 {
  line-height: 25 px !important;
}

.lh-30 {
  line-height: 30 px !important;
}

/* ========================================================================
 * LOADING ANIMATION
 * ======================================================================== */
#loading {
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999999;
}
#loading .loading-inner {
  position: absolute;
  top: 40%;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
}
#loading .loading-inner img {
  margin-right: auto;
  margin-left: auto;
}

/* ========================================================================
 * LOADING INDICATOR
 * ======================================================================== */
.indicator {
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  cursor: progress;
}
.indicator.inline {
  display: block !important;
  position: relative;
  z-index: 9;
  height: 34px;
  background-color: transparent;
  border-radius: 0;
}
.indicator .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -9px;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
}

/* ========================================================================
 * CHOOSE THEMES
 * ======================================================================== */
.themes {
  position: relative;
  z-index: 2;
  list-style: none;
  padding: 0px;
}
.themes li {
  height: auto;
  border: none;
  padding: 0;
  position: relative;
  float: left;
  margin-bottom: 5px;
}
.themes li a {
  opacity: 0.7;
  line-height: 32px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  display: block;
  color: #676767;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  font-size: 14px;
  padding: 5px !important;
  width: 17px;
  height: 17px !important;
  margin-right: 5px;
}
.themes li a:hover {
  opacity: 1;
}

/* ========================================================================
 * BOOTSTRAP OVERRIDE
 * ======================================================================= */
.row {
  margin-left: -10px;
  margin-right: -10px;
}

.row-merge {
  margin: 0px;
}
.row-merge > [class*="col-"] {
  padding: 0px;
}

.col-xs-1, .col-xs-2,
.col-xs-3, .col-xs-4,
.col-xs-5, .col-xs-6,
.col-xs-7, .col-xs-8,
.col-xs-9, .col-xs-10,
.col-xs-11, .col-xs-12,
.col-sm-1, .col-sm-2,
.col-sm-3, .col-sm-4,
.col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8,
.col-sm-9, .col-sm-10,
.col-sm-11, .col-sm-12,
.col-md-1, .col-md-2,
.col-md-3, .col-md-4,
.col-md-5, .col-md-6,
.col-md-7, .col-md-8,
.col-md-9, .col-md-10,
.col-md-11, .col-md-12,
.col-lg-1, .col-lg-2,
.col-lg-3, .col-lg-4,
.col-lg-5, .col-lg-6,
.col-lg-7, .col-lg-8,
.col-lg-9, .col-lg-10,
.col-lg-11, .col-lg-12 {
  padding-left: 10px;
  padding-right: 10px;
}

.table-responsive {
  border: none;
}

.table tr td img {
  margin-right: 5px;
}

.img-responsive {
  max-height: 200px;
}

.bs-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  left: auto;
  bottom: auto;
  z-index: 1;
  display: block;
  overflow: visible;
}
.bs-example-modal .modal-dialog {
  left: auto;
  margin: 0px auto 10px;
  width: auto;
}
.bs-example-modal .modal-content {
  box-shadow: none;
}

.modal-content {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}

.list-group-item:first-child, .list-group-item:last-child {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.navbar {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.navbar-nav.navbar-right:last-child {
  margin-right: 0px;
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 10px;
}

.thumbnail {
  position: relative;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.well {
  border: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

@media (max-width: 768px) {
  .navbar {
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px;
    border-radius: 0px !important;
  }
  .incomplete-notification {
    left: 0 !important;
  }
}
@media (max-width: 360px) {
  .col-xs-override, .col-xs-6 {
    width: 100% !important;
  }
}
.dropdown-menu > li > a {
  padding: 3px 8px;
}

/* ========================================================================
 * FULLSCREEN MODE
 * ======================================================================= */
#fullscreen-mode {
  bottom: 0px;
  right: 0px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
}
#fullscreen-mode .panel {
  height: inherit;
}
#fullscreen-mode .panel .panel-body {
  height: inherit;
  overflow-y: scroll;
}

/* ========================================================================
 * CHARTS
 * ======================================================================== */
.chart {
  width: 100%;
  height: 300px;
  padding: 0px;
  position: relative;
}

/* ========================================================================
 * BACK TOP
 * ======================================================================== */
#back-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  color: #EEE;
  text-align: center;
  z-index: 1000;
  background-color: rgba(89, 95, 102, 0.7);
  display: block;
  opacity: 1;
  width: 40px;
  height: 40px;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out;
  -webkit-transition-delay: 0s;
  transition: all 0.5s ease-in-out 0s;
}
#back-top i {
  font-size: 20px;
  line-height: 35px;
}

.align-middle{
  vertical-align:middle;
  float:none;
}

.panel-heading .pull-right {
  text-align: right;
}
.panel-heading .pull-right .btn {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.profile-body .edit-profile {
  margin: 20px;
}