@import '../../styles/mixins/media';

.incomplete-picks-page {
  .body-content {
    @include media-sm {
      padding: 0;
    }
  }

  .panel-body {
    padding: 20px 10px;

    @include media-tn {
      padding: 20px 0;
    }
  }

  .form-horizontal {
    border: 1px solid #ddd;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    background-color: #ededed;
    position: relative;

    .name::placeholder {
      text-transform: capitalize;
    }

    input,
    textarea {
      color: #373737 !important;
    }

    label {
      font-size: 12px;
      margin-bottom: 0;
    }

    .form-control[disabled] {
      background-color: #c5c3c3;
    }

    .form-group {
      margin-bottom: 10px;
    }

    .potential-roi {
      font-size: 13px;
      margin-top: 10px;

      span {
        font-weight: bold;
      }
    }

    .pick-price {
      > div {
        margin-top: 5px;
        margin-left: 3px;
        display: flex;
      }

      label {
        margin-left: 5px;
      }
    }

    &::after {
      content: 'Please select at least one pick';
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      background-color: rgba(42, 42, 42, 0.75);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      color: #fff;
    }

    &.available::after {
      display: none;
    }

    > div {
      width: 100%;
    }
  }

  .info-arrow {
    display: flex;
    margin: 25px 0;
    justify-content: center;
    align-items: center;

    i {
      font-size: 23px;
      color: #f5bb42;
      margin-right: 10px;
    }

    span {
      font-weight: bold;
    }
  }

  .picks-to-complete-wrapper {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .col-md-7 {
    @include media-sm {
      margin-bottom: 35px;
    }
  }
}

.incomplete-picks-modal {
  .modal-dialog {
    top: 25%;

    @media (min-width: 768px) {
      width: 450px;
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}