/* ========================================================================
 * SIGN
 * ======================================================================== */
body {
  background-color: transparent !important;
}
body.page-boxed {
  background-color: #505050;
}
body.page-boxed:before {
  background-color: #505050;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  content: "";
}
body.page-boxed #sign-wrapper form {
  width: 350px;
  margin: 0px auto;
}

@media (min-width: 1200px) {
  body.page-boxed #sign-wrapper {
    width: 1220px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  body.page-boxed #sign-wrapper {
    width: 970px;
  }
}
#sign-wrapper {
  width: 350px;
  margin: 0px auto;
  padding-top: 5%;
  height: 100%;
  position: relative;
}
#sign-wrapper a {
  font-size: 13px;
  color: #f7f7f7;
}
#sign-wrapper form {
  background-color: rgba(255, 255, 255, 0.19);
}
#sign-wrapper form.rounded .sign-header {
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
}
#sign-wrapper form.rounded .sign-body .form-group .error {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
#sign-wrapper form.circle .sign-header {
  -webkit-border-top-left-radius: 50%;
  -moz-border-radius-topleft: 50%;
  border-top-left-radius: 50%;
  -webkit-border-top-right-radius: 50%;
  -moz-border-radius-topright: 50%;
  border-top-right-radius: 50%;
}
#sign-wrapper form.circle .sign-body .form-group .error {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
#sign-wrapper input {
  font-size: 12px !important;
}
#sign-wrapper input:focus {
  box-shadow: none;
}
#sign-wrapper .form-group {
  margin-left: 0;
  margin-right: 0;
  padding: 0 15px;
}
#sign-wrapper .form-group:last-child {
  margin-bottom: 0;
}
#sign-wrapper .form-group .input-group .input-group-addon {
  border: none;
  padding-left: 0px;
}
#sign-wrapper .form-group .input-group input[type=text], #sign-wrapper .form-group .input-group input[type=password], #sign-wrapper .form-group .input-group input[type=email] {
  box-shadow: none !important;
  border: none !important;
  color: #B6B6B6;
}
#sign-wrapper .form-group .input-group i {
  font-size: 23px;
  color: #B6B6B6;
}
#sign-wrapper .form-group .input-group-addon {
  background-color: white;
}
#sign-wrapper .ckbox label {
  color: #f7f7f7;
}
#sign-wrapper .brand {
  text-align: center;
  margin-bottom: 20px;
}
#sign-wrapper .brand > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#sign-wrapper hr {
  margin: 5px 0px 10px;
}
#sign-wrapper .text-muted {
  font-size: 13px;
}
#sign-wrapper .sign-link {
  margin-top: 20px;
  color: #CCC9C9;
}

.sign-header {
  color: white;
  padding: 20px 15px;
  margin-bottom: 0px;
}
.sign-header p, .sign-header strong {
  color: white;
}


.sign-body {
  margin: 0px;
  padding: 15px 10px;
  background-color: #2a2a2a;
}
.sign-body .form-group .error {
  position: absolute;
  background-color: #E9573F;
  padding: 5px 10px;
  top: 9px;
  color: white !important;
}
.sign-body .form-group:first-child .error {
  left: -205px;
}
.sign-body .form-group:first-child .error:after {
  content: "____";
  position: absolute;
  right: -20px;
  color: #E9573F;
  top: 0px;
}
.sign-body .form-group:last-child .error {
  right: -190px;
}
.sign-body .form-group:last-child .error:before {
  content: "____";
  position: absolute;
  left: -20px;
  color: #E9573F;
  top: 0px;
}

.sign-footer {
  padding: 15px 10px;
  background-color: #2a2a2a;
}

.lock .sign-text:before {
  top: 40%;
}

.sign-text {
  color: #777;
  display: block;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  text-align: center;
}
.sign-text:before {
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
}
.sign-text span {
  display: inline-block;
  padding: 0 10px;
  position: relative;
  color: white;
  font-weight: 300;
}
.sign-text img {
  position: relative;
}
.sign-text p {
  margin-bottom: 0;
  margin-top: 5px;
}

@media (min-width: 1200px) {
  body.page-boxed #sign-wrapper {
    width: 1220px;
  }
}
@media (max-width: 1024px) {
  body.page-boxed #sign-wrapper {
    width: 100%;
  }
}
@media (max-width: 360px) {
  #sign-wrapper form {
    width: 95% !important;
  }
}
.ckbox label:before {
  border: none;
}


//custom
.auth-page {
  .body-content {
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 62px);

    @include media-sm {
      min-height: calc(100vh - 120px);
    }
  }
}

.auth {
  max-width: 340px;
  width: 100%;
  margin: 0 auto;

  .sign-header {
    .form-group {
      margin-bottom: 0;
    }
  }

  .sign-body {
    padding-bottom: 0px;

    .form-group:last-child {
      margin-bottom: 0;
    }
  }

  .sign-footer {
    padding-bottom: 7px;
  }

  .callout-info {
    padding: 3px;

    p {
      line-height: 1.4;
      color: #373737;
    }
  }

  .ckbox-theme {
    input {
      margin-right: 5px;
    }

    label {
      margin-bottom: 0!important;
    }
  }
}

.headerButtons {
  position: absolute;
  right: 0px;
  padding: 10px;
}

.paypal-login {
  margin-top: 15px;
  height: 46px;
  background-color: #0070BA!important;
  font-size: 18px;
  color: #fff !important;
  line-height: 1.2;
  text-align: center;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #337ab7!important;
  }
}

.paypal-icon {
  color: white;
  font-size: 22px;
  margin-right: 10px!important;
}

.social-login-btns {
  > div {
    margin: 15px 0 0!important;
    height: 46px!important;

    > div {
      justify-content: center!important;

      > div {
        width: auto!important;
        font-size: 18px!important;
      }
    }

    svg {
      width: 22px!important;
      height: 22px!important;
      margin-right: 10px!important;
    }
  }
}

.social-login-title {
  font-size: 13px;
  color: #636e7b;
  margin-bottom: -10px;
  margin-top: 10px;
}