/* Customize styles for sidebar menu scrollbar */
body .scrollbar-track-x {
  display: none!important;
}

body .scrollbar-track {
  background: #3c3c3c;
}

body .scrollbar-thumb {
  background: rgba(233, 87, 63, 0.8);
}