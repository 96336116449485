@import '../../styles/mixins/media';

.feed-page {
  .user-pick {
    width: calc(100% - 250px);
  }

  .feed-sidebar {
    @include media-lg {
      display: none;
    }

    &-title {
      text-align: center;
      background-color: #607D8B;
      padding: 12px;
      color: #eee;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .inline-button {
    display: inline-block;
    width: auto;
    margin-right: 3px;
    font-weight: bold;
  }

  .blog-item {
    @media (min-width: 767px) {
      display: flex;
      flex-direction: row-reverse;

      &.with-media {
        .price {
          flex-direction: row;
        }

        iframe {
          height: 100% !important;
        }
      }

      .blog-details {
        flex-grow: 1;
      }
    }
  }

  .media-block {
      width: 450px;

    .content {
      width: 100%;
      height: 100%;

      @include media-sm {
        height: 280px;
      }
    }
    @media (max-width: 767px) {
      max-width: 100%;
      height: auto;
      width: auto;
    }

    .media-block-image {
      display: block;
      height: 100%;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      &.cover {
        background-size: cover;
      }
    }

    img {
      height: 100%;
      width: 100%;
    }

    .youtube-cover {
      height: 100%;
      width: 100%;

      @media (max-width: 767px) {
        min-height: 190px;
      }
    }
  }
}

.feed-filter {
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;

  .panel-heading {
    border: none;
  }
}

.feed-modal {
  .modal-dialog {
    top: 25%;
  }

  .modal-body {
    label {
      vertical-align: top;
      margin-bottom: 0;
      margin-left: 3px;
    }
  }

  .modal-footer {
    display: flex;

    @include media-sm {
      flex-direction: column;
    }

    .btn {
      @include media-sm {
        margin-left: 0!important;

        &:first-child {
          margin-bottom: 5px;
        }
      }
    }
  }
}