/* ========================================================================
 * RESET
 * ======================================================================== */
@import '../../styles/mixins/media';

body, html {
  background-color: #E9EAED;
  font-family: "Open Sans", sans-serif;
  color: #636E7B;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  line-height: 21px;
  font-size: 13px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 !important;
}

a, button {
  outline: none !important;
}

input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea,
select {
  border: 1px solid #DDD;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #a2a2a2 !important;
  font-size: 14px !important;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;

  @include media-sm {
    font-size: 16px!important; // fix for ios mobile zooming
  }
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;

  @include media-sm {
    font-size: 16px!important; // fix for ios mobile zooming
  }
}

input[type=file] {
  border: none !important;
}

input[type=checkbox]:disabled + label, input[type=radio]:disabled + label {
  color: #999;
  cursor: not-allowed;
}

label {
  font-weight: normal;
}

code {
  background: transparent;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

svg {
  width: 100%;
}
