@import '../../../styles/mixins/media';

.chat-history-wrapper {
    background-color: #7e818b;
}

.chat-history {
    padding: 20px 30px;
    position: relative;
    background-color: #7e818b;
    flex-grow: 1;
    height: calc(100vh - 309px);
    min-height: 200px;

    @include media-sm {
        height: calc(100vh - 359px);
        height: calc(var(--vh, 1vh) * 100 - 359px); // hack for mobile browsers
    }

    @include media-tn {
        padding: 20px;
        height: calc(100vh - 314px);
    }

    .loader {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        z-index: 1;
        bottom: 0;
        left: 0;
        background-color: #7e818b;
        height: calc(100vh - 310px);

        @include media-sm {
            height: calc(100vh - 360px);
            height: calc(var(--vh, 1vh) * 100 - 360px); // hack for mobile browsers
        }

        @include media-tn {
            height: calc(100vh - 314px);
        }
    }

    ul {
        list-style: none;
        padding: 0;
    }

    .no-messages {
        font-size: 17px;
        text-align: center;
        color: #ddd;
        margin-top: 30px;
    }

    .message-wrapper {
        flex-grow: 1;
        max-width: calc(100% - 65px);
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: none;
        text-overflow: ellipsis;
    }

    .message {
        display: flex;
        flex-direction: row-reverse;

        .avatar {
            border-radius: 50%;
            margin-top: 5px;
            margin-right: 0px;
            margin-left: 15px;

            img {
                border-radius: 50%;
            }
        }

        .name {
            color: #ddd;
        }

        .data {
            margin-bottom: 5px;
            text-transform: capitalize;
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
        }

        .time {
            color: #ddd;
            padding-left: 6px;
            text-transform: lowercase;
        }

        .text {
            color: #2a2a2a;
            padding: 12px 20px;
            line-height: 26px;
            font-size: 16px;
            border-radius: 27px;
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 1.6;
            background-color: #ccc;
            position: relative;
            box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
            border-top-left-radius: 27px;
            border-top-right-radius: 0;

        }

        &:last-child .text {
            margin-bottom: 15px;
        }
    }

    .my-message {
        flex-direction: row;

        .avatar {
            margin-right: 15px;
            margin-left: 0px;
        }

        .text {
            background: #ccc;
            color: #000;
            border-top-left-radius: 0;
            border-top-right-radius: 27px;

            &::after {
                border-bottom-color: #fff;
            }
        }

        .data {
            flex-direction: row;
        }
    }
}

.chat.is-ios .chat-history {
    @include media-tn {
        height: calc(100vh - 260px);
        height: calc(var(--vh, 1vh) * 100 - 260px); // hack for mobile browsers
    }

    .loader {
        @include media-tn {
            height: calc(100vh - 260px);
            height: calc(var(--vh, 1vh) * 100 - 260px); // hack for mobile browsers
        }
    }
}