@import '../../../styles/mixins/media';

body .incomplete-notification {
  z-index: 1000 !important;
}

.incomplete-notification {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 250px;

  @include media-sm {
    position: fixed;
  }

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: grey;
    height: 50px;
    border-top: 1px solid #e9573f;
    padding-right: 100px; // add space for chat button

    @include media-sm {
      width: 100%;
    }
  }

  .teams {
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    flex-shrink: 1;

    @include media-tn {
      display: none;
    }

    & > p {
      color: #fff;
      display: inline;

      > span {
        font-weight: bold;
        font-size: 16px;
        font-style: italic;
        color: #f6bb42;
        margin: 0 5px;
      }

      &::after {
        content: ',';
        display: inline-block;
        margin-right: 10px;
        font-size: 15px;
      }

      &:last-child::after {
        display: none;
      }
    }
  }

  .btn {
    max-width: 190px;
    margin-left: 50px;
    flex-shrink: 0;
    font-weight: bold;
    color: #333;
    line-height: 1.8;

    @include media-tn {
      margin-left: 0;
    }

    &:hover,
    &:focus,
    &:active {
      color: #333;
    }
  }
}