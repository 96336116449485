@import '../../../styles/mixins/media';

.chat-header {
    padding: 23px 30px;
    background-color: #434651;
    color: #b7b7b7;

    @include media-tn {
        padding: 10px 20px;
    }

    .title {
        @include media-tn {
            margin-right: auto;
        }

        a {
            color: #b2b3b3;
        }

        span {
            @include media-tn {
                display: none;
            }
        }
    }

    .chat-with {
        padding-top: 5px;
        font-weight: bold;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-tn {
            justify-content: flex-start;
        }

        > div:not(.title) {
            flex-shrink: 0;
        }
    }

    .user-name {
        text-transform: capitalize;
    }

    .users-list {
        margin-left: 5px;

        span {
            &::after {
                content: ',';
                display: inline-block;
                margin-left: 2px;
                margin-right: 7px;
            }

            &:last-child::after {
                display: none;
            }
        }
    }

    .block-unblock-user,
    .send-tip {
        margin-left: 30px;
        cursor: pointer;

        @include media-sm {
            margin-left: 15px;
        }

        i {
            margin-right: 8px;

            @include media-sm {
                font-size: 22px;
                padding: 0 5px;
                margin-right: 0;
            }
        }

        span {
            font-size: 15px;

            @include media-sm {
                display: none;
            }
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .close-room {
        display: none;
        margin-right: 15px;

        i {
            font-size: 25px;
            padding: 0 5px;
        }

        @media (max-width: 767px) {
            display: block;
        }
    }
}
