@import '../../styles/mixins/media';

.picks-page {
    .loader {
        height: 55vh;
    }
    .body-content {
        @include media-sm {
            padding: 0 0 60px;
            .row:first-child {
                margin: 0;
                .btn {
                    margin: 10px 0;
                }
                .input-group {
                    padding: 0 10px;
                    .input-group-btn {
                        .btn {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
