.online-user {
  display: flex;
  align-items: center;
  padding: 15px;

  .avatar {
    margin-right: 5px;
  }

  .full-width {
    display: block;
    width: 100%;
  }

  small {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 3px;
  }

  .full-name {
    span {
      display: inline-block;
      font-size: 18px;
      margin-left: 4px;
      width: 80%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}