@import '../../../styles/mixins/media';

.landing-header {
  min-height: 100vh;
  height: min-content;
  background-image: url('/img/landing/header-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.85);
  }

  .down {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2;
    text-align: center;
    width: 50px;
    padding: 10px 0;
    bottom: 25px;

    i {
      font-size: 40px;
      color: #fff;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: 40px 50px;

    @include media-sm {
      flex-direction: column;
      justify-content: center;
    }

    @include media-tn {
      padding: 50px 20px 100px;
    }

    h1 {
      color: #eee;
      line-height: 1.4;

      @include media-sm {
        margin-bottom: 80px;
      }

      @include media-tn {
        font-size: 27px;
        margin-bottom: 50px;
      }
    }

    .landing-sign-up {
      margin-left: 100px;
      flex-shrink: 0;

      @include media-sm {
        margin-left: 0;
      }
    }
  }

  .menu {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -30px;
    width: 100%;
    z-index: 4;
    position: relative;

    @include media-sm {
      padding: 20px 15px;
    }

    .main-logo {
      padding-left: 0;
    }

    &-burger {
      display: none;
      padding: 10px;
      position: fixed;
      z-index: 1;
      right: 15px;
      top: 5px;

      @include media-sm {
        display: block;
      }

      i {
        font-size: 35px;
        color: #e9573f;
      }
    }

    nav {
      @include media-sm {
        display: none;
        flex-direction: column;
        align-items: center;
        background-color: #2a2a2a;
        padding: 90px 30px 30px;
        min-width: 270px;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1;
        height: 100%;

        &.active {
          display: flex;
        }
      }

      a {
        padding: 0px 20px;
        color: #eee;
        font-size: 15px;
        text-transform: uppercase;
        line-height: 1.3;
        font-weight: bold;

        @include media-sm {
          padding: 10px 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}