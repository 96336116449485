@import '../../../styles/mixins/media';

.landing-footer {
  background: #222;
  padding: 100px 0;

  .container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include media-sm {
        flex-direction: column;
      }
  }

  .right {
    display: flex;

    @include media-sm {
      flex-direction: column;
      margin-top: 40px;
    }
  }

  .col {
    min-width: 250px;
    padding-right: 30px;

    @include media-sm {
      margin-bottom: 30px;
    }
  }

  .links {
    display: flex;
    flex-direction: column;

    a {
      color: #888;
      font-size: 15px;
      margin-bottom: 8px;
    }
  }

  .sub-title {
    color: #ccc;
    font-size: 17px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  .socials {
    a {
      padding: 0 10px;

      &:first-child {
        margin-left: -10px;
      }

      i {
        color: #888;
        font-size: 16px;

        &:hover {
          color: #eee;
        }
      }
    }
  }
}