@import '../../../styles/mixins/media';

.footer {
  padding: 50px 10px 15px;
  margin-top: auto;

  a {
    color: #999;

    &:hover {
      color: #eee;
      text-decoration: none;
    }
  }

  .links {
    display: flex;
    font-size: 12px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media-sm {
      flex-direction: column;
      align-items: flex-start;
    }

    a {
      margin-right: 10px;
    }
  }

  .copyright {
    margin-top: 3px;
  }
}