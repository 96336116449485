@import '../../styles/mixins/media';

.become-consultant-page {
  .container {
    max-width: 500px;
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
  }

  p {
    font-size: 15px;
  }

  .navigation {
    justify-content: space-between;

    @include media-tn {
      flex-direction: column-reverse;
    }

    > div {
      width: 100%;
      max-width: 200px;

      @include media-tn {
        max-width: 100%;
      }
    }

    .btn {
      margin: 0;
      width: 100%;
      max-width: 200px;

      @include media-tn {
        max-width: 100%;
        margin-top: 10px;
      }
    }
  }

  .paypal-login {
    padding: 6 12px;
    height: 34px;
    background-image: linear-gradient(#0070BA 20%, #0070BA) !important;
    font-size: 15px;
    color: #fff !important;
    font-weight: bold;
    line-height: 20px;
    &:hover {
      background-image: linear-gradient(#003087 20%, #003087) !important;
      text-decoration: underline;
    }
    .paypal-icon {
      font-size: 20px;
    }
  }
}