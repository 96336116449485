@import '../../../styles/mixins/media';

#sidebar-left {
  width: 250px;
  float: left;
  z-index: 200;
  position: absolute!important;
  background-color: #2A2A2A;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 62px;

  @include media-sm {
    position: fixed!important;
    width: 220px;
    left: -250px;
    z-index: 9999;
  }

  @include media-sm {
    .sidebar-content {
      padding: 23px 10px;
    }

    .sidebar-menu > li:first-child a {
      padding-top: 13px;
    }
  }

  .scroll-wrap {
    height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;

    @include media-sm {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100); // hack for mobile browsers
    }
  }

  .scrollbar-track-y {
    right: auto;
    left: 0;
  }

  .leagues {
    &::after {
      content: "\F105";
      font-family: FontAwesome;
      font-size: 15px;
      display: inline-block;
      margin-left: 20px;
      vertical-align: middle;
    }

    &.opened {
      &::after {
        content: "\F107";
      }
    }
  }

  .fullname {
    span {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 150px;
      white-space: nowrap;

      @include media-sm {
        width: 120px;
      }
    }
  }

  .sidebar-menu ul li > a.active {
    color: #b7b7b7;
  }
}

/* ========================================================================
 * SIDEBAR CONTENT
 * ======================================================================== */
 .sidebar-content {
  position: relative;
  padding: 15px 10px;
  border-bottom: 2px solid #373737;
  min-height: 81px;
  flex-shrink: 0;

  .media-heading {
    color: #777;
    text-transform: capitalize;
    margin-top: 3px;
    font-weight: 300 !important;
    margin-bottom: 2px;
    margin-top: 5px;

    span {
      color: #d0d0d0;
      transition: 0.3s ease-in;
    }

    a:hover,
    a:focus {
      text-decoration: none;

      span {
        color: #eee;
      }
    }
  }

  p {
    margin-bottom: 0px;
  }

  small {
    color: #777;
  }

  img {
    width: 67px;
    height: 67px;
  }

  .media {
    margin-top: 0;
  }

  .close {
    color: white;
    position: absolute;
    top: -2px;
    right: 2px;
    font-size: 19px;
    font-weight: normal;
    text-shadow: 0 1px 0 #FFF;
  }

  .btn {
    opacity: .7;

    &:hover {
      opacity: 1;
    }
  }

  &:after {
    position: absolute;
    content: "";
    left: 37px;
    top: 0px;
    bottom: 0px;
    z-index: -1;
  }
}

/* ========================================================================
 * SIDEBAR MENU
 * ======================================================================== */
 .sidebar-menu {
  list-style: none;
  padding: 0;
  position: relative;
  margin-bottom: 0px;
}
.sidebar-menu li:after {
  position: absolute;
  content: "";
  left: 55px;
  bottom: -1px;
}
.sidebar-menu li.submenu .label.pull-right, .sidebar-menu li.submenu .badge.pull-right {
  margin-right: 10px !important;
}
.sidebar-menu li.submenu .fa.arrow {
  font-size: 15px;
  margin-right: 15px;
}
.sidebar-menu li .label, .sidebar-menu li .badge {
  text-shadow: 0px 1px 1px #777;
  color: #f7f7f7;
  padding: 3px 4px 4px 4px;
}
.sidebar-menu > li {
  display: block;
  position: relative;
}
.sidebar-menu > li:after {
  border-left: 2px solid #373737;
}
.sidebar-menu > li:first-child.active {
  margin-top: -1px;
}
.sidebar-menu > li:first-child > a > .icon:after {
  top: -1px;
}
.sidebar-menu > li:last-child:after {
  bottom: 10px;
}
.sidebar-menu > li:last-child > a > .icon:after {
  bottom: 6px;
}
.sidebar-menu > li.active {
  background-color: #3c3c3c;
}
.sidebar-menu > li.active .icon {
  text-shadow: 0px 1px 1px #777;
}
.sidebar-menu > li.active > a span {
  color: #d0d0d0;
}
.sidebar-menu > li.active > a .arrow {
  color: #777;
}
.sidebar-menu > li.active > a > .plus:before {
  content: "\f147";
}
.sidebar-menu > li.active > a > .icon i {
  color: #d0d0d0;
}
.sidebar-menu > li.sidebar-minimize-end > a > .icon:after {
  bottom: 6px !important;
}
.sidebar-menu > li > a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
}
.sidebar-menu > li > a:hover span {
  color: #d0d0d0;
}
.sidebar-menu > li > a:hover .text, .sidebar-menu > li > a:hover .icon > i {
  color: #d0d0d0;
}
.sidebar-menu > li > a:after {
  position: absolute;
  content: "";
  left: 29px;
  top: -13px;
  bottom: 0px;
  z-index: -1;
}
.sidebar-menu > li > a > span {
  display: inline;
  color: #777;
}
.sidebar-menu > li > a > .count {
  float: right;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 11px;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 19px;
  background-color: #373737;
}
.sidebar-menu > li > a > .icon {
  margin-right: 10px;
}
.sidebar-menu > li > a > .icon:after {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 29px;
  z-index: -1;
}
.sidebar-menu > li > a > .icon i {
  text-align: center;
  line-height: 25px;
  width: 29px;
  font-size: 12px;
  color: #777;
}
.sidebar-menu > li > a > .arrow, .sidebar-menu > li > a > .plus {
  vertical-align: middle;
  font-weight: 400;
  text-align: center;
  font-size: 13px;
  position: absolute;
  top: 10px;
  right: 20px;
}
.sidebar-menu > li > a > .arrow:before, .sidebar-menu > li > a > .plus:before {
  font-family: FontAwesome;
  font-size: 15px;
}
.sidebar-menu > li > a > .arrow:before {
  content: "\f105";
}
.sidebar-menu > li > a > .arrow.open:before {
  content: "\f107" !important;
}
.sidebar-menu > li > a > .selected {
  display: block;
  background-image: none;
  float: right;
  position: absolute;
  right: 0px;
  top: 14px;
  background: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-top: 6px double transparent;
  border-bottom: 6px double transparent;
  border-left: 0;
  border-right: 6px solid #F3F3F4;
}
.sidebar-menu > li > a > .plus:before {
  content: "\f196";
}
.sidebar-menu > li > a > .plus.open:before {
  content: "\f147" !important;
}
.sidebar-menu > li > a > .pull-right {
  margin-top: 5px;
}
.sidebar-menu > li.submenu ul {
  display: none;
}
// .sidebar-menu > li.submenu.active .arrow:before {
//   content: "\f107";
// }
// .sidebar-menu > li.submenu.active > ul {  // fix submenu always displaying.
//   display: block;
// }
.sidebar-menu > li.submenu.active > ul > li.submenu.active .arrow:before {
  content: "\f103" !important;
}
.sidebar-menu > li.submenu.active > ul > li.submenu.active > ul {
  display: block;
}
.sidebar-menu > li.submenu.active > ul > li.submenu.active > ul > li.active a {
  color: #d0d0d0;
}
.sidebar-menu > li.submenu.active > ul > li.submenu.active > ul > li.active a:before {
  color: #81B71A;
}
.sidebar-menu > li.submenu.active > ul > li.submenu.active > ul > li a {
  color: #777;
}
.sidebar-menu > li.submenu.active > ul > li.submenu.active > ul > li a:hover {
  color: #d0d0d0;
}
.sidebar-menu > li > ul:after {
  position: absolute;
  content: "";
  left: 29px;
  top: 39px;
  bottom: 0;
}
.sidebar-menu > li > ul > li {
  line-height: 16px;
  border-bottom: 1px solid #373737;
}
.sidebar-menu > li > ul > li > a:hover {
  background-color: black;
}
.sidebar-menu > li > ul > li > a > .text {
  min-width: 110px;
}
.sidebar-menu > li > ul > li > a > .arrow {
  float: right;
  margin-right: 21px;
  vertical-align: middle;
  font-weight: 400;
  width: 30px;
  text-align: right;
  font-size: 13px;
}
.sidebar-menu > li > ul > li > a > .arrow:before {
  font-family: FontAwesome;
  font-size: 15px;
  content: "\f101" !important;
  margin-right: -6px;
}
.sidebar-menu > li > ul > li > a > .arrow.open:before {
  content: "\f103" !important;
}
.sidebar-menu > li > ul > li > ul {
  background-color: #161616;
}
.sidebar-menu > li > ul > li > ul > li > a {
  padding: 8px 0px 8px 30px;
  display: flex;
}
.sidebar-menu > li > ul > li > ul > li > a:hover {
  background-color: black;
}
.sidebar-menu > li > ul > li > ul > li > ul {
  background-color: #111111;
}
.sidebar-menu > li > ul > li > ul > li > ul > li > a {
  padding: 8px 0px 8px 35px;
}
.sidebar-menu > li > ul > li > ul > li > ul > li > a:hover {
  background-color: black;
}
.sidebar-menu > li > ul > li > ul > li > ul > li > ul {
  background-color: #0b0b0b;
}
.sidebar-menu > li > ul > li > ul > li > ul > li > ul > li > a {
  padding: 8px 0px 8px 40px;
}
.sidebar-menu > li > ul > li > ul > li > ul > li > ul > li > a:hover {
  background-color: black;
}
.sidebar-menu > li > ul > li > ul > li > ul > li > ul > li > ul {
  background-color: #060606;
}
.sidebar-menu > li > ul > li > ul > li > ul > li > ul > li > ul > li > a {
  padding: 8px 0px 8px 45px;
}
.sidebar-menu > li > ul > li > ul > li > ul > li > ul > li > ul > li > a:hover {
  background-color: black;
}
.sidebar-menu > li > ul > li.active {
  background-color: black;
}
.sidebar-menu > li > ul > li.active.submenu > a {
  background-color: #373737;
}
.sidebar-menu ul {
  margin: 0;
  padding: 0;
  background-color: #1d1d1d;
  list-style: none;
}
.sidebar-menu ul li {
  position: relative;
}
.sidebar-menu ul li:after {
  background-color: #2A2A2A;
}
.sidebar-menu ul li a {
  font-size: 12px;
  text-decoration: none;
  padding: 8px 0 8px 22px;
  display: block;
  text-transform: capitalize;
  color: #777;
}
.sidebar-menu ul li a:hover {
  color: #b7b7b7;
}
.sidebar-menu ul li a:before {
  color: #373737;
}
.sidebar-menu ul li.active a {
  color: #b7b7b7;
}
.sidebar-menu ul li.active a:before {
  color: #373737;
}
.sidebar-menu ul li.active ul li.active > a {
  color: #b7b7b7;
}
.sidebar-menu ul li.active ul li > a {
  color: #777;
}
.sidebar-menu ul li.active ul li > a:hover {
  color: #b7b7b7;
}
.sidebar-menu .submenu-header {
  display: none;
}

.sidebar-menu ul a.active::before {
  color: red!important;
}

.sidebar-menu ul li.active::after {
  box-shadow: 0 0 0 5px #dc3519!important;
}

/* Fix styles of template for sidebar menu, because custom scrollbar added between ul and li,
but template styles were written with inheritage like this .sidebar-menu > li > ul,
so I add .menu-list class to ul and write new styles without inheritage. */
.sidebar-circle .sidebar-menu > li .menu-list > li > a {
  text-transform: uppercase;
}

#sidebar-left .sidebar-menu ul li > ul li.active:after  {
  display: none;
}

.sidebar-circle .sidebar-menu > li .menu-list:after {
  border-left: 2px solid #373737;
}

.sidebar-circle .sidebar-menu > li .menu-list > li:after {
  z-index: 1;
  width: 8px;
  height: 8px;
  left: 26px;
  top: 13px;
  bottom: auto;
  border-color: transparent;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 0 0 2px #373737;
}

.sidebar-circle .sidebar-menu > li .menu-list > li a:before {
  content: "\2014\A0";
  margin-left: 13px;
}

.sidebar-circle .sidebar-menu > li .menu-list > li:after {
  border-radius: 50%;
}

.sidebar-circle .sidebar-menu > li .menu-list:after {
  border-left: 2px solid #373737;
}

.sidebar-menu > li .menu-list:after {
  position: absolute;
  content: "";
  left: 29px;
  top: 0;
  bottom: 0;
}

#sidebar-left.sidebar-circle .sidebar-menu > li .menu-list > li:hover:after {
  box-shadow: 0 0 0 5px #dc3519;
}

.sidebar-menu > li .menu-list > li > a:hover {
    background-color: black;
}

.sidebar-menu > li .menu-list > li {
  line-height: 16px;
  border-bottom: 1px solid #373737;
}
.sidebar-menu > li.submenu.show > a > .arrow:before {
  content: "\F107" !important;
}