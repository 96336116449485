@import '../../../styles/mixins/media';

.landing-why-we {
  padding: 100px 0px;
  background-color: rgb(249, 249, 249);

  .server_title {
      margin-bottom: 40px;

      h2 {
          text-transform: uppercase;
          margin-bottom: 20px;
      }

      p {
          font-size: 14px;
      }
  }

  .server_left_text{
    i {
        font-size: 25px;
        margin-top: 7px;
    }

    .col-md-6 {
        @include media-sm {
            margin-bottom: 40px;
        }
    }

    .media{
        margin-top: 40px;
        &:first-child{
            margin-top: 0px;
        }
        .media-left{
            padding-right: 15px;
        }
        .media-body{
            h4 {
                font-size: 18px;
                font-weight: bold;
                color: #e9573f;
                letter-spacing: .36px;
                padding-bottom: 22px;
            }
            p {
                font-size: 14px;
                line-height: 24px;
                color: #777;
                letter-spacing: .28px;
            }
        }
    }
  }
  .server_right_img{
    text-align: center;
    vertical-align: bottom;
    height: 100%;
    margin-top: 100px;
    padding-left: 40px;

    @include media-sm {
        padding-left: 0;
    }

    img{
        max-width: 100%;
    }
  }
}