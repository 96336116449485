@import '../../../styles/mixins/media';

.picks-to-complete-table {
  position: relative;
  width: calc(100% - 50px);

  @include media-tn {
    width: 100%;
  }

  .main-head {
    border: 1px solid #607D8B;
    height: 45px;
    background-color: #607D8B;

    @include media-tn {
      padding-right: 50px;
    }

    .text-right {
      @include media-lg {
        display: none;
      }
    }
  }

  thead .head th {
    background-color: #ececed;
  }

  tbody {
    td {
      background-color: #ededed!important;
    }
  }

  
  .team-separator {
    padding: 0 10px;
    color: #f5bb42;
  }
}
.selected-picks-to-complete {
  position: relative;
  .remove {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    color: #e9573f;
    height: 45px;
    cursor: pointer;
    width: 50px;
    line-height: 43px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #ddd;
    border-right: none;
    border-left: none;
    box-sizing: border-box;
    background-color: #eee;

    @include media-tn {
      right: 0;
      top: 1px;
    }
  }
}
