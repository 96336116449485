@import '../../../styles/mixins/media';

.landing-about-us {
  overflow: hidden;
  background: rgb(249, 249, 249);


  .pawerfull_area_inner {
    display: flex;
    width: 100%;

    @include media-md {
        flex-direction: column;
    }

    .col-md-5 {
        display: flex;

        @include media-md {
            margin-top: 80px;
            margin-bottom: -30px;
        }

        @include media-sm {
            display: none;
        }
    }
  }

  .power_left_img {
      text-align: center;
      align-self: center;
      vertical-align: middle;

      img {
          max-width: 100%;
      }
  }

  .power_right_content {
      &_inner {
          max-width: 620px;
          margin-left: 70px;
          align-self: center;
          vertical-align: middle;
          padding: 80px 0px;

          .content {
            padding: 47px 0px;
          }

          p {
              font-size: 16px;
              line-height: 28px;
              color: #777;
              letter-spacing: .28px;
              font-weight: 400;
              margin-bottom: 12px;
          }
          ul {
              overflow: hidden;
              margin-bottom: -30px;

              li {
                  width: 50%;
                  float: left;
                  overflow: hidden;
                  margin-bottom: 30px;
                  font-size: 20px;
                  color: #585858;
                  letter-spacing: .40px;
                  font-weight: 500;

                img {
                    padding-right: 16px;
                }
              }
          }
      }
  }
}

.landing-about-us.ie {
    .col-md-5 {
        display: block;
        margin-top: 230px;
    }
}