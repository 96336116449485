.navbar-profile {
 .dropdown-menu {
    width: 250px !important;
    box-shadow: 5px 10px 8px 10px rgba(0, 0, 0, 0.05);
    li {
      a {
        margin-top: 0 !important;
        display: block !important;
        padding: 6px 15px !important;
        line-height: 29px !important;
        font-size: 13px !important;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}