@import '../../styles/mixins/media';

.odds-page {
  .body-content {
    @include media-sm {
        padding: 0 0 60px;
        .row:first-child {
            margin: 0;
            .btn {
                margin: 10px 0;
            }
            .input-group {
                padding: 0 10px;
                .input-group-btn {
                    .btn {
                        margin: 0;
                    }
                }
            }
        }
    }
  }
  .last-update {
      text-align: right;
      color: #607D8B;
      font-weight: bold;
  }

  .last-update i {
      margin-right: 5px;
  }

  .loader {
      height: 55vh;
  }
}