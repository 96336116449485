@import '../../../styles/mixins/media';

.pick {
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    @include media-tn {
        flex-direction: column;
    }

    .info {
        padding: 10px;
        background: #607d8b;
        width: 240px;
        flex-shrink: 0;
        margin-left: 15px;

        @include media-tn {
            margin-left: 0;
            width: 100%;
        }

        .item {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-bottom: 5px;
            color: #eee;
            font-size: 14px;
            font-weight: bold;

            .title {
                flex-shrink: 0;
                margin-right: 10px;
            }

            .value {
                flex-grow: 1;
                overflow: hidden;
                text-align: right;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &:last-child {
            margin-bottom: 0;
            }

            a {
            color: #eee;
            }
        }

        .btn {
            margin-top: 10px;
            margin-bottom: 3px;
        }
    }

    .socialShareWrap {
        margin-top: 12px;
        text-align: center;

        .SocialMediaShareButton {
          display: inline-block;
          margin-right: 12px
        }
      }
}

.user-pick {
    margin-top: 0;
    display: table;
    width: 100%;

    @include media-tn {
        width: 100%;
    }

    @include media-lg {
        display: flex;
        flex-direction: column;
    }


    th,
    td {
        text-align: center;
    }

    th {
        padding: 10px!important;
        font-weight: bold!important;
        border-bottom: 0!important;
    }

    thead {
        border: 1px solid #ddd;
    }

    .head {
        color: #555;

        @include media-lg {
            display: flex;
        }

        th {
            background-color: #fff;
            position: relative;

            @include media-lg {
                flex-basis: 100%;
                line-height: 2;
            }

            .label {
                position: absolute;
                left: 10px;
                top: 12px;

                @include media-lg {
                    left: 0px;
                    top: -1px;
                    font-size: 9px;
                }
            }
        }
    }

    .main-head {
        border: 1px solid #607D8B;
        color: #eee;
        position: relative;

        .teams > div {
            padding: 0 30px;

            @include media-lg {
                padding: 0;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }
        }

        @include media-lg {
            display: flex;
            width: 100%;
            flex-direction: column;

            .status {
                position: absolute;
                left: 0px;
                top: 1px;
            }

            .date {
                order: 1;
                padding-bottom: 2px!important;
                font-size: 12px;
            }

            .teams {
                order: 2;
            }
        }

        th {
            background-color: #607D8B;
            border: none!important;

            @include media-lg {
                margin-bottom: -1px;
            }
        }
    }

    tbody {
        @include media-lg {
            tr {
                width: 100%;
                display: flex;
            }

            td {
                flex-basis: 100%;
            }
        }

        @include media-tn {
            td {
                font-size: 12px;
            }
        }
    }

    .scores {
        padding: 0 30px;
        color: #f5bb42;

        @include media-lg {
            padding: 0 20px;
        }
    }

    .total {
        font-weight: bold!important;
        background-color: #eee;
    }

    .team-number {
        margin-left: 7px;

        @include media-md {
            display: none;
        }
    }
}

.pregame {
    .user-pick .scores {
        padding: 0 20px;
    }
}

.final {
    .main-head .teams > div {
        padding: 0;
    }
}
