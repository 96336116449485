.upload-avatar {
  .btn {
    background-color: #fff;
    min-width: 150px;
  }
}

.add-avatar {
  text-align: center;
  overflow: hidden;

  > div > div {
    margin: 0 auto;
  }

  > img {
    margin-top: 20px;
    width: 180px;
    border: 2px solid #e9573f;
    border-radius: 50%;
  }
}
