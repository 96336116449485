@import '../../styles/mixins/media';

.affiliates-page {
  .affiliate-message {
    margin: 0 0 5px 10px;
  }
  .affiliate-link {
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 20px;

    @include media-md {
      flex-direction: column;
    }

    .btn {
      max-width: 250px;

      @include media-md {
        max-width: 100%;
      }
    }

    input {
      width: 100%;
      max-width: 700px;
      padding: 5px;
      margin-right: 20px;

      @include media-md {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }
}