@import '../../../styles/mixins/media';

.main-logo {
  padding: 0;
  display: flex;
  letter-spacing: 2px;
  color: #e9573f;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  padding-right: 10px;

  @include media-tn {
    padding-left: 85px;
    justify-content: flex-end;
  }

  .text {
    position: relative;
    overflow: hidden;
    line-height: 1.2;

    &:after {
      content: '';
      width: 14%;
      height: 100%;
      position: absolute;
      right: auto;
      top: 0;
      bottom: 0;
      left: -20%;
      background-image:
        linear-gradient(160deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.6),
          rgba(255, 255, 255, 0)
        );
    }
  }

  &:hover {
    .text {
      &:after {
        transition: all 0.4s ease-in;
          left: 100%;
        }
    }

  }

  .logo {
    margin-top: 0!important;
    margin-right: 7px;
  }
}