.friends-page {
  .panel-tab {
    margin-bottom: 0;
    border: none;

    .nav-tabs > li {
      margin-bottom: 0;
    }

    .panel-heading {
      .nav > li > a {
        min-width: 180px;
        padding: 18px 10px;

        @include media-tn {
          min-width: 100%;
        }
      }

      .nav > li {
        @include media-sm {
          width: 50%;
        }

        @include media-mc {
          width: 100%;
        }
      }

      ul {
        width: 100%;
      }

      ul li a i {
        height: auto;
        margin-top: 0;
      }

      ul li a span {
        font-size: 14px;
      }
    }
  }

  .fa-user {
    color: #777!important;
  }

  .friends {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 10px;
    padding-top: 35px;
    border: 1px solid #ccc;
    border-top: none;

    .empty-component {
      width: 100%;
      padding-bottom: 20px;
    }
  }
}