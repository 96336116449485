@import '../../styles/mixins/media';

.banner {
    margin: 20px 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-mc {
      flex-direction: column;
    }

    .text {
        max-width: 75px;
        font-size: 12px;
        line-height: 1.3;
        text-align: center;

        @include media-mc {
          max-width: 100%;
        }
    }

    .item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        max-width: 380px;
        min-height: 70px;
        width: 100%;
        border: 1px solid #9e9e9e47;
        padding: 20px;
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.7);

        @include media-tn {
          width: 280px;
          text-align: center;
        }

        @include media-mc {
          width: 200px;
        }

        img {
            max-width: 65%;
            flex-shrink: 1;

            @include media-tn {
              max-width: 100%;
            }
        }

        button {
            max-width: 100px;
            margin-left: 10px;
            flex-shrink: 0;

            @include media-tn {
              display: none;
            }
        }
    }
}
