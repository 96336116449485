@import '../../../styles/mixins/media';

#header {
  @include media-tn {
    height: 112px;

    &.on-scrolling {
      height: 50px;
    }
  }
}