@import '../../../styles/mixins/media';

.incomplete-picks-table {
  margin-top: 1px;

  @include media-lg {
    display: block;
  }

  thead {
    @include media-lg {
      display: block;
    }

    tr {
      background-color: #607d8b;
      color: #eee;

      @include media-lg {
        display: block;
        width: 100%;
      }
    }

    td {
      @include media-lg {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  tbody {

    @include media-lg {
      display: block;
    }

    tr {
      @include media-lg {
        display: block;
        width: 100%;
      }
    }

    td {
      line-height: 35px!important;
      padding-top: 5px!important;
      padding-bottom: 5px!important;
      padding-right: 60px!important;
      vertical-align: middle;
      position: relative;
      background-color: #eee!important;
      font-weight: bold;

      @include media-lg {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      @include media-lg {
        line-height: 30px!important;
      }
    }
  }

  .btn {
    max-width: 140px;
    margin-top: 3px;
  }

  .plus {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    width: 50px;
    line-height: 1;
    font-size: 20px;
    color: #607d8b;
    cursor: pointer;
    border-left: 1px solid #ddd;
    height: 100%;
  }

  .team-separator {
    padding: 0 10px;
    color: #f5bb42;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
  }
}