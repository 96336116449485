/* ========================================================================
 * TIMELINE TYPE 2
 * ======================================================================== */
.timeline {
  margin: 0;
  padding: 0;
  position: relative;
  margin-bottom: 30px;
}

.timeline-item {
  position: relative;
  margin: 0;
  padding: 0;
}
.timeline-item:before {
  content: "";
  position: absolute;
  display: block;
  width: 4px;
  background: white;
  top: 0px;
  bottom: -30px;
  margin-left: 38px;
}
.timeline-item.last-timeline:before {
  content: normal;
}

.timeline-badge {
  float: left;
  position: relative;
  padding-right: 30px;
  height: 80px;
  width: 80px;
}

.timeline-badge-userpic {
  width: 80px;
  border: 4px white solid;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50%;
  border-radius: 50% !important;
}

.timeline-badge-userpic img {
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50%;
  border-radius: 50% !important;
  vertical-align: middle !important;
}

.timeline-icon {
  width: 80px;
  height: 80px;
  background-color: #f5f6fa;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50%;
  border-radius: 50% !important;
  padding-top: 30px;
  padding-left: 22px;
}

.timeline-icon i {
  font-size: 34px;
}

.timeline-body {
  position: relative;
  padding: 20px;
  margin-top: 20px;
  margin-left: 110px;
  background-color: white;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.timeline-body .media .media-left img, .timeline-body .media .media-right img {
  width: 115px;
}

.timeline-body:before, .timeline-body:after {
  content: " ";
  display: table;
}

.timeline-body:after {
  clear: both;
}

.timeline-body-arrow {
  position: absolute;
  top: 25px;
  left: -14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14px 14px 14px 0;
  border-color: transparent white transparent transparent;
}

.timeline-body-head {
  margin-bottom: 10px;
}

.timeline-body-head-caption {
  float: left;
}

.timeline-body-title {
  font-size: 16px;
  font-weight: 600;
}

.timeline-body-alerttitle {
  font-size: 16px;
  font-weight: 600;
}

.timeline-body-time {
  font-size: 14px;
  margin-left: 10px;
}

.timeline-body-head-actions {
  float: right;
}
.timeline-body-head-actions .btn-group {
  margin-top: -2px;
}

.timeline-body-content {
  font-size: 14px;
  margin-top: 35px;
}
.timeline-body-content p:last-child {
  margin-bottom: 0;
}

.timeline-body-img {
  width: 100px;
  height: 100px;
  margin: 5px 20px 0 0px;
}

.page-container-bg-solid .timeline:before {
  background: #fff;
}
.page-container-bg-solid .timeline-badge-userpic {
  border-color: #fff;
}
.page-container-bg-solid .timeline-icon {
  background-color: #fff;
}
.page-container-bg-solid .timeline-body {
  background-color: #fff;
}
.page-container-bg-solid .timeline-body-arrow {
  border-color: transparent white transparent transparent;
}

@media (max-width: 768px) {
  .timeline-body-head-caption {
    width: 100%;
  }

  .timeline-body-head-actions {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 480px) {
  .timeline-item:before {
    margin-left: 28px;
  }

  .timeline-badge {
    padding-right: 40px;
    width: 60px;
    height: 60px;
  }

  .timeline-badge-userpic {
    width: 60px;
  }

  .timeline-icon {
    width: 60px;
    height: 60px;
    padding-top: 23px;
    padding-left: 18px;
  }
  .timeline-icon i {
    font-size: 25px;
  }

  .timeline-body {
    margin-left: 80px;
  }

  .timeline-body-arrow {
    top: 17px;
  }
}
