/**
    All media rules hosts here
 */

 $width-huge: 1600px;
 $width-large: 1200px;
 $width-middle: 992px;
 $width-small: 768px;
 $width-tiny: 480px;
 $width-micro: 350px;

 /* stylelint-disable at-rule-blacklist */
 @mixin media-mc {
     @media (max-width: $width-micro) {
         @content;
     }
 }

 @mixin media-tn {
     @media (max-width: $width-tiny) {
         @content;
     }
 }

 @mixin media-only-tn {
    @media (min-width: $width-micro + 1) and (max-width: $width-tiny) {
        @content;
    }
}

 @mixin media-sm {
     @media (max-width: $width-small) {
         @content;
     }
 }

 @mixin media-only-sm {
    @media (min-width: $width-tiny + 1) and (max-width: $width-small) {
        @content;
    }
}

 @mixin media-md {
     @media (max-width: $width-middle) {
         @content;
     }
 }

 @mixin media-only-md {
    @media (min-width: $width-small + 1) and (max-width: $width-middle) {
        @content;
    }
}

 @mixin media-lg {
     @media (max-width: $width-large) {
         @content;
     }
 }

 @mixin media-only-lg {
    @media (min-width: $width-middle + 1) and (max-width: $width-large) {
        @content;
    }
}

 @mixin media-hg {
     @media (max-width: $width-huge) {
         @content;
     }
 }

 @mixin media-only-hg {
    @media (min-width: $width-large + 1) and (max-width: $width-huge) {
        @content;
    }
}

 @mixin media-tv {
     @media (min-width: $width-huge + 1) {
         @content;
     }
 }
 /* stylelint-enable at-rule-blacklist */