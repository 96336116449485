/* ========================================================================
 * SEARCH COURSES
 * ======================================================================== */
.list-search {
  background: transparent !important;
}
.list-search .media {
  background-color: #FFFFFF;
  margin-bottom: 20px !important;
  padding: 0px !important;
}
.list-search .media.selected .meta-search .meta-action {
  color: #8CC152;
  cursor: default;
}
.list-search .media img {
  border-radius: 3px 0px 0px 3px;
  width: 225px;
}
.list-search .media .media-object {
  width: 100% !important;
  height: inherit !important;
}
.list-search .media .media-left {
  min-width: 240px;
}
.list-search .media-body {
  padding: 10px 10px 10px 0px;
  min-height: 150px;
  position: relative;
  width: 100%;
}
.list-search .meta-provider {
  position: absolute;
  left: 0px;
  bottom: 10px;
  background-color: rgba(89, 103, 104, 0.44);
  padding: 7px;
  color: #FFFFFF;
  margin-left: -5px;
  font-size: 12px;
}
.list-search .meta-provider.primary {
  border-left: 5px solid #00B1E1;
}
.list-search .meta-provider.success {
  border-left: 5px solid #8CC152;
}
.list-search .meta-provider.info {
  border-left: 5px solid #63D3E9;
}
.list-search .meta-provider.warning {
  border-left: 5px solid #F6BB42;
}
.list-search .meta-provider.danger {
  border-left: 5px solid #E9573F;
}
.list-search .meta-search {
  border-top: 1px solid #DDD;
  padding: 10px 10px 0px 0px;
  width: 100%;
}
.list-search .meta-search .meta-mounth, .list-search .meta-search .meta-level {
  margin-right: 40px;
}
.list-search .meta-search .meta-selected {
  background-color: #EEEEEE;
  padding: 3px 5px;
  border-left: 3px solid #8CC152;
}
.list-search .meta-search .meta-price {
  margin-right: 10px;
  font-weight: bold;
}
.list-search .meta-search .meta-price small {
  font-weight: 300;
}
.list-search .meta-search .meta-action {
  font-size: 20px;
  border-left: 1px dotted #DDD;
  padding-left: 10px;
  color: #DDD;
}
.list-search .meta-search .meta-action:hover {
  color: #8CC152;
}

@media (max-width: 800px) {
  .list-search .media img {
    height: 165px !important;
  }
  .list-search .media .media-body {
    min-height: 165px;
  }
  .list-search .selected .meta-search .meta-mounth, .list-search .selected .meta-search .meta-level {
    display: none;
  }
  .list-search .meta-search .meta-mounth, .list-search .meta-search .meta-level {
    margin-right: 15px !important;
  }
}
@media (max-width: 768px) {
  .list-search .meta-search .meta-mounth {
    display: none;
  }
}
@media (max-width: 768px) {
  .list-search .media > a {
    float: none !important;
    display: block;
    margin: 0px;
  }
  .list-search .media > a img {
    width: 100% !important;
    height: 100% !important;
    -moz-border-radius: 3px 3px 0px 0px;
    -webkit-border-radius: 3px;
    border-radius: 3px 3px 0px 0px;
  }
  .list-search .media .media-body {
    padding: 10px;
    min-height: 190px;
  }
  .list-search .meta-provider {
    top: 20px;
    bottom: inherit;
  }
  .list-search .meta-search {
    margin-left: -10px;
    padding: 10px;
  }
}
@media (max-width: 360px) {
  .list-search .media-left {
    width: 100%;
    display: block;
    padding-right: 0px;
  }
  .list-search .media-left img {
    width: 100% !important;
    height: inherit !important;
  }
}
