@import '../../../styles/mixins/media';

.chat-tips-modal {
  .modal-dialog {
    top: 25%;
  }

  .modal-footer {
    padding-left: 15px!important;
    padding-right: 15px!important;
  }

  .close {
    top: 5px;
    position: absolute;
    right: 5px;
    padding: 7px;
    cursor: pointer;
    z-index: 1;
  }

  .fee-massage {
    display: block;
    margin-top: 10px;
  }

  .buy-buttons {
    display: flex;

    @include media-sm {
      flex-direction: column;
    }

    .paypal {
      background: #ffc439;
      max-height: 34px;
      &:hover {
        background: #ebb435;
      }
      .paypal-img {
        max-width: 80px;
        max-height: 20px;
      }
    }

    .btn {
      @include media-sm {
        margin-left: 0!important;

        &:first-child {
          margin-bottom: 5px;
        }
      }
    }
  }
}