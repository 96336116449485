.pick-page {
  .picks {
    position: relative;
  }

  .body-content {
    @include media-tn {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .need-buy {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: bold;
    padding: 10px;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    font-size: 15px;
    line-height: 1.3;
    text-align: center;

    p {
      margin-bottom: 5px;
    }

    &.parlay {
      justify-content: center;
      padding-bottom: 40px;
    }
  }

  .table .head th {
      background-color: #fff;
  }

  .table td {
      background-color: #fff;
  }

  .alt-table-responsive {
    padding: 10px;

    @include media-mc {
      padding-left: 0;
      padding-right: 0;
    }

    > h4 {
      font-size: 17px;
    }
  }

  a {
    color: #333
  }

  .profile {
    background-color: rgba(190, 200, 207, 0.6);
    padding: 10px 25px;
    margin: 30px 10px 15px;
    border: 1px solid #607d8b;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 2px;

    @include media-lg {
      padding: 10px 0;
      flex-wrap: wrap;
    }

    &::before {
      content: 'Created by:';
      display: block;
      position: absolute;
      top: -22px;
      left: 0;
      font-size: 13px;
      font-weight: 400;
    }

    &-info {
      margin-right: 20px;
      text-align: center;
      flex-shrink: 0;

      @include media-lg {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }

      @include media-md {
        margin-bottom: 10px;
      }

      h5 {
        margin-top: 6px;
        margin-bottom: 0;
        color: #444;
      }

      img {
        @include media-lg {
          width: 50px;
          height: 50px;
        }
      }
    }

    &-stat {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-around;

      @include media-md {
        flex-wrap: wrap;
      }

      .item {
        text-align: center;
        padding: 0 20px;

        @include media-lg {
          padding: 0 15px;
        }

        @include media-md {
          width: 33%;
          margin-top: 15px;
        }


        @include media-tn {
          width: 49%;
        }
      }

      .title {
        color: #555;
        line-height: 21px;
        font-size: 13px;
        font-weight: bold;
      }

      .value {
        color: #555;
        font-size: 24px;
        line-height: 1.3;
        font-weight: bold;
      }

      .separator {
        width: 1px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.3);

        @include media-lg {
          &:first-child {
            display: none;
          }
        }

        @include media-md {
          &:nth-child(7) {
            display: none;
          }
        }

        @include media-tn {
          &:nth-child(7) {
            display: block;
          }

          &:nth-child(5),
          &:nth-child(9) {
            display: none;
          }
        }
      }
    }
  }

  .buy-buttons {
    padding: 0 10px;
    margin-top: 15px;
    margin-bottom: 30px;

    @include media-sm {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      > * {
        min-width: 200px;
      }
    }

    > * {
      width: 200px;

      @include media-sm {
        min-width: 100%;
      }
    }

    .btn {
      margin-left: 20px;
      margin-top: 0!important;

      @include media-sm {
        margin-left: 0;
        margin-top: 15px!important;
      }
    }

    .paypal {
      background: #ffc439;
      max-height: 34px;
      &:hover {
        background: #ebb435;
      }
      .paypal-img {
        max-width: 80px;
        max-height: 20px;
      }
    }

    .back {
      margin-right: auto;
      margin-left: 0;

      @include media-sm {
        order: 3;
      }
    }
  }

  .pick-banner {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px 30px;
    margin-top: 10px;
    margin-bottom: 50px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    position: relative;
    overflow: hidden;

    @media (max-width: 767px) {
      flex-direction: column;
      padding: 20px;
    }

    &::before {
      content: '';
      background-image: url('/img/pages/pick/game.jpg');
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-position: center 7%;
      filter: grayscale(100%) contrast(1) brightness(0.8);
    }

    &::after {
      content: '';
      display: block;
      width: 500px;
      height: 500px;
      position: absolute;
      right: -305px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      transform: rotate(135deg);
      background-color: #6f6f6f;

      @include media-lg {
        right: -315px;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    .logo {
      flex-shrink: 0;

      @include media-lg {
        position: absolute;
        right: 100px;
        top: 17px;
        z-index: 1;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    .description {
      color: #fff;
      font-size: 18px;
      line-height: 1.3;
      margin: 0 70px 0 20px;
      flex-grow: 1;
      text-align: left;

      @include media-lg {
        margin-left: 0;
      }

      @media (max-width: 767px) {
        margin-right: 0;
      }

      p {
        margin-bottom: 4px;

        @media (max-width: 767px) {
          margin-bottom: 10px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: #fff;
        font-weight: bold;
      }
    }

    .btn {
      flex-shrink: 0;
      max-width: 200px;
      text-transform: uppercase;
      z-index: 2;

      @include media-lg {
        max-width: 180px;
        margin-top: 50px;
      }

      @media (max-width: 767px) {
        margin-top: 20px;
        margin-bottom: 5px;
        max-width: 100%;
      }
    }
  }

  .blog-item {
    @media (min-width: 767px) {
      display: flex;
      flex-direction: row-reverse;

      &.with-media {
        .price {
          flex-direction: row;
        }

        iframe {
          height: 100% !important;
        }
      }

      .blog-details {
        flex-grow: 1;
      }
    }
  }

  .media-block {
    width: calc(100% - 20px);
    height: 300px;
    margin: 0 auto;
    .content {
        width: 100%;
        height: 100%
    }
    .media-block-image {
        display: block;
        height: 100%;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        @media (max-width: 767px) {
            max-width: 100%;
            height: auto;
            width: auto;
        }
    }
    .full-width {
        display: block;
        width: 100%;
    }
    &.cover {
        background-size: cover;
    }
  }
}

.modal-login {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  overflow: hidden;
  border-radius: 10px;
  border: 3px solid;

  .modal-login-title {
    font-size: 22px;
  }

  .modal-login-body {
    font-size: 18px;
  }

  .modal-login-footer {
    text-align: center;

    .modal-login-footer-button {
      display: inline-block;
      width: 49%;

      &.btnsignup {
        background-color: #f6bb42;
      }
    }
  }
}