@import '../../../styles/mixins/media';

.refill-page {
  .panel-heading {
    background-color: #607D8B;
    font-weight: bold;
    color: #eee;
    border-top: 2px solid #e9573f;
    i {
      color:  #fff;
      font-size: 2em;
    }
  }
  .panel-sub-heading {
    .row:first-child {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      > div {
        padding: 20px;
      }
      .company-info, .client-info {
        @include media-md {
          text-align: center !important;
        }
        div {
          strong {
            font-size: 25px;
            font-weight: 300;
          }
        }
        address {
          font-size: 16px;
          color: #000;
          strong {
            font-size: 25px;
          }
        }
        abbr {
          font-style: italic;
        }
      }
      .client-info {
        position: relative;
        padding-left: 70px;
        &::before {
          content: '\F061';
          position: absolute;
          font-family: 'FontAwesome';
          font-size: 50px;
          color: rgba(99, 110, 123, .5);
          right: -21px;
          top: 50%;
          bottom: 0;
          @include media-md {
            right: 0;
            top: 100%;
            bottom: -25px;
            left: 0;
            text-align: center;
            transform: rotate(90deg);
            z-index: 10;
          }
        }
        .client-avatar {
          display: block;
          width: 50px;
          height: 50px;
          position: absolute;
          left: 0;
          top: 35px;
          border-radius: 50%;
          border: 2px solid #e9573f;
        }
      }
      .company-info {
        position: relative;
        padding-right: 70px;
        .company-logo {
          display: block;
          width: 50px;
          height: 50px;
          position: absolute;
          right: 0;
          top: 35px;
        }
      }
    }
    .form-body {
      width: 420px;
      margin: 0 auto;
      @include media-sm {
        width: 290px;
      }
    }
    .form-group {
      width: 100%;
      min-height: 50px;
      .form-control {
        width: 140px;
        float: left;
        @include media-sm {
          width: 100%;
          width: calc(100% - 20px);
          margin: 10px;
          margin-bottom: 0;
        }
      }
      .paypal-button {
        .paypal {
          background: #ffc439;
          &:hover {
            background: #ebb435;
          }
          .paypal-img {
            max-width: 80px;
            max-height: 20px;
          }
        }
        width: calc(100% - 150px);
        text-align: center;
        @include media-sm {
          width: 100%;
          float: left;
          padding: 10px;
        }
        float: right;
        .zoid-outlet {
          height: 50px !important;
        }
    }
    .paypal-button-disabled {
        pointer-events: none;
        opacity: 0.4;
      }

    }
    .help-block {
      color: #a94442;
    }
  }
  .panel-body {
    padding: 0;
    h5 {
      padding-left: 25px;
    }
  }
  .payments-history-talbe {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    background-color: #607D8B;
    color: #eee;
    border-top: 2px solid #e9573f;
    thead {
      th {
        font-weight: bold;
        background: #fff;
      }
    }
    /* Zebra striping */
    tr:nth-of-type(odd) {
      background: #eee;
    }
    td, th {
      color: #636E7B;
      padding: 6px;
      border-width: 1px;
    }
    tfoot {
      tr {
        border-top: 1px solid #e9573f;
        background: #fff !important;
      }
    }
    /*
  Max width before this PARTICULAR table gets nasty
  This query will take effect for any screen smaller than 760px
  and also iPads specifically.
  */
    @media
    only screen and (max-width: 1200px),
    (min-device-width: 768px) and (max-device-width: 1024px) {

    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
      display: none;
    }
    tbody {
      td {
      /* Behave  like a "row" */
        position: relative;
        padding-left: 150px;
        @include media-sm {
          padding-left: 100px;
        }
      }
      td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        font-weight: bold;
        top: 6px;
        left: 6px;
        width: 150px;
        padding-right: 10px;
        white-space: nowrap;
      }
        /*
        Label the data
        */
        td:nth-of-type(1):before { content: "Date"; }
        td:nth-of-type(2):before { content: "Type"; }
        td:nth-of-type(3):before { content: "Total Amount"; }
      }
      tfoot {
        display: block;
        tr {
          td {
            display: block;
            width: 50%;
            float: left;
            &:first-child {
              display: none;
            }
          }
        }
      }
    }
  }
}

