@import '../../styles/mixins/media';

/* Create your style on here */
.fifty{
    width: 50%;
}
td {
    white-space: normal !important; /* To consider whitespace.*/
  }

  .btn-stroke{
    width: 100%;
  }

  .btn{
    display: block;
    width: 100%;
    cursor: pointer;
    text-align: center;
  }
  .btn-none{
    border: 0;
    cursor:default;
  }
  .success-color{
    color: #8CC152!important;
  }
  .white-color{
    color: #e8e8e8;
  }
  .bet-item{
    padding: 0 5px;
  }
  .bet-separator{
    border-bottom: 1px solid #e8e8e8!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .table-auto-layout {
    table-layout: auto !important;
  }

  .round-avatar {
    border-radius: 50%;
  }

  .hundred-px {
    width: 100px;
  }

.btn-stroke.btn-primary {
  background-color: white;
}
.btn-stroke.btn-warning {
  background-color: white;
}
.btn-warning.btn-stroke:hover {
  background-color: white;
  border-color: #f5b22a;
}
.btn-warning,
.btn-primary,
.btn-default,
.btn-success {
  color: #333!important;
  border-radius: 3px;
}

.btn-default {
  background-color: #ddd;

  &:hover,
  &:active,
  &:focus {
    background-color: #d4d3d3;
  }
}

input[type="text"].form-control,
input[type="password"].form-control,
input[type="datetime"],
input[type="datetime-local"].form-control,
input[type="date"].form-control,
input[type="month"].form-control,
input[type="time"].form-control,
input[type="week"].form-control,
input[type="number"].form-control,
input[type="email"].form-control,
input[type="url"].form-control,
input[type="search"].form-control,
input[type="tel"].form-control,
input[type="color"].form-control,
textarea.form-control,
select.form-control {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) !important;
  border-color: #c3c3c3;
}

textarea {
  resize: none;
}

table {
  margin-top: 5px;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

td, th {
  border: 1px solid #dddddd;
  padding: 8px;
}

tr, .back-white {
  background-color: #ffffff;
}

svg {
width: auto;
}

.wide {
width: 40%;
}

.panel-tab {
  background-color: #ddd;
}

.panel-tab .panel-heading {
  border: 1px solid #ccc;
  background-color: #fff;
}

.panel-tab .panel-heading ul li a i {
  font-size: 23px;
  height: 30px;
}
.panel-tab .panel-heading ul li a i.fa-calendar {
  font-size: 21px;
}


/* header */
#header .navbar-header {
  position: absolute;
  background-color: #000000;
  height: 62px;
}

#header .header-left .navbar-header .navbar-brand img {
  margin-top: 4px;
}

#header {
  height: 62px;
}

/* add padding bottom when notification shows. */
.notification .body-content {
  height: auto;
  padding-bottom: 60px;

  @include media-tn {
    padding: 15px 15px 60px;
  }
}

.sidebar-content {
  padding: 17px 10px;
}

/* dropdown customize */
.navbar-toolbar .navbar-right .dropdown-menu {
  margin-top: 0;
  min-height: auto;
}

.navbar-toolbar .navbar-right .dropdown.vertical > a {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #888;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 8px;
}

.navbar-dark .navbar-right .dropdown > a:hover,
.navbar-dark .navbar-right .dropdown > a:focus,
.navbar-dark .navbar-right .dropdown > a:active,
.navbar-toolbar .navbar-right .dropdown > a.active {
  background-color: #353535 !important;
}

.navbar-toolbar .navbar-right .dropdown.open > a {
  background-color: #353535 !important;
}

.navbar-dark .navbar-right .dropdown > a:focus i,
.navbar-dark .navbar-right .dropdown > a:active i,
.navbar-dark .navbar-right .dropdown > a.active i {
  color: #c35745;
}

.bg-facebook i {
  color:#3b5998;
}
.bg-primary i {
  color: #00B1E1;
}
.bg-googleplus i {
  color: #db4a39;
}
.bg-bitbucket i {
  color: #205081;
}

#page-content {
  height: calc(100vh - 62px);
  overflow-y: auto;

  @include media-sm {
    height: auto;
  }

  @include media-tn {
    margin-top: 0;
  }
}

#wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  #header {
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
    .header-right {
      position: absolute;
    }
  }

  body.page-header-fixed {
    background-color: #fff!important;
  }

  #page-content {
    position: absolute;
    top: 62px;
    bottom: 0;
    right: 0;
    left: 0;
    box-sizing: border-box;
  }

  #wrapper {
    max-width: 1930px;
    margin: 0 auto;
    overflow: hidden;
  }
}

@media (min-width: 769px) {
  #header {
    .header-left {
      position: relative;
      height: 62px;
      width: 250px;
    }
  }
}

.pagination > li {
  a {
  cursor: pointer;
  }
  .not-active {
    cursor: default;
    color: gray;
  }
}

.nav-tabs > .nav-border > a {
  cursor: pointer;
}

.ribbon-wrapper .ribbon {
  cursor: default;
}

.btn.loading {
  &::after {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    background-image: url('/img/pics/loader.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    margin-left: 15px;
    transition: none;
  }
}