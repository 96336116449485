/* here styles for components in (pages) folder */


/* CONSULTANTS */
.consultants-page {
    .consultants {
        display: flex;
        flex-wrap: wrap;

        .empty-component {
            width: 100%;
        }
    }

    .panel-body {
        padding-top: 25px;
    }
}
