/* ========================================================================
 * TABLE OF CONTENTS
 * ========================================================================

 * ========================================================================
 * LAYOUT
 * ========================================================================
   01. HEADER
   02. SIDEBAR LEFT & RIGHT
   03. PAGE CONTENT
 * ========================================================================

 * ========================================================================
 * PAGES
 * ========================================================================
   01. SIGN
   02. ERROR
   03. INVOICE
 * ========================================================================

 * ========================================================================
 * COMPONENT
 * ========================================================================
   01. RESET
   02. PANEL
   03. MEDIA MANAGER
   04. PAGINATION
   05. RATING STAR
   06. DROPDOWN
   07. LIST GROUP
   08. FORM
   09. TABLE
   10. BUTTON
   11. MISC
 * ========================================================================

 * ========================================================================
 * PLUGINS
 * ========================================================================
   01. CHOSEN
   02. DROPZONE
   03. JPRELOADER
   04. DATEPICKER
   05. ION SLIDER
 * ======================================================================== */
/* ========================================================================
 * HEADER
 * ======================================================================== */
body.page-sidebar-minimize #header .navbar-minimize > a {
  background-color: #e9573f;
  color: #ffffff;
}
body.page-sidebar-minimize #header .navbar-minimize:hover > a {
  background-color: #e64328;
}
#header .navbar-header {
  background-color: #e9573f;
}
/* ========================================================================
 * SIDEBAR LEFT & RIGHT
 * ======================================================================== */
#sidebar-left.sidebar-box .sidebar-content img,
#sidebar-left.sidebar-rounded .sidebar-content img,
#sidebar-left.sidebar-circle .sidebar-content img {
  border: 2px solid #e9573f;
}
#sidebar-left.sidebar-box .sidebar-menu > li.active > a > .icon i,
#sidebar-left.sidebar-rounded .sidebar-menu > li.active > a > .icon i,
#sidebar-left.sidebar-circle .sidebar-menu > li.active > a > .icon i {
  background-color: #e9573f;
  position: relative;
  z-index: 1;
}
#sidebar-left.sidebar-box .sidebar-menu > li > ul > li:hover:after,
#sidebar-left.sidebar-rounded .sidebar-menu > li > ul > li:hover:after,
#sidebar-left.sidebar-circle .sidebar-menu > li > ul > li:hover:after {
  -webkit-box-shadow: 0 0 0 5px #dc3519;
  -moz-box-shadow: 0 0 0 5px #dc3519;
  box-shadow: 0 0 0 5px #dc3519;
}
#sidebar-left .sidebar-menu ul li:hover:after {
  background-color: #e9573f;
}
#sidebar-left .sidebar-menu menu-list > li:hover a:before {
  color: #e9573f;
}
#sidebar-left .sidebar-menu ul li.active:after {
  background-color: #e9573f;
}
#sidebar-left .sidebar-menu ul li.active > ul > li.active a:before {
  color: #e9573f;
}
/* ========================================================================
 * PAGE CONTENT
 * ======================================================================== */
body.page-sidebar-minimize .navbar-minimize,
body.page-sidebar-minimize-auto .navbar-minimize {
  border-right: 1px solid #e9573f !important;
}
body.page-sidebar-minimize .navbar-minimize a,
body.page-sidebar-minimize-auto .navbar-minimize a {
  background-color: #e9573f;
  border-bottom: 1px solid #e9573f;
}
body.page-sidebar-minimize .navbar-minimize a:hover,
body.page-sidebar-minimize-auto .navbar-minimize a:hover {
  background-color: #e64328;
  border-bottom: 1px solid #e64328;
}
body.page-sidebar-minimize .navbar-minimize a i,
body.page-sidebar-minimize-auto .navbar-minimize a i {
  color: #ffffff;
}
.navbar-minimize-mobile {
  background-color: #e9573f;
}
.navbar-minimize-mobile:hover {
  background-color: #e64328;
}
@media (max-width: 768px) {
  body .page-sidebar-left-show .navbar-minimize-mobile,
  body.page-sidebar-right-show .navbar-minimize-mobile {
    background-color: #dc3519;
  }
  body .page-sidebar-left-show .navbar-minimize-mobile:hover,
  body.page-sidebar-right-show .navbar-minimize-mobile:hover {
    background-color: #e64328;
  }
}
.navbar-toolbar .navbar-form input:focus {
  border: 1px solid #e9573f;
}
.navbar-toolbar .navbar-form .btn-focus {
  background-color: #e9573f;
  box-shadow: none;
  border: none;
  color: #ffffff;
}
.navbar-toolbar .navbar-right .dropdown > a:focus > i {
  color: #e9573f;
}
/* ========================================================================
 * SIGN
 * ======================================================================== */
.sign-wrapper a {
  color: #e9573f;
}
.sign-wrapper a:hover,
.sign-wrapper a:focus,
.sign-wrapper a:active {
  color: #e8533a;
}
.sign-text:before {
  background-color: #ee7f6d;
}
.sign-header {
  background-color: #e9573f;
  border-bottom: 10px solid #e53b1f;
}
.sign-text span {
  background-color: #e9573f;
}
.sign-text img {
  border: 7px solid #e9573f;
}
/* ========================================================================
 * ERROR
 * ======================================================================== */
.error-wrapper h1 {
  color: #e9573f;
}
/* ========================================================================
 * INVOICE
 * ======================================================================== */
.product-num {
  background-color: #e9573f;
}
/* ========================================================================
 * RESET
 * ======================================================================== */
a {
  color: #e9573f;
}
a:hover,
a:active,
a:focus {
  color: #dc3519;
}
input.no-border-left:focus,
textarea.no-border-left:focus {
  border-top: 1px solid #e9573f !important;
  border-right: 1px solid #e9573f !important;
  border-bottom: 1px solid #e9573f !important;
  border-left: none !important;
}
input.no-border-right:focus,
textarea.no-border-right:focus {
  border-top: 1px solid #e9573f !important;
  border-none: 1px solid #e9573f !important;
  border-bottom: 1px solid #e9573f !important;
  border-right: none !important;
}
input:focus,
textarea:focus {
  border: 1px solid #e9573f !important;
}
/* ========================================================================
 * PANEL
 * ======================================================================== */
.panel-tab .panel-heading ul li.active a i {
  color: #e9573f;
}
.panel-tab .panel-heading ul li a:hover i {
  color: #e9573f;
}
/* ========================================================================
 * MEDIA MANAGER
 * ======================================================================== */
.media-manager .media-manager-options .filter-type a.active {
  color: #e9573f;
}
/* ========================================================================
 * PAGINATION
 * ======================================================================== */
.pagination > li > a {
  color: #dc3519;
}
.pagination > .active > a,
.pagination > .active > span {
  background-color: #dc3519;
  border: 1px solid #dc3519;
}
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #e9573f;
  border: 1px solid #e9573f;
}
.pager > li > a {
  color: #dc3519;
}
/* ========================================================================
 * RATING STAR
 * ======================================================================== */
.rating .star:hover:before {
  color: #e9573f;
}
.rating .star.active:before {
  color: #e9573f;
}
.rating .star.active ~ .star:before {
  color: #e9573f;
}
/* ========================================================================
 * DROPDOWN
 * ======================================================================== */
.dropdown-menu li {
  position: relative;
}
.dropdown-menu li.active a {
  background-color: #e9573f;
}
.dropdown-menu li.active:hover a,
.dropdown-menu li.active:focus a,
.dropdown-menu li.active:active a {
  background-color: #e9573f;
  cursor: default;
}
.dropdown-menu li > a:hover:before {
  display: block;
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  border-left: 3px solid #e64328;
}
/* ========================================================================
 * LIST GROUP
 * ======================================================================== */
a.list-group-item.active {
  background-color: #e9573f;
  border-color: #e9573f;
}
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  background-color: #e9573f;
  border-color: #e9573f;
}
/* ========================================================================
 * FORM
 * ======================================================================== */
.ckbox-theme input[type=checkbox]:checked + label::after {
  border-color: #e9573f;
  background-color: #e9573f;
}
.ckbox-theme input[type=checkbox][disabled]:checked + label::after {
  border-color: #e9573f;
  opacity: .5;
}
.rdio-theme input[type=radio]:checked + label::after {
  border-color: #e9573f;
  background-color: #e9573f;
}
.rdio-theme input[type=radio][disabled]:checked + label::after {
  border-color: #e9573f;
  opacity: .5;
}
.form-control:focus {
  border: 1px solid #e9573f;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.form-focus {
  border: 1px solid #e9573f;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
/* ========================================================================
 * TABLE
 * ======================================================================== */
.table-theme thead tr th {
  background-color: #e9573f !important;
  border-color: #ec6b56 #ec6b56 #e53b1f !important;
  color: #fff;
}
.table-theme tbody tr td.sorting_1 {
  background: #f08b7a !important;
  color: white;
  border-bottom: 1px solid #f3a79b !important;
}
.table-theme tfoot tr th {
  background-color: #e9573f !important;
  border-color: #e53b1f #ec6b56 #ec6b56 !important;
  color: #fff;
}
.table-theme.table-bordered {
  border: 1px solid #e9573f;
}
.table-theme.table-bordered thead tr th:first-child,
.table-theme.table-bordered tfoot tr th:first-child,
.table-theme.table-bordered thead tr th:last-child,
.table-theme.table-bordered tfoot tr th:last-child {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
}
.table-theme.table-bordered tbody tr td.sorting_1 {
  border-right: 1px solid #f3a79b !important;
}
.table-theme table.has-columns-hidden > tbody > tr > td > span.responsiveExpander:before {
  color: white;
}
.table-theme table.has-columns-hidden > tbody > tr.detail-show > td span.responsiveExpander:before {
  color: white;
}
/* ========================================================================
 * BUTTON
 * ======================================================================== */
.btn-theme {
  background-color: #e9573f;
  border-color: #e9573f;
  color: white;
}
.btn-theme:hover,
.btn-theme:focus,
.btn-theme:active,
.btn-theme.active,
.btn-theme[disabled],
.btn-theme.disabled {
  background-color: #e64328;
  border-color: transparent;
  color: white;
}
.btn-theme.dropdown-toggle.btn-theme {
  background-color: #e9573f;
  border-color: #e64328;
  color: white;
}
.btn-theme.btn-alt {
  background-color: #e64328;
  border: 1px solid #e9573f;
}
.btn-theme.btn-alt:hover {
  background-color: #dc3519;
}
.btn-theme.btn-stroke {
  border: 1px double #e9573f;
  background-color: transparent;
  color: #999;
}
.btn-theme.btn-stroke:hover {
  background-color: #e64328;
  border-color: transparent;
  color: white;
}
.btn-theme.btn-solid {
  border: 1px solid #e9573f;
}
.btn-theme.btn-dashed {
  border: 1px dashed #e9573f;
}
.btn-theme.btn-dotted {
  border: 1px dotted #e9573f;
}
.btn-theme.btn-double {
  border: 4px double #e9573f;
}
.btn-theme.btn-inset {
  border: 4px inset #e9573f;
}
.btn-theme.btn-circle {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.btn-theme.btn-slidedown:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: #dc3519;
  z-index: -1;
}
.btn-theme.btn-slidedown:hover,
.btn-theme.btn-slidedown:active {
  color: white;
}
.btn-theme.btn-slidedown:hover:after,
.btn-theme.btn-slidedown:active:after {
  height: 100%;
}
.btn-theme.btn-slideright:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #dc3519;
  z-index: -1;
}
.btn-theme.btn-slideright:hover,
.btn-theme.btn-slideright:active {
  color: white;
}
.btn-theme.btn-slideright:hover:after,
.btn-theme.btn-slideright:active:after {
  width: 100%;
}
.btn-theme.btn-expand:after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background-color: #dc3519;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-theme.btn-expand:hover:after {
  width: 90%;
  opacity: 1;
}
.btn-theme.btn-expand:active:after {
  width: 101%;
  opacity: 1;
}
.btn-theme.btn-rotate {
  overflow: hidden;
}
.btn-theme.btn-rotate:after {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #dc3519;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.btn-theme.btn-rotate:hover:after {
  height: 260%;
  opacity: 1;
}
.btn-theme.btn-rotate:active:after {
  height: 400%;
  opacity: 1;
}
.btn-theme.btn-open {
  overflow: hidden;
}
.btn-theme.btn-open:after {
  width: 101%;
  height: 0;
  top: 50%;
  left: 50%;
  background-color: #dc3519;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.btn-theme.btn-open:hover:after {
  height: 75%;
  opacity: 1;
}
.btn-theme.btn-open:active:after {
  height: 130%;
  opacity: 1;
}
.btn-theme.btn-push {
  background: #e9573f;
  box-shadow: 0 6px #dc3519;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.btn-theme.btn-push:hover {
  box-shadow: 0 4px #dc3519;
  top: 2px;
}
.btn-theme.btn-push:active {
  box-shadow: 0 0 #dc3519;
  top: 6px;
}
.btn-theme.btn-pushright {
  background: #e9573f;
  box-shadow: 6px 0 #dc3519;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.btn-theme.btn-pushright:hover {
  box-shadow: 4px 0 #dc3519;
  left: 2px;
}
.btn-theme.btn-pushright:active {
  box-shadow: 0 0 #dc3519;
  left: 6px;
}
/* ========================================================================
 * PANEL
 * ======================================================================== */
.panel-theme .panel-heading {
  background-color: #e9573f;
  border: 1px solid #e8533a;
  color: white;
}
.panel-theme .panel-heading .option .btn:hover {
  background-color: #e64328;
  color: white;
}
.panel-theme .panel-heading .option .btn i {
  color: white;
}
.panel-bg-theme .panel-body {
  background-color: #e9573f;
  color: white;
}
.panel-bg-theme .panel-body .text-muted {
  color: #f2f2f2;
}
/* ========================================================================
 * MISC
 * ======================================================================== */
.img-bordered-theme {
  border: 2px solid #e9573f;
}
.progress-bar-theme {
  background-color: #e9573f;
}
.fg-theme {
  color: #e9573f !important;
}
.nicescroll-rails div {
  background-color: #e9573f !important;
}
.sidebar .nicescroll-rails div {
  background-color: #323232 !important;
}
.cal-month-box {
  border-top: 7px solid #e53b1f !important;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.cal-row-head [class*="cal-cell1"],
.cal-row-head [class*="cal-cell"] {
  background-color: #e9573f;
  color: #ffffff;
  border-width: 0px 1px 0px !important;
  border-style: solid;
  border-color: #ec6b56 #ec6b56 #e53b1f !important;
  border-left: none !important;
}
.cal-row-head [class*="cal-cell1"]:hover,
.cal-row-head [class*="cal-cell"]:hover {
  background-color: #e9573f !important;
}
.cal-row-head [class*="cal-cell1"]:first-child,
.cal-row-head [class*="cal-cell"]:first-child {
  border-width: 0px 1px 0px !important;
  border-style: solid;
  border-color: #ec6b56 #ec6b56 #e53b1f !important;
}
.cal-row-head [class*="cal-cell1"]:last-child,
.cal-row-head [class*="cal-cell"]:last-child {
  border-right: none !important;
}
#cal-day-panel {
  border-top: 7px solid #e53b1f !important;
  background-color: rgba(255, 255, 255, 0.28);
}
.cal-day-today {
  background-color: #e9573f !important;
}
.cal-row-head + .cal-day-hour {
  background-color: #e53b1f !important;
  color: #ffffff;
}
.bg-theme {
  background-color: #e9573f !important;
  border: 1px solid #e9573f;
  color: white;
}
.bg-theme a,
.bg-theme i,
.bg-theme span,
.bg-theme small,
.bg-theme p {
  color: white;
}
.bg-theme .flot-tick-label.tickLabel {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bg-theme .morris-hover-row-label {
  background-color: #dc3519;
}
#back-top:hover {
  background: #e9573f;
  box-shadow: 0 0 0 6px #ffffff;
}
.jqvmap-zoomin,
.jqvmap-zoomout {
  background: #e9573f !important;
}
.jqvmap-zoomin:hover,
.jqvmap-zoomout:hover {
  background: #dc3519 !important;
}
/* ========================================================================
 * CHOSEN
 * ======================================================================== */
.chosen-container .chosen-results li.highlighted {
  background-color: #e9573f !important;
  background-image: none !important;
}
/* ========================================================================
 * DROPZONE
 * ======================================================================== */
.dz-file-preview .dz-details:before {
  color: #e9573f;
}
/* ========================================================================
 * JPRELOADER
 * ======================================================================== */
#jpreBar {
  background-color: #e9573f;
}
/* ========================================================================
 * DATEPICKER
 * ======================================================================== */
.datepicker table thead tr:first-child {
  background-color: #e9573f;
}
.datepicker table thead tr:last-child {
  background-color: #dc3519;
}
.datepicker table thead tr th {
  background-color: #e9573f;
  border-color: #e64328 #e64328 #e53b1f;
}
.datepicker table thead tr .prev:hover,
.datepicker table thead tr .switch:hover,
.datepicker table thead tr .next:hover {
  background-color: #e64328 !important;
}
.datepicker table tbody tr td.active,
.datepicker table tbody tr td.active:hover,
.datepicker table tbody tr td.active:disabled,
.datepicker table tbody tr td.active.disabled:hover {
  background-color: #e9573f;
}
.datepicker table tbody tr td.active.active {
  background-color: #e9573f;
}
.datepicker table tbody tr td.active.active:hover {
  background-color: #dc3519;
}
.datepicker .icon-arrow-left:before {
  font-family: "FontAwesome";
  content: "\f104";
}
.datepicker .icon-arrow-right:before {
  font-family: "FontAwesome";
  content: "\f105";
}
.datepicker-dropdown:after {
  border-bottom: 6px solid #e9573f;
}
/* ========================================================================
 * ION SLIDER
 * ======================================================================== */
.slider-theme .irs-diapason {
  background-color: #ec6b56 !important;
}
.slider-theme .irs-slider {
  background-color: #e9573f !important;
}
.slider-theme #irs-active-slider,
.slider-theme .irs-slider:hover {
  background-color: #e9573f !important;
}
.slider-theme .irs-from,
.slider-theme .irs-to,
.slider-theme .irs-single {
  background-color: #e9573f !important;
}
.slider-theme .irs-from:after,
.slider-theme .irs-to:after,
.slider-theme .irs-single:after {
  border-top-color: #e9573f !important;
}
.slider-theme.circle .irs-slider {
  top: 21px;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.slider-theme.donut .irs-slider {
  background: #e1e4e9 !important;
  top: 21px;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 4px solid #e9573f;
  margin-left: -3px;
}
.block{
  display: block!important;
}
.none{
  display: none!important;
}
