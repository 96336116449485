@import '../../../styles/mixins/media';

.user-stats-profile {
    .image {
        text-align: center;

        img {
            height: 100px;
            background-color: #eee;
        }
    }

    .username {
        text-align: center;
        text-transform: capitalize;
        margin-top: 15px;
        margin-bottom: 25px;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: -15px;
            width: 50px;
            height: 2px;
            background-color: #f5bb42;
            border-radius: 20%;
        }

        a:hover {
            color: #f6bb42;
        }

        .send-message:hover i {
            transform: scale(1.05);
        }

        h4 {
            font-weight: 600;
        }

        span {
            max-width: calc(100% - 30px);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .statistics {
        text-transform: capitalize;
        color: #fff;
        font-size: 14px;

        p {
            margin: 5px 0;
        }
    }

    .bio {
        overflow: hidden;
        height: 30px;
    }

    .btn {
        color: #000!important;
        margin-top: 10px;
    }

    ul {
        margin-bottom: 0;
    }

    .panel-body {
        background-color: #607D8B;
        box-shadow: 2px 2px 4px rgba(0,0,0,0.4);
        padding-top: 15px;
        padding-bottom: 14px;
        height: 380px;
    }


    .ribbon-primary {
        background-color: #2196F3 !important;
    }

    .ribbon-wrapper .ribbon {
        font-size: 12px;
    }

    a {
        color: #ededed;
        transition: all 0.3s ease-out;

        &:active,
        &:focus,
        &:hover {
            color: #ededed;
        }
    }
}