@import '../../styles/mixins/media';

.profile-page {
  .timeline-item:last-child::before {
      display: none;
  }

   .col-lg-3 .list-unstyled {
       overflow: hidden; //if too big name
   }

  .cover {
    position: relative;
    text-align: center;

    @media (max-width: 767px) {
        display: none;
    }

    .inner-cover {
        display: inline-block;
        max-height: 200px;
        min-height: 40px;
        overflow: hidden;
    }

    label {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #F6BB42;
        padding: 5px 20px;
        text-decoration: none;
        cursor: pointer;
        color: black;
    }

    #upload-profile-image {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
      }
  }

  .social {
      position: absolute;
      left: 17px;
      top: 15px;

    a {
        background-color: #999;
        color: #fff;
        display: inline-block;
        width: 23px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        margin-right: 6px;
        transition: all 0.2s ease-in;
        opacity: 0.8;

        &:hover {
            background-color: #999;
            opacity: 1;

            i {
                font-size: 16px!important;
                margin-top: -1px;
            }
        }
    }

    i {
        vertical-align: middle;
        transition: all 0.2s ease-in;
        font-size: 15px!important;
    }
  }

  .profile-btns .btn {
      color: #000!important;
      height: 36px;

      &:not(:first-child) {
          margin-top: 10px;
      }
  }

  .profile-btns i {
      margin-top: 4px;
  }

  .avatar img {
      background-color: #eee;
      border-color: #ddd;
  }

  .balance {
      padding: 5px 0 10px;

      h4 {
          margin-top: 5px;
      }
  }

  .profile-cover .cover ul.cover-menu {
      width: calc(100% - 1px);
      background-color: rgba(0, 0, 0, 0.5);
  }

  .profile-cover .cover ul.cover-menu li {
      padding: 0 20px;
  }

  .cover-menu i {
      display: inline-block;
      width: 20px;
      line-height: 40px;
      vertical-align: middle;
      text-align: center;
      margin-right: 5px;
      margin-top: -2px;
  }

  .contacts .panel-heading {
      background-color: #999;
      border: 1px solid #999;
  }


  .inline-button {
    display: inline-block;
    width: auto;
    margin-right: 3px;
    font-weight: bold;
  }

  .col-lg-9 > div {
    margin-bottom: 25px;
  }

  .profile-stats {
      margin-bottom: 5px!important;
  }

  .fullname {
      span {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }
  }

  .blog-item {
    @media (min-width: 1100px) {
      display: flex;
      flex-direction: row-reverse;

      &.with-media {
        .price {
          flex-direction: row;
        }

        iframe {
          height: 100% !important;
        }
      }

      .blog-details {
        flex-grow: 1;
      }
    }
  }

  .media-block {
    width: 100%;
    background-color: #fcfcfc;
    @media (max-width: 1100px) {
        height: 216px;
    }
    .content {
        width: 100%;
        height: 100%
    }
    .media-block-image {
        display: block;
        height: 100%;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        @media (max-width: 1100px) {
            max-width: 100%;
            height: 100%;
            width: auto;
        }
    }
    .full-width {
        display: block;
        width: 100%;
    }
    &.cover {
        background-size: cover;
    }
}
}

.copy-to-buffer {
    display:  inline-block;
    cursor: copy;
    padding: 4px 10px;
    font-weight: bold;
    position: relative;
    background-color: #ddd;
    width: 100%;

    span {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1.2;
        vertical-align: middle;
    }

    &.copied {
        &::after {
            content: 'Copied!';
            position: absolute;
            bottom: -47px;
            right: 0;
            cursor: default;
        }
    }
}