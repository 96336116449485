.landing-start-now {
    // background: url(../img/start-up-bg.jpg) no-repeat scroll center center;
    background-size: cover;
    position: relative;
    z-index: 3;
    text-align: center;
    padding: 60px 0;
    &:before{
        content: "";
        background: #999;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .start_up_inner{
        text-align: center;
        img{
            display: block;
            margin: -25px auto 0px;
        }
        a{
            display: inline-block;
            font-size: 30px;
            color: #fff;
            font-weight: bold;
            border: 2px dotted #fff;
            padding: 0px 40px;
            line-height: 60px;
            border-radius: 5px;
            &:hover{
                color: #e9573f;
                border-color: #e9573f;
            }
        }
    }
}