@import '../../../styles/mixins/media';


#sales-page {
  padding: 0;
  /*
  Responsive Data Tables
  https://css-tricks.com/responsive-data-tables/
  Generic Styling, for Desktops/Laptops
  */
  .panel {
    .panel-heading {
      background-color: #607D8B;
      font-weight: bold;
      color: #eee;
      border-top: 2px solid #e9573f;
    }
    .panel-sub-heading {
      background-color: #fff;
      border-right: 1px solid rgba(100, 111, 125, 0.3);
      border-left: 1px solid rgba(100, 111, 125, 0.3);
      .pull-left {
        width: calc(100% -100px);
        display: flex;
        align-items: stretch;
        @include media-sm {
          width: 100%;
        }
      }
      .pull-right {
        width: 100px;
        @include media-sm {
          width: 100%;
        }
      }
    }

    .panel-title {
      > span {
        height: 30px;
        line-height: 30px;
        padding-right: 20px;
        @include media-md {
          display: block;
        }
        > span {
          font-weight: bold;
        }
      }
    }

    .panel-body {
      padding: 0;
      .sales-filter {
        max-width: 200px;
        margin: 10px;
      }
    }
  }


  .sales-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    /* Zebra striping */
    tr:nth-of-type(odd) {
      background: #eee;
    }
    th {
      color: #555;
      background-color: #fff;
      font-weight: bold;
    }
    td, th {
      padding: 6px;
      border: 1px solid #ccc;
      text-align: left;
      overflow: hidden;
    }
    /*
  Max width before this PARTICULAR table gets nasty
  This query will take effect for any screen smaller than 760px
  and also iPads specifically.
  */
    @media
    only screen and (max-width: 1200px),
    (min-device-width: 768px) and (max-device-width: 1024px) {

    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead th {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }

    td {
    /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid rgba(100, 111, 125, .3);
      position: relative;
      padding-left: calc(50% - 50px);
      min-height: 34px;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      font-weight: bold;
      top: 6px;
      left: 6px;
      width: 150px;
      padding-right: 10px;
      white-space: nowrap;
    }

      /*
      Label the data
      */
      td:nth-of-type(1):before { content: "Items Sold"; }
      td:nth-of-type(2):before { content: "Status"; }
      td:nth-of-type(3):before { content: "Item Name"; }
      td:nth-of-type(4):before { content: "Price"; }
      td:nth-of-type(5):before { content: "Total"; }
      td:nth-of-type(6):before { content: "Game Date"; }
      td:nth-of-type(7):before { content: "Created"; }
      td:nth-of-type(8):before { content: "Actions"; }
    }
  }
}
