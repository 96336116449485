@import '../../../styles/mixins/media';

.pick-feed {
  overflow: hidden;

  &.sponsored {
    .blog-title {
      padding-right: 45px;
    }
  }

  .blog-details {
    display: block!important;
    width: 100%;
    overflow: hidden;
  }

  .blog-meta li {
    padding-left: 0!important;
    margin-right: 5px!important;
  }

  .blog-title {
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include media-tn {
      line-height: 1.6;
    }
  }

  iframe {
    height: 100%;
  }

  .price {
    font-weight: bold;
    font-size: 13px;
    padding-bottom: 10px;
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @include media-tn {
      flex-direction: column;
    }

    span {
      padding: 10px 0;
      margin-right: 10px;
    }
  }

  .blog-meta li > span:first-child {
    margin-right: 3px;
  }

  .blog-meta li:last-child {
    margin-right: 20px!important;
  }

  .flex-box {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    flex-wrap: wrap;

    @include media-tn {
      flex-direction: column;
    }
  }

  .buy-buttons {
    justify-content: space-between;
    width: 100%;
    max-width: 360px;

    .paypal {
      background: #ffc439;
      max-height: 34px;
      &:hover {
        background: #ebb435;
      }
      .paypal-img {
        max-width: 80px;
        max-height: 20px;
      }
    }

    display: flex;
    align-items: center;

    @include media-tn {
      flex-direction: column;
      margin-bottom: 5px;
    }

    > * {
      width: 200px;

      @include media-lg {
        width: 175px;
      }

      @include media-tn {
        width: 100%;
      }
    }

    .btn {
      max-width: calc(50% - 10px);
      margin-left: 0;
      margin-right: 0;
      margin-top: 0!important;

      @include media-tn {
        margin-top: 10px!important;
        max-width: 100%;
      }
    }
  }
}
#blog-list .blog-video iframe {
  height: 100%;
}