@import '../../../styles/mixins/media';

.adBlocker-detected {
  position: fixed;
  width: calc(100% - 250px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  background-color:rgba(238, 238, 238, 0.9);
  padding: 20px;

  @include media-sm {
    width: 100%;
  }

  .content {
    max-width: 500px;
    width: 100%;
    padding: 25px 30px;
    background: #555;
    margin-bottom: 60px;

    @include media-tn {
      padding: 20px;
    }

    .icon {
      text-align: center;
      margin-bottom: 15px;
    }

    i {
      color: #e9573f;
      font-size: 28px;
      line-height: 1.3;
      background-color: #eee;
      border-radius: 50%;
      text-align: center;
      width: 35px;
      height: 35px;
    }

    p {
      font-size: 14px;
      line-height: 1.3;
      color: #fff;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}