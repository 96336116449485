.consultant-search {
  margin-bottom: 22px;

  .show-detailed {
    a {
      color: #666;
      transition: color 0.2s ease-in;
      padding: 7px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        color: #444;
        text-decoration: none;
      }
    }

    i {
      margin-right: 7px;
    }
  }

  
}