@import '../../../../styles/mixins/media';

.header-left {
  float: left;
  position: relative;

  @include media-sm {
    z-index: 2;
  }

  .navbar-header {
    background-color: #2a2a2a!important;
    width: 250px;
    left: 0;

    @include media-sm {
      height: 51px!important;
    }

    @include media-sm {
      border-bottom: 1px solid #eee;
    }
  }

  .navbar-minimize-mobile {
    color: #FFFFFF;
    display: none;

    @include media-sm {
      height: 49px!important;
    }

    @include media-only-sm {
      top: 51px;
      height: 61px!important;
      line-height: 56px!important;
    }
  }

  .main-logo {
    height: 100%;
    width: 100%;
  }

  .clearfix {
    @include media-tn {
      display: none;
    }
  }
}


#header.on-scrolling {
  @include media-tn {

    .header-left {
      top: -50px;

      .navbar-minimize-mobile {
        top: 51px;
      }
    }
  }
}