@import '../../styles/mixins/media';

.edit-profile-page {
  .body-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30px;

    @include media-sm {
      padding-right: 10px;
      padding-left: 10px;
    }

    @include media-mc {
      flex-direction: column;
    }
  }

  .settings-body {
    max-width: 750px;
    width: 100%;
    margin-left: 30px;
    background-color: #fff;
    padding: 35px 50px;

    @include media-md {
      padding: 25px 35px;
    }

    @include media-sm {
      margin-left: 10px;
      padding: 20px;
    }

    @include media-mc {
      margin-left: 0;
    }
  }

  .upload-avatar {
    button {
      margin: 30px auto 30px!important;
    }
  }

  .settings-tabs {
    background-color: #fff;
    max-width: 300px;
    width: 100%;

    @include media-md {
      max-width: 250px;
    }

    @include media-sm {
      max-width: 45px;
    }

    @include media-mc {
      max-width: 270px;
      margin: 0 auto 20px;
      display: flex;
    }

    .item {
      cursor: pointer;
      padding: 14px 20px;
      font-size: 15px;
      line-height: 1.3;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #636E7B;

      @include media-sm {
        padding: 14px 0;
        font-size: 0;
      }

      &.active {
        background-color: #636e7b;
        color: #ededed;
        text-decoration: none;
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }

    i {
      width: 20px;
      text-align: center;
      margin-right: 10px;
      font-size: 17px;

      @include media-sm {
        width: 45px;
        font-size: 19px;
        margin-right: 0;
      }
    }
  }

  .input-group-addon {
    color: #eee;
    background-color: #636E7B;
    text-align: center;
    padding: 0;
    max-width: 40px;
    min-width: 40px;
    border-color: #636e7b;
  }

  .asterisk {
    margin-left: 3px;
  }

  .information,
  .password,
  .social,
  .email {
    input,
    textarea {
      background-color: #f6f6f6!important;
      color: #373737 !important;
    }
  }

  .radio-wrap {
    display: flex;
    align-items: center;

    input {
      width: 24px;
      cursor: pointer;
    }

    label {
      font-size: 14px;
      cursor: pointer;
      padding-left: 10px;

      @include media-sm {
        margin-bottom: 2px!important;
        margin-top: 2px;
      }
    }
  }

  .title {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    text-align: center;
    font-weight: bold;
  }

  .form-control {
    height: 42px;
  }

  textarea.form-control {
    height: inherit;
  }

  .control-label {
    font-size: 13px;
    margin-bottom: 2px;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .btn {
      margin-top: 15px;
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
    background-color: transparent!important;
    padding: 0!important;

    @include media-tn {
      justify-content: space-between;
      margin-top: 10px;
    }

    .btn {
      max-width: 230px;
      margin-left: 20px;
      height: 42px;
      line-height: 28px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .anonimous-feed {
    margin-top: 30px;
  }

  .ckbox label:before, .rdio label:before {
    top: 13px;
  }

  .rdio input[type=radio]:checked + label::after {
    top: 18px;
    border-color: #636e7b;
    background-color: #636e7b;
  }

  .ckbox label:before {
    border: 1px solid #BBB;
  }

  .ckbox input[type=checkbox]:checked + label::after {
    top: 13px;
    border-color: #636e7b;
    background-color: #636e7b;
  }
}