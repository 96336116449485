.swal2-popup {
  width: 100%;
  max-width: 416px;

  .swal2-title {
    display: block!important;
    max-width: 383px;
    width: 100%;
  }

  .user-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .swal2-styled:focus {
    box-shadow: none;
  }

  button {
    text-transform: capitalize;
  }
}