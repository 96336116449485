.loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  max-height: 100%;
  flex-grow: 1;
  background-color: transparent;
}

.loader.hide {
  display: none!important;
}

.loader.show {
  display: flex!important;
}