@import '../../styles/mixins/media';

.chat-page {
    background-color: #ddd!important;

    > .body-content {
        padding: 0;
    }
}

.chat {
    background: #ddd;

    .choose-user-message {
        animation: 1s showWithDelay;
        animation-fill-mode: forwards;
        visibility: hidden;
        text-align: center;
        padding: 30px;
        margin-top: 60px;
        font-size: 18px;

        @media (max-width: 767px) {
            display: none;
        }
    }
}

.chat-navigation {
    background-color: #c7ccc7;
    width: 300px;
    float: left;
    height: calc(100vh - 62px);
    border-right: 1px solid #2a2a2a;

    @include media-sm {
        height: calc(100vh - 112px);
        height: calc(var(--vh, 1vh) * 100 - 112px); // hack for mobile browsers
    }

    @media (max-width: 767px) {
        width: 100%;

        &.hide-mb {
            display: none;
        }
    }

    @include media-tn {
        height: calc(100vh - 106px);
    }
}

.chat-navigation .scrollbar {
    padding: 0;
}

.chat-navigation .scrollbar-track-y {
    right: auto;
    left: 0;

    @media (max-width: 767px) {
        right: 0;
        left: auto;
    }
}

.chat-navigation-wrap .hidden {
    height: 0;
    visibility: hidden;
}

.chat-body {
    width: calc(100% - 300px);
    float: left;
    height: calc(100vh - 62px);
    background-color: #E9EAED;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #434651;
    display: flex;
    flex-direction: column;

    @include media-sm {
        height: calc(100vh - 112px);
    }

    @media (max-width: 767px) {
        width: 100%;
    }

    @include media-tn {
        height: calc(100vh - 106px);
    }
}

.chat-label {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color:  #646464;
    color: #fff;
    padding: 10px;
}

.chat.is-ios {
    .chat-navigation {
        @include media-tn {
            height: calc(100vh - 51px);
            height: calc(var(--vh, 1vh) * 100 - 51px); // hack for mobile browsers
        }
    }
    .chat-body {
        @include media-tn {
            height: calc(100vh - 51px);
            height: calc(var(--vh, 1vh) * 100 - 51px); // hack for mobile browsers
        }
    }
}
