@import '../../../styles/mixins/media';

.profile-stats {
  display: flex;
  flex-wrap: wrap;

  > div {
    @include media-lg {
      display: flex;
      flex: 1 1 0;
      min-width: 33%;
    }

    @include media-md {
      min-width: 50%;
    }

    @include media-mc {
      min-width: 100%;
    }
  }

  .mini-stat-type-3 {
    width: 100%;
  }

  span:not(.counter) {
    @include media-tn {
      font-size: 12px;
    }
  }

  h3 {
    @include media-tn {
      margin-bottom: 5px;
    }
  }

}