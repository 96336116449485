.chat-user-list {
  padding: 0;
  list-style: none;
  overflow: visible;
  margin-bottom: 0;
  background-color: #434651;
  display: flex;
  flex-direction: column;

  li {
    padding: 11px 15px;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #C2C2C1;
    background-color: #434651;
    border-bottom: 1px solid #1A1A1A;
    position: relative;
    order: 2;

    &:hover,
    &.active {
      background-color: #2f313a;
    }

    &.online {
      order: 1;
    }

    &.blocked {
      order: 3;
    }

    &.new {
      background-color: #656873;
      order: 0;

      &::after {
        content: "\f0e0";
        font-family: FontAwesome;
        text-decoration: inherit;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        display: inline-block;
        right: 20px;
        top: 26px;
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }

  img {
    float: left;
    border-radius: 50%;
    background-color: #779eae;
  }

  .about {
    float: left;
    padding-left: 8px;
  }

  .name {
    font-size: 16px;
    text-transform: capitalize;
    display: inline-block;
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .status {
    color: rgba(233,234,237, 0.7);

    p {
      margin-bottom: 0;
    }

    .online {
      color: #86BB71;
    }

    .offline {
      color: #E38968;
    }
  }

  .blocked-icon {
    position: absolute;
    right: 20px;
    font-size: 14px;
    top: 26px;
  }
}
