@import '../../styles/mixins/media';

.match-info-page {
  .match-info {
    background-color: #ddd;
    padding: 50px 40px 30px;
    max-width: 900px;
    width: 100%;
    margin: 60px auto 0;
    position: relative;
    border: 1px solid #ccc;

    @include media-tn {
      padding: 35px 25px 20px;
    }
  }

  .head:not(:last-child) {
    margin-bottom: 30px;
  }

  .head {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    text-align: center;
    position: relative;

    .name {
      font-size: 21px;
      line-height: 1.4;
      margin-bottom: 20px;
    }

    .score {
      font-size: 80px;
      font-weight: bold;
      line-height: 1.3;
    }

    .separator {
      font-size: 80px;
      padding: 20px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
      margin: auto;
    }

    .home-team {
      max-width: 300px;
      width: 100%;

      @include media-tn {
        margin-left: 8px;
      }
    }

    .away-team {
      max-width: 300px;
      width: 100%;

      @include media-tn {
        margin-right: 8px;
      }
    }

    .team-logo {
      height: 45px;
    }

  }

  .live-statistic,
  .statistic {
    margin-bottom: 30px;

    @include media-tn {
      margin-bottom: 0;
    }

    .title {
      border-bottom: 1px solid #ccc;
      padding-bottom: 8px;
      font-size: 13px;
      text-align: center;
      margin-bottom: 25px;
      text-transform: uppercase;

      @include media-tn {
        display: none;
      }
    }

    .mob-title {
      display: none;
      border-bottom: 1px solid #ccc;
      padding-bottom: 5px;
      font-size: 13px;
      text-align: center;
      margin-bottom: 15px!important;
      text-transform: uppercase;

      @include media-tn {
        display: block;
      }
    }

    .flex-box {
      display: flex;
      justify-content: space-around;

      @include media-tn {
        flex-direction: column;
      }

      > div {
        max-width: 300px;
        width: 100%;
        padding-left: 100px;

        @include media-tn {
          max-width: 100%;
          padding-left: 0;
          margin-bottom: 30px;
        }

        > div {
          margin-bottom: 4px;
        }
      }
    }

    span {
      display: inline-block;
      font-size: 15px;
    }

    .count {
      font-weight: bold;
      margin-left: 4px;
      font-size: 16px;
    }

    .name:first-letter {
      text-transform: capitalize;
    }
  }

  .clock {
    display: block;
    position: absolute;
    width: 100px;
    text-align: center;
    bottom: 90px;
    font-size: 17px;
    left: 0;
    right: 0;
    margin: 0 auto;

    p {
      margin-bottom: 10px;
    }
  }

  .score-table {
    width: 80%;
    margin: 0 auto 30px;

    @include media-sm {
      width: 100%;
      display: flex;

      thead,
      tbody {
        display: flex;
      }

      tbody,
      tbody tr {
        flex-grow: 1;
      }

      thead tr,
      tbody tr{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    tr {
      background-color: #888;
      color: #fff;
    }

    img {
      margin-right: 8px;
    }

    thead {
      tr {
        background-color: #666;
      }

      td {
        padding-top: 12px;
        padding-bottom: 12px;
        font-weight: bold;

        @include media-sm {
          padding: 8px;
        }
      }
    }

    td {
      text-align: center;

      &:first-child {
        width: 200px;
        min-width: 200px;

        @include media-sm {
          width: inherit;
          min-width: inherit;
          flex-grow: 1;
        }
      }
    }
  }

  .featured-athletes {
    div {
      font-size: 15px;
      margin-bottom: 7px;
      text-align: center;
    }

    strong {
      font-weight: 600;
    }
  }
}