@import '../../styles/mixins/media';

.myparlays-page {
  .parlay {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    border: 1px solid #ccc;

    @include media-tn {
      flex-direction: column-reverse;
      padding: 10px;
    }

    .alt-table-responsive {
      width: 100%;
    }

    .info {
      padding: 10px;
      background: #607d8b;
      width: 240px;
      flex-shrink: 0;
      margin-left: 15px;

      @include media-tn {
        width: 100%;
        margin-left: 0;
      }

      .item {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: 5px;
        color: #eee;
        font-size: 14px;
        font-weight: bold;

        .title {
          flex-shrink: 0;
          margin-right: 10px;
        }

        .value {
            flex-grow: 1;
            overflow: hidden;
            text-align: right;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: #eee;
        }
      }

      .btn {
        margin-top: 10px;
        margin-bottom: 3px;
      }
    }
  }

  .body-content {
    @include media-sm {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .panel-tab .panel-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav {
      margin-bottom: 0;

      > li {
        @include media-tn {
          &.active a::before {
            content: '';
            position: absolute;
            right: 25px;
            top: 28px;
            width: 10px;
            height: 10px;
            background-color: #777;
            border-radius: 50%;
            z-index: 1;
          }
          > a {
              position: relative;
              padding: 14px 20px 14px 14px;
              border-bottom: 1px solid #eee;

              &::after {
                content: '';
                position: absolute;
                right: 20px;
                top: 23px;
                width: 20px;
                height: 20px;
                background-color: #ddd;
                border-radius: 50%;
              }
          }
        }
      }
    }
  }

  .switch-btn {
    margin-right: 15px;
    margin-left: 15px;
    max-width: 200px;

    @include media-only-md {
      margin-left: 0;
    }

    @include media-md {
      margin-bottom: 15px;
    }

    @include media-tn {
      margin-top: -5px;
    }

    i {
      margin-right: 10px;
    }
  }

  .empty-component {
    padding: 30px;
    h1 {
      margin-top: 5px;
    }
  }

  .socialShareWrap {
    margin-top: 12px;
    text-align: center;

    .SocialMediaShareButton {
      display: inline-block;
      margin-right: 12px
    }
  }
}
