@import '../../../styles/mixins/media';

.odds-table {
    margin-bottom: 15px;

    &.in-progress {
        thead tr {
            border-top: 2px solid #e9573f;
        }
    }

    &.finished {
        thead tr {
            border-top: 2px solid #e9573f;
        }
    }

    td {
        position: relative;
        font-weight: bold;
    }

    thead {
        tr {
            background-color: #607D8B;
            font-weight: bold;
            color: #eee;
            height: 42px;
            @include media-md {
                display: block;
                height: initial;
                td {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 4px 8px;
                    &.text-right {
                        text-align: left !important;
                        padding: 0 8px 2px;
                    }
                    &:nth-child(1):after {
                        position: absolute;
                        right: 5px;
                        bottom: -25px;
                    }
                }
            }
        }

        td {
            border-color: transparent;
        }
    }

    .delta {
        cursor: pointer;
        position: absolute;
        left: 0;
        font-weight: bold;

        @include media-tn {
            left: -5px;
        }

        &-up {
            color: #0bb90b;
        }

        &-down {
            color: #ce5b3e;
        }
    }

    .price,
    .points {
        margin-right: 10px;
        padding-left: 25px;
        position: relative;
        letter-spacing: 1px;

        // minus letter have smaller width than plus, that why we add padding here.
        // &.minus {
        //     padding-left: 27px;
        // }

        @include media-tn {
            padding-left: 15px;
            margin-right: 8px;
            white-space: nowrap;

            &.minus {
                padding-left: 15px;
            }
        }

        @include media-mc {
            letter-spacing: 0;
            margin-right: 5px;
        }
    }

    .fa {
        font-size: 20px;
        vertical-align: middle;
        padding: 5px;
        line-height: 20px;
        margin-top: -4px;
    }

    tbody {
        @include media-md {
            display: flex;
            flex-direction: row;
            width: 100%;
            table-layout: unset;
            font-size: 12px;
        }
        tr {
            @include media-md {
                display: flex;
                flex-direction: column;
                &:nth-child(2),
                &:nth-child(3) {
                    width: 100%;
                    td:nth-child(2),
                    td:nth-child(3),
                    td:nth-child(4) {
                        font-weight: bold;
                    }
                }
                td:nth-child(1) {
                    flex-grow: 1;
                }
                td:nth-child(2),
                td:nth-child(3) {
                    flex-shrink: 1;
                }
            }
        }
        td {
            color: #555;
            min-height: 39px;

            @include media-md {
                padding: 8px 4px;
                font-weight: bold;
                overflow: hidden;
            }

            &:not(:first-child) {
                text-align: center;
            }

            > span:nth-of-type(2) {
                margin-right: 0!important;
            }
        }
    }

    .head {
        font-weight: bold;

        td {
            color: #333;
            background-color: #fff;
            font-weight: bold;
            text-align: center;
            @include media-md {
                width: 80px;
                flex-shrink: 0;
            }
        }
    }

    .two-rows {
        background-color: white;
        &-mob {
            position: relative;
            overflow: visible;
            display: none;

            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 10px;
                height: 100%;
                left: -5px;
                top: 0;
                background-color: #fff;
                z-index: 1;
            }

            @include media-sm {
                display: block;
            }
        }
    }

    .live-link {
        margin-left: 10px;
        color: #f6bb42;
        letter-spacing: 0.3px;
        @include media-tn {
            order: 3;
            width: 100%;
            margin-left: 0;
        }
        &:hover {
            color: #f6bb42;
        }
    }
}

.inprogress-label {
    display: inline-block;
    padding: 0px 7px;
    background-color: #f6bb42;
    font-size: 10px;
    text-align: center;
    border-radius: 3px;
    letter-spacing: 1px;
    color: #333;
    margin-left: 10px;
}

.finished-label {
    display: inline-block;
    padding: 0px 7px;
    background-color: #f6bb42;
    font-size: 10px;
    text-align: center;
    border-radius: 3px;
    letter-spacing: 1px;
    color: #333;
    margin-left: 10px;

    @include media-md {
        order: 2;
        margin-left: auto;
    }
}

