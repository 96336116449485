@import '../../../styles/mixins/media';

.chart-filter {
  margin-top: -10px;
  margin-bottom: 15px!important;
  text-align: right;

  @include media-tn {
    margin-bottom: 5px!important;
  }

  label {
    margin-right: 10px;
    font-size: 14px;
  }

  select {
    padding: 3px 8px;
    background-color: #fff;
    color: #444!important;
  }

  .btn {
    @include media-md {
      margin-bottom: 10px;
    }

    @include media-tn {
      margin-bottom: 15px;
    }
  }

  .col-md-5 {
    @include media-tn {
      margin-bottom: 15px;
    }
  }
}